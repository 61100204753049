import {
  createTypes,
  completeTypes,
  withPostSuccess,
  withPostFailure,
  withPreFetch
} from 'redux-recompose'

import { createProgramsService } from '../../services/BifiService'
import { normalizeOptions } from '../../schemas/adminModuleSchema'

export const actions = createTypes(
  completeTypes(
    [
      'POST_PROGRAMS',
      'GET_SOURCES',
      'GET_ECONOMIC_GROUPS',
      'GET_PROGRAMS',
      'GET_VPC'
    ],
    [
      'SIDE_STATE',
      'SET_ECONOMIC_GROUP',
      'SET_HOLDERS_NUMBER',
      'SET_SOCIAL_REASON',
      'SET_NIT',
      'SET_PROGRAMS',
      'SET_SOURCE',
      'SET_NIT',
      'SET_DISABLE_NIT',
      'SET_MESSAGE',
      'SET_HOLDERS_TOTAL',
      'SET_HOLDERS_DATA',
      'SET_CURRENT_TAB',
      'ENABLE_BUTTON',
      'SET_SHOW_MODAL',
      'SET_POST_PROGRAMS_LOADING',
      'SET_STATUS_CODE',
      'SET_RESPONSE_ERROR',
      'SET_STEPS',
      'CLEAR_HOLDERS_DATA',
      'SET_VPC',
      'SET_EGROUP_STATUS',
      'SET_HOLDER_STATUS',
      'GET_EGROUPS_VALIDATION'
    ]
  ),
  '@@CREATEPROGRAMS'
)

export const actionsCreators = {
  postPrograms: (programsData) => ({
    type: actions.POST_PROGRAMS,
    target: 'programsData',
    service: createProgramsService.postPrograms,
    payload: programsData,
    injections: [
      withPreFetch((dispatch) => {
        dispatch(actionsCreators.setPostprogramsLoading(true))
        dispatch(actionsCreators.setStatusCode(null))
        dispatch(actionsCreators.setShowModal(true))
      }),
      withPostSuccess((dispatch, response) => {
        dispatch(actionsCreators.setStatusCode(response?.status))
        dispatch(actionsCreators.setPostprogramsLoading(false))
        dispatch(actionsCreators.setSteps(0))
        dispatch(actionsCreators.setHoldersNumber(0))
        dispatch(actionsCreators.clearHoldersData())
        dispatch(actionsCreators.setEgroupStatus(false))
        dispatch(actionsCreators.setHolderStatus(false))
        dispatch(actionsCreators.setEconomicGroup())
        dispatch(actionsCreators.enableButton(true))
      }),
      withPostFailure((dispatch, response) => {
        dispatch(actionsCreators.setResponseError(response?.data.message))
        dispatch(actionsCreators.setStatusCode(response?.status))
        dispatch(actionsCreators.setPostprogramsLoading(false))
        dispatch(actionsCreators.clearHoldersData())
      })
    ]
  }),
  getSources: () => ({
    type: actions.GET_SOURCES,
    target: 'sources',
    service: createProgramsService.getSources,
    successSelector: (response) => normalizeOptions(response.data, 'sources')
  }),
  getVpc: () => ({
    type: actions.GET_VPC,
    target: 'vpcList',
    service: createProgramsService.getVpcData,
    successSelector: (response) => normalizeOptions(response.data, 'vpcList')
  }),
  getEconomicGroups: () => ({
    type: actions.GET_ECONOMIC_GROUPS,
    target: 'eGroupsList',
    service: createProgramsService.getEconomicGroups,
    successSelector: (response) =>
      normalizeOptions(response.data, 'eGroupsList')
  }),
  getEGroupsValidation: () => ({
    type: actions.GET_EGROUPS_VALIDATION,
    target: 'economicGroupsList',
    service: createProgramsService.getEconomicGroups,
    successSelector: (response) => response.data
  }),
  getPrograms: () => ({
    type: actions.GET_PROGRAMS,
    target: 'programsList',
    service: createProgramsService.getProgramsData,
    successSelector: (response) =>
      normalizeOptions(response.data, 'programsList')
  }),
  setEconomicGroup: (value) => ({
    type: actions.SET_ECONOMIC_GROUP,
    payload: value
  }),
  setHoldersNumber: (value) => ({
    type: actions.SET_HOLDERS_NUMBER,
    payload: value
  }),
  setFamilySide: (value) => ({
    type: actions.SIDE_STATE,
    payload: value
  }),
  setSocialReason: (value) => ({
    type: actions.SET_SOCIAL_REASON,
    payload: value
  }),
  setNit: (value) => ({
    type: actions.SET_NIT,
    payload: value
  }),

  setVPC: (value) => ({
    type: actions.SET_VPC,
    payload: value
  }),
  setPrograms: (value) => ({
    type: actions.SET_PROGRAMS,
    payload: value
  }),
  setSource: (value) => ({
    type: actions.SET_SOURCE,
    payload: value
  }),
  setMessage: (value) => ({
    type: actions.SET_MESSAGE,
    payload: value
  }),
  setHoldersTotal: (value) => ({
    type: actions.SET_HOLDERS_TOTAL,
    payload: value
  }),
  setHoldersData: (value) => ({
    type: actions.SET_HOLDERS_DATA,
    payload: value
  }),
  setCurrentTab: (value) => ({
    type: actions.SET_CURRENT_TAB,
    payload: value
  }),
  enableButton: (value) => ({
    type: actions.ENABLE_BUTTON,
    payload: value
  }),
  setShowModal: (value) => ({
    type: actions.SET_SHOW_MODAL,
    payload: value
  }),
  setPostprogramsLoading: (value) => ({
    type: actions.SET_POST_PROGRAMS_LOADING,
    payload: value
  }),
  setStatusCode: (value) => ({
    type: actions.SET_STATUS_CODE,
    payload: value
  }),
  setResponseError: (value) => ({
    type: actions.SET_RESPONSE_ERROR,
    payload: value
  }),
  setSteps: (value) => ({
    type: actions.SET_STEPS,
    payload: value
  }),
  clearHoldersData: (value) => ({
    type: actions.CLEAR_HOLDERS_DATA,
    payload: value
  }),
  setEgroupStatus: (value) => ({
    type: actions.SET_EGROUP_STATUS,
    payload: value
  }),
  setHolderStatus: (value) => ({
    type: actions.SET_HOLDER_STATUS,
    payload: value
  }),
  setDisableNit: (value) => ({
    type: actions.SET_DISABLE_NIT,
    payload: value
  })
}

export default actionsCreators
