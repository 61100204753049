import React, { Fragment, Component } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { t } from 'i18next'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { NAVIGATION_MOBILE } from '../../constant'

import styles from './styles.module.scss'

class SideDrawer extends Component {
  render() {
    const drawerClasses = [styles.sideDrawer]
    const {
      show,
      nick,
      hasPendingWorld,
      itemRender,
      listRender,
      onHandleClick
    } = this.props

    if (show) {
      drawerClasses.push(styles.open)
    }
    return (
      <nav className={classNames(drawerClasses)}>
        <div className={styles.borderBox}>
          <span
            id="logo_sura_sideDrawer"
            className={`float-left ${styles.logoSura}`}
            onClick={onHandleClick('HOME')}
          />
          <span
            id="nick_name_sideDrawer"
            className={`float-right ${styles.nickName}`}
          >
            {nick}
          </span>
        </div>
        <ul className={`float-right ${styles.menuList}`}>
          <li className={styles.userOption}>
            <span
              id="assistance_user_option"
              className={styles.option}
              onClick={onHandleClick('ASSISTANCE')}
            >
              {t('navBar:assistance')}
            </span>
          </li>
          <li className={styles.userOption}>
            <span
              id="logout_user_option"
              className={styles.logout}
              onClick={onHandleClick('LOGOUT')}
            >
              {t('navBar:signOut')}
            </span>
          </li>
          {hasPendingWorld === false && (
            <Fragment>
              <hr className={styles.separator} />
              <li>
                <Navbar expand="md" className={styles.mobileNavbar}>
                  <Nav className={`flex-column ${styles.navbarNav} `}>
                    {NAVIGATION_MOBILE.map((nav) => {
                      if (nav.hasSubmenu) {
                        return listRender(nav)
                      }
                      return itemRender(nav)
                    })}
                  </Nav>
                </Navbar>
              </li>
            </Fragment>
          )}
        </ul>
      </nav>
    )
  }
}

SideDrawer.propTypes = {
  hasPendingWorld: PropTypes.bool.isRequired,
  itemRender: PropTypes.func.isRequired,
  listRender: PropTypes.func.isRequired,
  nick: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHandleClick: PropTypes.func.isRequired
}

export default SideDrawer
