import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  ciaExperience: {
    ciaName: null,
    source: null,
    title: null,
    text: null,
    showContactInfo: false,
    showMesaggeBox: false
  }
}

const reducerDescription = {
  primaryActions: [actions.GET_DATA]
}

export default createReducer(initialState, completeReducer(reducerDescription))
