import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  faqs: []
}

const reducerDescription = {
  primaryActions: [actions.GET_FAQS],
  override: {}
}

export default createReducer(initialState, completeReducer(reducerDescription))
