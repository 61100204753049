/* eslint-disable camelcase */
import i18next from 'i18next'

i18next.addResources('es', 'conversations', {
  title: 'Registro de conversatorios',
  createConversation: 'Crear conversatorio',
  tableTitle: 'Conversatorios',
  total: 'Total participantes: ',
  download: 'Descargar',
  filters: 'Filtros',
  Conversatorio: 'Conversatorio',
  complete: 'Usuarios con valoración: ',
  percentage: 'Promedio de completos: '
})

i18next.addResources('es', 'usersActions', {
  deleteSelection: 'Borrar selección',
  editSelection: 'Selecciona el campo que quiere editar',
  city: 'Escriba el nuevo valor para el campo CIUDAD',
  email: 'Escriba el nuevo valor para el campo CORREO',
  phone: 'Escriba el nuevo valor para el campo TELEFONO',
  program_name: 'Escriba el nuevo valor para el campo PROGRAMA',
  general_state: 'Escriba el nuevo valor para el campo ESTADO',
  observations: 'Escriba el nuevo valor para el campo HALLAZGOS',
  conversation_type: 'Escriba el nuevo valor para el campo CONVERSATORIO'
})

i18next.addResources('es', 'conversationsTableFilters', {
  filters: 'Filtros',
  levelRisk: 'Nivel de riesgo',
  generalStates: 'Estados',
  selfManagement: 'Autogestión',
  low: 'Bajo',
  medium: 'Medio',
  mediumHigh: 'Medio - Alto',
  high: 'Alto',
  data: 'Datos',
  stateProcess: 'Estado Valoración',
  economicGroup: 'Grupo Económico',
  socialReason: 'Tomador',
  program: 'Programa',
  state: 'Estado de proceso',
  date: 'Fecha',
  submit: 'crear filtro',
  clear: 'limpiar filtro',
  to: 'hasta',
  conversation: 'Conversatorio',
  conversations: 'Conversatorio'
})
