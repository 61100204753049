import { createTypes, completeTypes } from 'redux-recompose'

import { engineService } from '../../services/BifiService'
import { options } from '../../constants/engine'
import { normalizeFinancialData } from '../../schemas/engineSchema'

export const actions = createTypes(
  completeTypes(['GET_FINANCIAL', 'GET_GENERAL']),
  '@@ENGINE'
)

export const actionsCreators = {
  getFinancial: (params) => ({
    type: actions.GET_FINANCIAL,
    target: options.financial,
    payload: params,
    service: engineService.getFinancial,
    successSelector: (response) => normalizeFinancialData(response.data)
  }),
  getGeneral: () => ({
    type: actions.GET_GENERAL,
    target: options.general,
    service: engineService.getGeneral,
    successSelector: (response) => response && response.data
  })
}

export default actionsCreators
