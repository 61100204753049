export const denormalizeDataPoll = (data) => {
  const newData = data.map((item) => ({
    id: parseInt(item.id),
    value: parseInt(item.value)
  }))
  return {
    questions: newData
  }
}

export const normalizeQuestions = (data) => data.questions

export const normalizeResults = (data) => data.results
