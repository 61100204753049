import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Nav from 'react-bootstrap/Nav'
import classNames from 'classnames'

import { ACTIONS } from './constants'
import styles from './styles.module.scss'

class FloatingButton extends Component {
  handleClick = () => {
    const { id, onClickHelpUsToImprove } = this.props
    if (id === 'helpUsToImprove') {
      onClickHelpUsToImprove()
    }
  }

  render() {
    const { id, url, currentLocation } = this.props
    if (!url) {
      return null
    }
    const { className, externalUrl, title, icon } = ACTIONS[id]
    const buttonClasses = [
      styles.floatingButton,
      styles[className],
      currentLocation === '/app/' ? styles.videoScreen : null
    ]
    const target = externalUrl ? '_blank' : '_self'
    return (
      <Nav.Link
        id={id}
        target={target}
        className={classNames(buttonClasses)}
        href={url}
        onClick={this.handleClick}
      >
        <div className={styles.content}>
          <img src={icon} alt="iconImg" />
          <span id={`${id}Text`}>{title}</span>
        </div>
      </Nav.Link>
    )
  }
}

FloatingButton.propTypes = {
  currentLocation: PropTypes.string,
  id: PropTypes.string,
  url: PropTypes.string,
  onClickHelpUsToImprove: PropTypes.func
}

export default FloatingButton
