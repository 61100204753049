import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose'

import { normalizeOptions } from '../../schemas/adminModuleSchema'
import { adminService } from '../../services/BifiService'

export const actions = createTypes(
  completeTypes(
    [
      'GET_DASHBOARD_DATA',
      'GET_LEVEL_RISK',
      'GET_GENERAL_STATES',
      'GET_PROGRAMS_DATA',
      'GET_STATE_PROCESS',
      'GET_ECONOMIC_GROUPS',
      'GET_GENERAL_STATESFULL'
    ],
    [
      'SET_SOURCE_OPTIONS',
      'CHANGE_FILTER',
      'CHANGE_FILTER_DATES',
      'SET_SHOW_FILTER_CARD',
      'CLEAR_FILTERS',
      'CHANGE_OPTIONS',
      'SET_FORM_ERROR',
      'RESET_FORM_ERROR',
      'SET_GENERAL_STATE_OPTIONS',
      'SET_TAKER_ID',
      'SET_DISABLED',
      'SET_DISABLED_TAKER',
      'SET_ENABLE_BUTTON',

      'SET_SHOW_MODAL'
    ]
  ),
  '@@ADMIN_MODULE'
)

export const actionsCreators = {
  getDashboardData: (filters) => ({
    type: actions.GET_DASHBOARD_DATA,
    target: 'dashboard',
    service: adminService.getDashboardData,
    payload: filters,
    successSelector: (response) => response.data
  }),
  getLevelRisk: () => ({
    type: actions.GET_LEVEL_RISK,
    target: 'levelRiskList',
    service: adminService.getLevelRisk,
    successSelector: (response) => response.data
  }),
  getGeneralStates: () => ({
    type: actions.GET_GENERAL_STATES,
    target: 'generalStateList',
    service: adminService.getGeneralStates,
    successSelector: (response) => response.data.group,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(
          actionsCreators.setGeneralStateOptions(
            normalizeOptions(response.data.group, 'generalStateOptions')
          )
        )
      })
    ]
  }),
  getEconomicGroups: () => ({
    type: actions.GET_ECONOMIC_GROUPS,
    target: 'groupsList',
    service: adminService.getEconomicGroups,
    successSelector: (response) => normalizeOptions(response.data, 'groupsList')
  }),
  getPrograms: (programs) => ({
    type: actions.GET_PROGRAMS_DATA,
    target: 'programsData',
    service: adminService.getProgramsData,
    payload: programs,
    successSelector: (response) => response.data
  }),
  getGeneralStatesFull: (fullList) => ({
    type: actions.GET_GENERAL_STATESFULL,
    target: 'generalStateListFull',
    service: adminService.getGeneralStateFull,
    successSelector: (response) => response.data.detail,
    payload: fullList,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(
          actionsCreators.setGeneralStateOptions(
            normalizeOptions(response.data.detail, 'generalStateOptionsFull')
          )
        )
      })
    ]
  }),
  getStateProcess: () => ({
    type: actions.GET_STATE_PROCESS,
    target: 'processList',
    service: actionsCreators.getStateProcess,
    successSelector: (response) =>
      normalizeOptions(response.data, 'processList')
  }),
  setGeneralStateOptions: (value) => ({
    type: actions.SET_GENERAL_STATE_OPTIONS,
    payload: value
  }),
  changeFilter: (value) => ({
    type: actions.CHANGE_FILTER,
    payload: value
  }),
  changeFilterDates: (value) => ({
    type: actions.CHANGE_FILTER_DATES,
    payload: value
  }),
  setShowFilterCard: (value) => ({
    type: actions.SET_SHOW_FILTER_CARD,
    payload: value
  }),
  clearFilters: () => ({
    type: actions.CLEAR_FILTERS
  }),
  changeOptions: (value) => ({
    type: actions.CHANGE_OPTIONS,
    payload: value
  }),
  setFormError: (value) => ({
    type: actions.SET_FORM_ERROR,
    payload: value
  }),
  resetFiltersError: () => ({
    type: actions.RESET_FORM_ERROR
  }),
  setTakerId: (value) => ({
    type: actions.SET_TAKER_ID,
    payload: value
  }),
  setDisabled: (value) => ({
    type: actions.SET_DISABLED,
    payload: value
  }),
  setDisabledTaker: (value) => ({
    type: actions.SET_DISABLED_TAKER,
    payload: value
  }),
  setEnableButton: (value) => ({
    type: actions.SET_ENABLE_BUTTON,
    payload: value
  }),

  setShowModal: (value) => ({
    type: actions.SET_SHOW_MODAL,
    payload: value
  })
}

export default actionsCreators
