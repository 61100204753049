import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  general: '',
  psycological: [],
  financial: []
}

const reducerDescription = {
  primaryActions: [actions.GET_FINANCIAL, actions.GET_GENERAL]
}

export default createReducer(initialState, completeReducer(reducerDescription))
