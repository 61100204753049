import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose'

import { userExperience } from '../../services/BifiService'
import { normalizeData } from '../../schemas/userExperienceSchema'

export const actions = createTypes(
  completeTypes(
    ['GET_DATA', 'PUT_DATA'],
    ['SET_CONTROL_CLOSE', 'SET_ROLE_INTERFACE']
  ),
  '@@USER_EXPERIENCE'
)

export const actionsCreators = {
  putData: (data) => ({
    type: actions.PUT_DATA,
    target: 'userExperienceEdit',
    service: userExperience.putData,
    payload: { data },
    injections: [
      withPostSuccess((dispatch) => dispatch(actionsCreators.getData()))
    ]
  }),

  getData: () => ({
    type: actions.GET_DATA,
    target: 'userExperience',
    service: userExperience.getData,
    successSelector: (response) => normalizeData(response.data)
  }),

  setControlClose: (value) => ({
    type: actions.SET_CONTROL_CLOSE,
    payload: value
  }),
  setRoleInterface: (values) => ({
    type: actions.SET_ROLE_INTERFACE,
    payload: values
  })
}

export default actionsCreators
