import { t } from 'i18next'
import React from 'react'

import styles from './styles.module.scss'

import Routes from '../../../constants/routes'

export const Navigation = [
  {
    key: 'dashboard',
    title: (
      <span className={styles.title}>
        <i className="fal fa-tachometer-alt-fast" />{' '}
        {t('adminSidebar:dashboard')}{' '}
      </span>
    ),
    path: '#',
    hasSubmenu: true,
    submenu: [
      {
        key: 'home',
        title: t('adminSidebar:home'),
        path: `${Routes.DASHBOARD}`
      }
    ]
  },
  {
    key: 'users',
    title: (
      <span className={styles.title}>
        <i className="fal fa-tools" /> {t('adminSidebar:users')}{' '}
      </span>
    ),
    path: '#',
    hasSubmenu: true,
    submenu: [
      {
        key: 'home',
        title: t('adminSidebar:usersPanel'),
        path: `${Routes.USERS}`
      },
      {
        key: 'usersByAllPrograms',
        title: t('adminSidebar:usersByAllProgramsPanel'),
        path: `${Routes.USERSBYALLPROGRAMS}`
      },
      {
        key: 'createUser',
        title: t('adminSidebar:createUsers'),
        path: `${Routes.CREATE_USERS}`
      },
      {
        key: 'programsPanel',
        title: t('adminSidebar:programsPanel'),
        path: `${Routes.PROGRAMS_PANEL}`
      },
      {
        key: 'createPrograms',
        title: t('adminSidebar:createPrograms'),
        path: `${Routes.CREATE_PROGRAMS}`
      },
      {
        key: 'schedulingPanel',
        title: t('adminSidebar:schedulingPanel'),
        path: `${Routes.SCHEDULINGS}`
      }
    ]
  },
  {
    key: 'conversationsTittle',
    title: (
      <span className={styles.title}>
        <i className="fal fa-microphone-stand" />{' '}
        {t('adminSidebar:conversationsTittle')}{' '}
      </span>
    ),
    path: '#',
    hasSubmenu: true,
    submenu: [
      {
        key: 'conversations',
        title: t('adminSidebar:conversations'),
        path: `${Routes.CONVERSATIONS}`
      }
    ]
  },
  {
    key: 'emailTitle',
    title: (
      <span className={styles.title}>
        <i className="fal fa-paper-plane" /> {t('adminSidebar:emailTitle')}{' '}
      </span>
    ),
    path: '#',
    hasSubmenu: true,
    submenu: [
      {
        key: 'emails',
        title: t('adminSidebar:emails'),
        path: `${Routes.EMAILS}`
      }
    ]
  }
]
