import React, { Component, Fragment } from 'react'
import { t } from 'i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import classNames from 'classnames'

import styles from './styles.module.scss'
import { OPTIONS, OPTIONS_PERSONAL_INFO, OPTIONS_MY_RESULTS } from './constants'

import Routes from '../../../constants/routes'
// import Routes from '~constants/routes';
// import navbarBottomActions from '~redux/NavbarBottom/actions';
import navbarBottomActions from '../../../redux/NavbarBottom/actions'

class NavbarBottom extends Component {
  handleClick = (route, externalTarget) => (e) => {
    const {
      showPersonalInfoOptions,
      setShowPersonalInfo,
      setShowMyResults,
      showMyResultsOptions
    } = this.props
    const { history } = this.props
    e.stopPropagation()
    if (route === OPTIONS[3].route) {
      setShowPersonalInfo(!showPersonalInfoOptions)
    } else if (route === OPTIONS[0].route) {
      setShowMyResults(!showMyResultsOptions)
    } else {
      setShowPersonalInfo(false)
      if (externalTarget === true) {
        window.open(route, '_blank')
      } else {
        history.push(Routes[route])
      }
    }
  }

  render() {
    const { showPersonalInfoOptions, showMyResultsOptions } = this.props
    const { learningUrl } = this.props
    const currentLocation = window.location.pathname
    const Classes = {
      personalInfo: [
        'row',
        styles.personalInfoRow,
        showPersonalInfoOptions === true ? styles.active : ''
      ],
      myResults: [
        'row',
        styles.myResultsRow,
        showMyResultsOptions === true ? styles.active : ''
      ]
    }
    const currentSubMenu = showPersonalInfoOptions
      ? 'personalInfo'
      : 'myResults'
    const optionsSubMenu = showPersonalInfoOptions
      ? OPTIONS_PERSONAL_INFO
      : OPTIONS_MY_RESULTS
    return (
      <Fragment>
        <div className={styles.container}>
          <div className={classNames(Classes[currentSubMenu])}>
            <div className={`col-11 ${styles.subMenuCol}`}>
              <div className={styles.subMenuTitle}>
                {t(`navbarBottom:${currentSubMenu}`)}
              </div>
              {optionsSubMenu.map((option) => (
                <li className={styles.subMenuOption} key={option.key}>
                  <a id={`${option.name}_submenu_option`} href={option.route}>
                    {t(`navbarBottom:${option.name}`)}
                  </a>
                </li>
              ))}
            </div>
          </div>
          <div className={`row mx-0 full-height ${styles.optionRow}`}>
            {OPTIONS.map((option) => {
              const linkTo =
                option.name === 'learning' ? learningUrl : option.route
              const externalTarget = option.name === 'learning'
              const activeClass =
                (currentLocation.includes(OPTIONS_MY_RESULTS[0].route) ||
                  currentLocation.includes(OPTIONS_MY_RESULTS[1].route)) &&
                option.name === OPTIONS[0].name
              const menuItem = (
                <div
                  id={`${option.name}_option`}
                  key={option.key}
                  className={`col-3 p-1 ${styles.optionCol} ${
                    currentLocation.includes(Routes[option.route]) ||
                    activeClass
                      ? styles.optionActive
                      : ''
                  }`}
                  onClick={this.handleClick(linkTo, externalTarget)}
                >
                  <div>
                    {currentLocation.includes(Routes[option.route]) ||
                    activeClass ? (
                      <img
                        src={option.iconActive}
                        className={styles.icon}
                        alt={t(`navbarBottom:${option.name}`)}
                      />
                    ) : (
                      <img
                        src={option.icon}
                        className={styles.icon}
                        alt={t(`navbarBottom:${option.name}`)}
                      />
                    )}
                  </div>
                  <span>{t(`navbarBottom:${option.name}`)}</span>
                </div>
              )
              return menuItem
            })}
          </div>
        </div>
      </Fragment>
    )
  }
}

NavbarBottom.propTypes = {
  setShowMyResults: PropTypes.func.isRequired,
  setShowPersonalInfo: PropTypes.func.isRequired,
  showMyResultsOptions: PropTypes.bool.isRequired,
  showPersonalInfoOptions: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  learningUrl: PropTypes.string
}

const mapStateToProps = (state) => ({
  nick: state.auth.currentUser.fullname?.split(' ')[0],
  learningUrl: state.learning.learningUrl,
  showPersonalInfoOptions: state.navbarBottom.showPersonalInfoOptions,
  showMyResultsOptions: state.navbarBottom.showMyResultsOptions,
  learningRoute: state.profile.learningRoute,
  learningUrlLoading: state.learning.learningUrlLoading
})

const mapDispatchToProps = (dispatch) => ({
  setShowPersonalInfo: (value) =>
    dispatch(navbarBottomActions.setShowPersonalInfo(value)),
  setShowMyResults: (value) =>
    dispatch(navbarBottomActions.setShowMyResults(value))
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NavbarBottom)
