import i18next from 'i18next'

i18next.addResources('es', 'cifinModal', {
  title: 'Información entregada por la central de riesgo TransUnion (CIFIN)',
  content:
    'A continuación, te presentamos lo que las centrales de riesgo han entregado sobre tu comportamiento financiero. Ten presente que los informes de estas instituciones privadas no son siempre negativos ni tienen como única función reportar a personas con deudas ante las entidades financieras.',
  documentNumber: 'Número de documento:  ',
  queryDate: 'Consultado ',
  download: 'Descargar',
  exit: 'Salir',
  enMora:
    '* Actualmente las centrales de riesgo reportan que tienes una obligación en mora, por esta razón se carga el total del valor que le adeudas a la entidad financiera.'
})
