/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Layout from './layout'

class AdminNavbar extends Component {
  render() {
    const { nick } = this.props
    return <Layout nick={nick} />
  }
}

AdminNavbar.propTypes = {
  nick: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
  nick: state.auth.currentUser.fullname?.split(' ')[0]
})
export default connect(mapStateToProps)(AdminNavbar)
