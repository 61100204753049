/* eslint-disable */
import {
  completeTypes,
  createTypes,
  withPostFailure,
  withPostSuccess
} from 'redux-recompose'

import { options } from '../../constants/login'

import * as AuthService from '../../services/AuthServices'
import * as LocalStorage from '../../services/LocalStorageService'
import { userService } from '../../services/BifiService'

import { actionsCreators as switchesActions } from '../Switches/SwitchActions'
import Routes from '../../constants/routes'
import userExperienceActions from '../../redux/UserExperience/actions'
import profileActions from '../../redux/Profile/actions'
import purposeActions from '../../redux/Purpose/actions'
import { ADMIN_ROLES } from '../../constants/roles'

/* ------------- Auth actions ------------- */
export const actions = createTypes(
  completeTypes(
    ['PUT_COMUNICATION_CHANNEL', 'GET_USER', 'VERIFTY_TOKEN'],
    [
      'SET_ERROR',
      'SET_STATUS_WORLD',
      'SET_FLOATING_OPTIONS',
      'CLEAR_CLIENT_ERROR',
      'SET_APP_LOADING',
      'LOGOUT',
      'SET_ROLE'
    ]
  ),
  '@@AUTH'
)

export const actionsCreators = {
  logout: () => ({
    type: actions.LOGOUT,
    target: options.current,
    service: AuthService.logout,
    payload: {},
    injections: [
      withPostSuccess(() => {
        LocalStorage.removeCurrentAcc()
        LocalStorage.removeSessionToken()
        window.location = process.env.REACT_APP_LANDING
      })
    ]
  }),

  editUser: (data) => ({
    type: actions.PUT_COMUNICATION_CHANNEL,
    target: 'comunicationChannel',
    service: userService.editUser,
    payload: data,
    injections: [
      withPostSuccess((dispatch) => dispatch(actionsCreators.getUser()))
    ]
  }),

  getUser: () => ({
    type: actions.GET_USER,
    target: options.current,
    service: AuthService.getUser,
    injections: [
      withPostSuccess((dispatch, response) => {
        const isAdmin = ADMIN_ROLES.includes(response.data.rol)
        dispatch(switchesActions.getSwitches())
        LocalStorage.setCurrentAcc(response.data.id)
        dispatch(actionsCreators.setAppState())
        dispatch(actionsCreators.setRole(isAdmin))
        dispatch(
          userExperienceActions.setRoleInterface({
            path: location.pathname,
            isAdmin
          })
        )
        dispatch(actionsCreators.setFloatingOptions(true))
        dispatch(purposeActions.getPurposesByUser(response.data.id))
      }),
      withPostFailure((dispatch) => dispatch(actionsCreators.setAppState()))
    ]
  }),
  setError: (payload) => ({
    type: actions.SET_ERROR,
    payload
  }),
  clearError: (property) => ({
    type: actions.CLEAR_CLIENT_ERROR,
    payload: property
  }),
  setAppState: () => ({
    type: actions.SET_APP_LOADING,
    payload: false,
    target: options.appLoading
  }),
  setStatusWorld: (value) => ({
    type: actions.SET_STATUS_WORLD,
    payload: value
  }),
  setFloatingOptions: (value) => ({
    type: actions.SET_FLOATING_OPTIONS,
    payload: value
  }),
  verifyToken: (token, history) => ({
    type: actions.VERIFY_TOKEN,
    target: options.enabledUser,
    payload: token,
    service: AuthService.verifyToken,
    injections: [
      withPostSuccess((dispatch) => {
        LocalStorage.setSessionToken(token)
        dispatch(actionsCreators.getUser())
        dispatch(userExperienceActions.getData())
        dispatch(profileActions.getProfile())
      }),
      withPostFailure(() => history.push(Routes.LOGIN))
    ]
  }),
  setRole: (value) => ({
    type: actions.SET_ROLE,
    payload: value
  })
}

export default actionsCreators
