import { t } from 'i18next'

import { toCamel } from '../utils/generalFunctions'

export const normalizePsycologicalData = (data) =>
  data.map((dimension) => ({
    name: dimension.name,
    data: {
      text: dimension.text
    }
  }))

export const normalizeFinancialData = (data) =>
  Object.keys(data).map((financialOption) => {
    const newOption = {}
    Object.keys(data[financialOption]).forEach((option) => {
      newOption[toCamel(option)] = data[financialOption][option]
    })
    return {
      name: t(`recommendationEngine:${financialOption}`),
      data: newOption
    }
  })
