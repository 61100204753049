export const normalizeOptions = (options, key) =>
  options.map((value) => ({ value, label: value, option: key }))

export const normalizePrograms = (options, key) =>
  options.map((value) => ({
    value: value.socialReason,
    label: value.socialReason,
    option: key
  }))

export const normalizeDocumets = (options, key) =>
  options.map((value) => ({
    value: value.tipo,
    label: value.name,
    option: key
  }))
