import i18next from 'i18next'

i18next.addResources('es', 'navBar', {
  home: 'Inicio',
  myResults: 'Mis resultados',
  myWayOfBeing: 'Mi forma de ser',
  myFinance: 'Mis finanzas',
  learning: 'Rutas de aprendizaje',
  personalInformation: 'Información Personal',
  purpose: 'Propósitos',
  family: 'Mi entorno',
  events: 'Mis acontecimientos',
  financial: 'Mis finanzas',
  signOut: 'Salir',
  assistance: 'Ayuda',
  adminMode: 'Modo administrador',
  helpUs: 'Ayúdanos a mejorar',
  myPlan: 'Mi plan de acción'
})
