/* eslint-disable */
import { t } from 'i18next'

export const numberWithDots = (value) => {
  const number = value || '0'
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const orderByID = (array) => array.sort((a, b) => a.id - b.id)

export const cleanArray = (array) =>
  array.map((item) => ({ ...item, id: null, content: null }))

export const toCamel = (s) =>
  s.replace(/([-_ ][a-z])/gi, ($1) =>
    $1.toUpperCase().replace('-', '').replace('_', '')
  )

export const getFullToken = () => {
  const queryStringParams = new URLSearchParams(location?.search)
  const token1 = queryStringParams.get('auth1') || ''
  const token2 = queryStringParams.get('auth2') || ''
  const token3 = queryStringParams.get('auth3') || ''
  return `${token1}.${token2}.${token3}`
}

export const orderByNumber = (array, decreases) =>
  array.sort((a, b) => (decreases ? b - a : a - b))

export const textFinanceExpenses = (PERCENT, PERCENT_GREEN, PERCENT_RED) => {
  if (PERCENT <= PERCENT_GREEN) {
    return t('FinanceExpenses:greenContent')
  }
  if (PERCENT > PERCENT_RED) {
    return t('FinanceExpenses:redContent')
  }
  return t('FinanceExpenses:orangeContent')
}

export const progressPercentage = (num, total) => {
  const percentage = 100
  return Math.floor((num * percentage) / total)
}

export const currentMonth = () => {
  const currenDate = new Date()
  return currenDate.getMonth()
}

export const getHasPendingWorlds = (profile) => {
  const pendingWorlds = Object.values(profile).filter(
    (world) => world.complete === false
  )
  return pendingWorlds.length > 0
}

export const formatDate = (date) => {
  if (date) {
    const newDate = new Date(date)
    let month = `${newDate.getMonth() + 1}`
    let day = `${newDate.getDate()}`
    const year = newDate.getFullYear()

    if (month.length < 2) month = `0${month}`
    if (day.length < 2) day = `0${day}`

    return [year, month, day].join('-')
  }
  return null
}

export const convertToDate = (dateString) => {
  const [year, month, day] = dateString.split('-')
  return new Date(year, month - 1, day)
}
