import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  showErrorModal: false,
  showSuccesModal: false,
  errorType: null,
  reportError: false,
  succesType: null,
  errorMessage: null,
  showCifinModal: false,
  showAlertModal: false,
  showFiltersDashboardModal: false,
  showFiltersTableModal: false,
  nextRoute: null,
  typeOfAlertModal: null
}

const reducerDescription = {
  primaryActions: [actions.POST_EMAIL],
  override: {
    [actions.SHOW_ERROR_MODAL]: (state, action) => ({
      ...state,
      showErrorModal: action.payload.showErrorModal,
      errorType: action.payload.errorType,
      reportError: action.payload.reportError
        ? action.payload.reportError
        : false,
      errorMessage: action.payload.errorMessage
    }),
    [actions.SHOW_SUCCESS_MODAL]: (state, action) => ({
      ...state,
      showSuccesModal: action.payload.showSuccesModal,
      succesType: action.payload.succesType
    }),
    [actions.SHOW_CIFIN_MODAL]: (state, action) => ({
      ...state,
      showCifinModal: action.payload
    }),
    [actions.SHOW_ALERT_MODAL]: (state, action) => ({
      ...state,
      showAlertModal: action.payload.isOpen,
      nextRoute: action.payload.nextRoute,
      typeOfAlertModal: action.payload.typeOfAlertModal
    }),
    [actions.SHOW_FILTERS_DASHBOARD_MODAL]: (state) => ({
      ...state,
      showFiltersDashboardModal: !state.showFiltersDashboardModal
    }),
    [actions.SHOW_FILTERS_TABLE_MODAL]: (state) => ({
      ...state,
      showFiltersTableModal: !state.showFiltersTableModal
    })
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
