import i18next from 'i18next'

i18next.addResources('es', 'createUsers', {
  title: 'Usuarios',
  createUser: 'Crear usuario',
  loadUsers: 'Carga masiva de usuarios ',
  selecLoad: 'Selecciona un archivo para cargar',
  infoLoad: 'Carga solamente los tipos de archivos permitidos',
  tableTitle: 'Clientes Salud Financiera',
  formTitle: 'Crear usuarios',
  bulkLoadTitle: 'Carga masiva de usuarios',
  button: 'CREAR USUARIO',
  loadButton: 'CARGAR ARCHIVO ',
  name: 'Nombre completo*',
  phone: 'Teléfono',
  email: 'Correo electrónico*',
  city: 'Ciudad',
  document: 'Nro. documento*',
  program_name: 'Programa',
  general_state: 'Estado',
  observations: 'Hallazgos',
  conversation_type: 'Conversatorio',
  taker_name: 'Tomador',
  new_valoration_exception: 'Excepcion Nueva Valoración',
  vpc: 'VPC',
  okMessage: 'Los Usuarios fueron creados correctamente!',
  createMessage: 'El usuario fue creado correctamente.',
  badRequestMessage: 'Algo salio mal, verifica el reporte de errores!',
  errorMessage: 'Algo salio mal, contacte al administrador de sistema!',
  forbiddenMessage: 'Tu sesión ha expirado!',
  serverErrorMessage: '¡Ups, algo va mal!. Inténtalo en un minuto'
})
