import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  events: [{ name: null, eventOptions: [] }],
  options: [],
  userEvents: null,
  screen: 0,
  side: 0,
  noneOption: {
    id: 'none',
    text: 'Ninguno de los anteriores',
    active: false
  },
  isEdit: false,
  eventsLoading: false,
  enableDoneButton: false
}

const reducerDescription = {
  primaryActions: [
    actions.POST_EVENTS,
    actions.GET_DATA,
    actions.GET_USER_DATA
  ],
  override: {
    [actions.SIDE_STATE]: (state, action) => ({
      ...state,
      side: action.payload
    }),
    [actions.CURRENT_OPTIONS]: (state, action) => ({
      ...state,
      options: action.payload
    }),
    [actions.USER_EVENTS_SELECTED]: (state, action) => ({
      ...state,
      userEvents: action.payload
    }),
    [actions.SELECT_NONE]: (state, action) => ({
      ...state,
      noneOption: {
        active: action.payload,
        id: 'none',
        text: 'Ninguno de los anteriores'
      }
    }),
    [actions.SET_EDIT]: (state, action) => ({
      ...state,
      isEdit: action.payload
    }),
    [actions.RESET_STATE]: () => ({
      ...initialState
    }),
    [actions.ENABLE_DONE_BUTTON]: (state, action) => ({
      ...state,
      enableDoneButton: action.payload
    })
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
