import { t } from 'i18next'

import { toCamel } from './generalFunctions'
import { INTERNAL_CODE } from './constants'

import actions from '../redux/Auth/actions'

export const dictionary = {
  authenticationError: t('utils:authenticationError'),
  unconfirmedUser: t('utils:unconfirmedUser'),
  registeredUser: t('utils:registeredUser'),
  unregisteredUser: t('utils:unregisteredUser'),
  databaseError: t('utils:databaseError'),
  activeUser: t('utils:activeUser'),
  notAllowedError: t('utils:userWithoutPermission')
}

export const setErrorWithDictionary = (dispatch, property, data) => {
  const camelCaseError = toCamel(data[INTERNAL_CODE])
  dispatch(actions.setError({ value: dictionary[camelCaseError], property }))
}

export const months = {
  Jan: t('utils:january'),
  Feb: t('utils:february'),
  Mar: t('utils:march'),
  Apr: t('utils:april'),
  May: t('utils:may'),
  Jun: t('utils:june'),
  Jul: t('utils:july'),
  Aug: t('utils:august'),
  Sep: t('utils:september'),
  Oct: t('utils:october'),
  Nov: t('utils:november'),
  Dec: t('utils:december')
}
