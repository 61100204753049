export const ADMIN_ROUTES = {
  DASHBOARD: '/dashboard',
  USERS: '/users',
  USERSBYALLPROGRAMS: '/users-by-all-programs',
  CREATE_USERS: '/users/create',
  CONVERSATIONS: '/conversations',
  EMAILS: '/emails',
  PROGRAMS_PANEL: '/users/programs',
  CREATE_PROGRAMS: '/users/create_programs',
  SCHEDULINGS: '/users/schedulings'
}

export const USER_ROUTES = {
  HOME_CARDS: '/home',
  PROFILE: '/profile',
  PURPOSE: '/purpose',
  FAMILY: '/profile/family',
  EVENTS: '/profile/events',
  THE_WAY_I_AM: '/profile/the_way_i_am',
  FINANCIAL: '/profile/financial',
  EDIT_PURPOSE: '/purpose/edit',
  EDIT_FAMILY: '/profile/family/edit',
  EDIT_EVENTS: '/profile/events/edit',
  EDIT_THE_WAY_I_AM: '/profile/the_way_i_am/edit',
  EDIT_FINANCIAL: '/profile/financial/edit',
  FINANCE: '/finance',
  MY_WAY_OF_BEING: '/my_way_of_being',
  ASSISTANCE: '/assistance',
  MY_PLAN: '/my_plan',
  FINANCIAL_RESULTS: '/financial_results'
}

const COMMON_ROUTES = {
  HOME: '/',
  APP: '/app',
  LOGIN: '/login',
  LOGOUT: '/logout',
  AUTH: '/auth'
}

export const ADMIN_ROUTES_REDIRECT_ON = [
  '/',
  '/dashboard',
  '/users',
  '/users-by-all-programs',
  '/users/create',
  '/users/schedulings',
  '/conversations',
  '/emails',
  '/users/programs',
  '/users/create_programs'
]

const Routes = Object.assign(COMMON_ROUTES, ADMIN_ROUTES, USER_ROUTES)

export const REDIRECT_SWITCH_ROUTES = 'https://vivemas.segurossura.com.co/'

export default Routes
