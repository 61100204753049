import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  side: 0,
  currentSection: 0,
  stepsToBack: 0,
  nextNavigationStatus: false,
  sectionsUnlocked: [
    'onBoarding',
    'ownership',
    'single',
    'COUPLE',
    'CHILD',
    'PARENT',
    'SIBLING',
    'PET',
    'doneFamily'
  ],
  familyDataStruct: {
    type: null,
    ownership: null,
    dependency: null,
    depends: null,
    dependsinput: null,
    disability: null,
    disabilityinput: null,
    options: [],
    single: null,
    wholive: null
  },
  environment: {
    dependency: null,
    options: { PET: 0 }
  },
  isEdit: false,
  familyLoading: false,
  enableDoneButton: false,
  validation: {
    enabledOwnershipOptions: false,
    enabledOwnOption: false,
    hasError: false
  }
}

const reducerDescription = {
  primaryActions: [actions.POST_FAMILY, actions.GET_FAMILY],
  override: {
    [actions.SCREEN_STATE]: (state, action) => ({
      ...state,
      screen: action.payload
    }),
    [actions.SIDE_STATE]: (state, action) => ({
      ...state,
      side: action.payload
    }),
    [actions.FAMILY_DATA_STRUCT]: (state, action) => ({
      ...state,
      familyDataStruct: action.payload
    }),
    [actions.NEXT_NAVIGATION]: (state, action) => ({
      ...state,
      nextNavigationStatus: action.payload
    }),
    [actions.SECTIONS_UNLOCKED]: (state, action) => ({
      ...state,
      sectionsUnlocked: action.payload
    }),
    [actions.CURRENT_SECTION]: (state, action) => ({
      ...state,
      currentSection: action.payload
    }),
    [actions.SET_EDIT]: (state, action) => ({
      ...state,
      isEdit: action.payload
    }),
    [actions.RESET_STATE]: () => ({
      ...initialState
    }),
    [actions.ENABLE_DONE_BUTTON]: (state, action) => ({
      ...state,
      enableDoneButton: action.payload
    }),
    [actions.ENABLE_OWNERSHIP_OPTIONS]: (state, action) => ({
      ...state,
      validation: {
        ...state.validation,
        enabledOwnershipOptions: action.payload
      }
    }),
    [actions.ENABLE_OWN_OPTION]: (state, action) => ({
      ...state,
      validation: {
        ...state.validation,
        enabledOwnOption: action.payload
      }
    }),
    [actions.SET_HAS_ERROR]: (state, action) => ({
      ...state,
      validation: {
        ...state.validation,
        hasError: action.payload
      }
    })
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
