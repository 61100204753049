import i18next from 'i18next'

i18next.addResources('es', 'CreatePrograms', {
  createprograms: 'Crear Programa',
  createEgroup: 'Grupo Económico',
  buttonLabel: 'CONTINUAR',
  inputLabelEgroup: 'Ingrese el grupo económico',
  holderNumber: 'Cantidad de tomadores',
  holderNumber2: '¿Cuántos tomadores tiene este programa?',
  buttonLabelFinish: 'Crear programa',
  economicGroupTittle: 'Error!',
  economicGroupError:
    '¡El grupo económico que estás ingresando ya existe,  por favor verifícalo!',
  accept: 'Aceptar',
  createMessage: '¡La creación fué exitosa!.',
  badRequestMessage: 'Algo salio mal, verifica el reporte de errores!',
  forbiddenMessage: 'Tu sesión ha expirado!',
  serverErrorMessage: '¡Ups, algo va mal!. Inténtalo en un minuto',
  economicGroup:
    '¡Ups, el grupo económico que estas ingresando ya existe. Por favor ingresa otro!',
  programError: 'El programa debe ser alfabético',
  areadyExist: '¿El grupo económico ya existe?',
  areadyExistHolder: '¿El Tomador ya existe?',
  economicGroupSelect: 'Seleccione un grupo económico',
  holderSelect: 'Seleccione un Tomador'
})
