/* eslint-disable no-magic-numbers */
import {
  createTypes,
  completeTypes,
  withPostSuccess,
  withPostFailure,
  withPreFetch
} from 'redux-recompose'

import { normalizeOptions } from '../../schemas/adminModuleSchema'
import modalActions from '../../redux/Modal/actions'
import {
  tableUserService,
  adminService,
  emailsTableService
} from '../../services/BifiService'

export const actions = createTypes(
  completeTypes(
    [
      'GET_TABLE_DATA',
      'GET_SOURCES_DATA',
      'GET_LEVEL_RISK',
      'GET_GENERAL_STATES',
      'GET_PROGRAMS_DATA',
      'GET_DOCUMENT_DATA',
      'GET_CITIES_DATA',
      'GET_PROFILES_DATA',
      'UPDATE_TABLE_DATA',
      'DOWNLOAD_USERS',
      'GET_STATE_PROCESS',
      'GET_ECONOMIC_GROUPS',
      'GET_GENERAL_STATESFULL',
      'SEND_EMAIL_USER'
    ],
    [
      'ADD_ROW_SELECTED',
      'SET_SHOW_CONFIRM',
      'DELETE_ROW_SELECTED',
      'SELECT_ALL_ROWS',
      'SET_SHOW_FILTER_CARD',
      'SET_SHOW_DOCUMENT_CARD',
      'SET_GENERAL_STATE_OPTIONS',
      'SET_SOURCE_OPTIONS',
      'CHANGE_FILTER',
      'CHANGE_FILTER_DATES',
      'CLEAR_FILTERS',
      'CHANGE_OPTIONS',
      'SET_FORM_ERROR',
      'RESET_FORM_ERROR',
      'SET_TAKER_ID',
      'SET_DISABLED',
      'SET_DISABLED_TAKER',
      'SET_FORM_ERROR',
      'UPDATE_USER_SELECTED',
      'SET_REPORT_USER',
      'EMAIL_OPTION_NAME',
      'ADD_EMAIL_TYPE',
      'ADD_USERS_TOEMAIL',
      'ENABLE_SUCCESS_MESSAGE',
      'SET_SHOW_ACTIONS',
      'SET_VALUE_UPDATE',
      'SET_EMPTY_USERS',
      'SET_DOCUMENT',
      'HIDE_EMAIL_ACTIONS',
      'SET_ENABLE_BUTTON',
      'SET_SHOW_MODAL',
      'SET_STATUS_CODE',
      'REPLACE',
      'SET_SHOW_MESSAGE',
      'SET_SENDING_EMAIL'
    ]
  ),
  '@@EMAILS'
)

export const actionsCreators = {
  getTableData: (filters) => ({
    type: actions.GET_TABLE_DATA,
    target: 'tableData',
    service: emailsTableService.getTableData,
    payload: filters,
    successSelector: (response) => response.data,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionsCreators.setStatusCode(response?.status))
      }),
      withPostFailure((dispacth, response) => {
        dispacth(actionsCreators.setStatusCode(response?.status))
      })
    ]
  }),

  sendEmail: (send) => ({
    type: actions.SEND_EMAIL_USER,
    service: emailsTableService.sendEmail,
    payload: send,
    injections: [
      withPreFetch((dispatch) => {
        dispatch(actionsCreators.setSendingEmail(true))
        dispatch(actionsCreators.setStatusCode(null))
        dispatch(actionsCreators.setShowModalMessage(true))
      }),
      withPostSuccess((dispatch, response) => {
        dispatch(actionsCreators.setStatusCode(response?.status))
        dispatch(actionsCreators.setSendingEmail(false))
        dispatch(actionsCreators.showActions(false))
        dispatch(actionsCreators.getTableData())
      }),
      withPostFailure((dispatch, response) => {
        if (response.data && response.data.status === 'authentication_error') {
          dispatch(
            modalActions.showErrorModal({
              showErrorModal: true,
              errorType: 'AUTHENTICATION_ERROR'
            })
          )
        }
      })
    ]
  }),
  getSources: () => ({
    type: actions.GET_SOURCES_DATA,
    target: 'sourcesList',
    service: adminService.getSourcesData,
    successSelector: (response) =>
      normalizeOptions(response.data, 'sourcesList')
  }),
  getLevelRisk: () => ({
    type: actions.GET_LEVEL_RISK,
    target: 'levelRiskList',
    service: tableUserService.getLevelRisk,
    successSelector: (response) => response.data
  }),
  getStateProcess: () => ({
    type: actions.GET_STATE_PROCESS,
    target: 'processList',
    service: tableUserService.getStateProcess,
    successSelector: (response) =>
      normalizeOptions(response.data, 'processList')
  }),
  getEconomicGroups: () => ({
    type: actions.GET_ECONOMIC_GROUPS,
    target: 'groupsList',
    service: tableUserService.getEconomicGroups,
    successSelector: (response) => normalizeOptions(response.data, 'groupsList')
  }),
  getCities: () => ({
    type: actions.GET_CITIES_DATA,
    target: 'citiesList',
    service: tableUserService.getCitiesData,
    successSelector: (response) => normalizeOptions(response.data, 'citiesList')
  }),
  getPrograms: (programs) => ({
    type: actions.GET_PROGRAMS_DATA,
    target: 'programsData',
    service: tableUserService.getProgramsData,
    payload: programs,
    successSelector: (response) => response.data
  }),
  getGeneralStates: () => ({
    type: actions.GET_GENERAL_STATES,
    target: 'generalStateList',
    service: tableUserService.getGeneralStates,
    successSelector: (response) => response.data.detail,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(
          actionsCreators.setGeneralStateOptions(
            normalizeOptions(response.data.detail, 'generalStateOptions')
          )
        )
      })
    ]
  }),
  getGeneralStatesFull: (fullList) => ({
    type: actions.GET_GENERAL_STATESFULL,
    target: 'generalStateListFull',
    service: tableUserService.getGeneralStateFull,
    successSelector: (response) => response.data.detail,
    payload: fullList,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(
          actionsCreators.setGeneralStateOptions(
            normalizeOptions(response.data.detail, 'generalStateOptionsFull')
          )
        )
      })
    ]
  }),
  downloadUsers: () => ({
    type: actions.DOWNLOAD_USERS,
    target: 'reportUser',
    service: tableUserService.downloadUsers,
    successSelector: (response) => response.data
  }),
  setGeneralStateOptions: (value) => ({
    type: actions.SET_GENERAL_STATE_OPTIONS,
    payload: value
  }),
  changeFilter: (value) => ({
    type: actions.CHANGE_FILTER,
    payload: value
  }),
  changeFilterDates: (value) => ({
    type: actions.CHANGE_FILTER_DATES,
    payload: value
  }),
  setShowFilterCard: (value) => ({
    type: actions.SET_SHOW_FILTER_CARD,
    payload: value
  }),
  setShowDocumentCard: (value) => ({
    type: actions.SET_SHOW_DOCUMENT_CARD,
    payload: value
  }),
  clearFilters: () => ({
    type: actions.CLEAR_FILTERS
  }),
  changeOptions: (value) => ({
    type: actions.CHANGE_OPTIONS,
    payload: value
  }),
  setFormError: (value) => ({
    type: actions.SET_FORM_ERROR,
    payload: value
  }),
  resetFiltersError: () => ({
    type: actions.RESET_FORM_ERROR
  }),
  addRowSelected: (payload) => ({
    type: actions.ADD_ROW_SELECTED,
    payload
  }),
  addEmailType: (payload) => ({
    type: actions.ADD_EMAIL_TYPE,
    payload
  }),
  addUserstoEmail: (payload) => ({
    type: actions.ADD_USERS_TOEMAIL,
    payload
  }),
  deleteRowSelected: (payload) => ({
    type: actions.DELETE_ROW_SELECTED,
    payload
  }),
  hideEmailActions: (value) => ({
    type: actions.HIDE_EMAIL_ACTIONS,
    payload: value
  }),
  updateUserSelected: (payload) => ({
    type: actions.UPDATE_USER_SELECTED,
    payload
  }),
  setShowConfirm: (payload) => ({
    type: actions.SET_SHOW_CONFIRM,
    payload
  }),
  selectAll: (payload) => ({
    type: actions.SELECT_ALL_ROWS,
    payload
  }),
  setTakerId: (value) => ({
    type: actions.SET_TAKER_ID,
    payload: value
  }),
  setDisabled: (value) => ({
    type: actions.SET_DISABLED,
    payload: value
  }),
  setDisabledTaker: (value) => ({
    type: actions.SET_DISABLED_TAKER,
    payload: value
  }),
  setOptionName: (value) => ({
    type: actions.EMAIL_OPTION_NAME,
    payload: value
  }),
  setReportUser: (value) => ({
    type: actions.SET_REPORT_USER,
    payload: value
  }),
  enableSuccesMessage: (value) => ({
    type: actions.ENABLE_SUCCESS_MESSAGE,
    payload: value
  }),
  showActions: (value) => ({
    type: actions.SET_SHOW_ACTIONS,
    payload: value
  }),
  setEmptyUsers: (value) => ({
    type: actions.SET_EMPTY_USERS,
    payload: value
  }),
  setDocument: (value) => ({
    type: actions.SET_DOCUMENT,
    payload: value
  }),
  setEnableButton: (value) => ({
    type: actions.SET_ENABLE_BUTTON,
    payload: value
  }),
  setShowModal: (value) => ({
    type: actions.SET_SHOW_MODAL,
    payload: value
  }),
  setShowModalMessage: (value) => ({
    type: actions.SET_SHOW_MESSAGE,
    payload: value
  }),
  setStatusCode: (value) => ({
    type: actions.SET_STATUS_CODE,
    payload: value
  }),
  setSendingEmail: (value) => ({
    type: actions.SET_SENDING_EMAIL,
    payload: value
  })
}

export default actionsCreators
