/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
import {
  createTypes,
  completeTypes,
  withPostSuccess,
  withPostFailure
} from 'redux-recompose'

import { normalizeOptions } from '../../schemas/adminModuleSchema'
import modalActions from '../../redux/Modal/actions'
import { tableUserService } from '../../services/BifiService'
import authActions from '../Auth/actions'

export const actions = createTypes(
  completeTypes(
    [
      'GET_TABLE_DATA',
      'GET_SOURCES_DATA',
      'GET_LEVEL_RISK',
      'GET_GENERAL_STATES',
      'GET_PROGRAMS_DATA',
      'GET_DOCUMENT_DATA',
      'GET_CITIES_DATA',
      'GET_CONVERSATIONS_DATA',
      'UPDATE_TABLE_DATA',
      'DOWNLOAD_USERS',
      'GET_STATE_PROCESS',
      'GET_ECONOMIC_GROUPS',
      'GET_GENERAL_STATESFULL',
      'GET_VPC_DATA'
    ],
    [
      'ADD_ROW_SELECTED',
      'SET_TABLE_COLUMNS',
      'DELETE_ROW_SELECTED',
      'SELECT_ALL_ROWS',
      'SET_SHOW_FILTER_CARD',
      'SET_SHOW_DOCUMENT_CARD',
      'SET_GENERAL_STATE_OPTIONS',
      'SET_SOURCE_OPTIONS',
      'CHANGE_FILTER',
      'CHANGE_FILTER_DATES',
      'CHANGE_FILTER_CURRENT_VALORATION_DATES',
      'CLEAR_FILTERS',
      'CHANGE_OPTIONS',
      'SET_FORM_ERROR',
      'RESET_FORM_ERROR',
      'SET_TAKER_ID',
      'SET_DISABLED',
      'SET_DISABLED_TAKER',
      // 'SET_FORM_ERROR',
      'UPDATE_USER_SELECTED',
      'SET_REPORT_USER',
      'UPDATE_OPTION_NAME',
      'ADD_UPDATE_TYPE',
      'ADD_USERS_TOUPDATE',
      'ENABLE_SUCCESS_MESSAGE',
      'SET_SHOW_ACTIONS',
      'SET_VALUE_UPDATE',
      'SET_EMPTY_USERS',
      'SET_DOCUMENT',
      'SET_SHOW_UPDATE_CARD',
      // 'SET_ENABLE_BUTTON',
      'SET_NIT_UPDATE',
      'SET_PROGRAMS_DATA',
      'SET_SHOW_MODAL',
      'SET_STATUS_CODE',
      'SET_ERROR_MESSAGE'
    ]
  ),
  '@@USERS'
)
export const actionsCreators = {
  getTableData: (filters) => ({
    type: actions.GET_TABLE_DATA,
    target: 'tableData',
    service: tableUserService.getTableData,
    payload: filters,
    successSelector: (response) => response.data,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionsCreators.setStatusCode(response?.status))
      }),
      withPostFailure((dispacth, response) => {
        dispacth(actionsCreators.setStatusCode(response?.status))
        dispacth(
          actionsCreators.setErrorMessage(response?.data?.message) ||
            'Oops! lgo salio mal.'
        )
      })
    ]
  }),
  updateTableData: (update) => ({
    type: actions.UPDATE_TABLE_DATA,
    service: tableUserService.updateTableData,
    payload: update,
    injections: [
      withPostSuccess((dispatch, response) => {
        const filters = {
          filters: {
            documents: update.users.map((user) => user.document)
          }
        }
        if (response.data.length === 0) {
          dispatch(actionsCreators.getTableData(filters))
          dispatch(actionsCreators.setShowActions(false))
          dispatch(actionsCreators.setShowDocumentCard(false))
          dispatch(actionsCreators.setShowFilterCard(false))
          dispatch(actionsCreators.setShowUpdateCard(true))
          dispatch(actionsCreators.enableSuccesMessage(true))
          setTimeout(() => {
            dispatch(actionsCreators.enableSuccesMessage(false))
          }, 5000)
          dispatch(authActions.getUser())
        } else {
          dispatch(
            modalActions.showErrorModal({
              showErrorModal: true,
              errorType: 'USER_ERROR',
              errorMessage: response.data
            })
          )
        }
      }),
      withPostFailure((dispatch, response) => {
        if (response.data && response.data.status === 'authentication_error') {
          dispatch(
            modalActions.showErrorModal({
              showErrorModal: true,
              errorType: 'AUTHENTICATION_ERROR'
            })
          )
        }
      })
    ]
  }),
  getLevelRisk: () => ({
    type: actions.GET_LEVEL_RISK,
    target: 'levelRiskList',
    service: tableUserService.getLevelRisk,
    successSelector: (response) => response.data
  }),
  getStateProcess: () => ({
    type: actions.GET_STATE_PROCESS,
    target: 'processList',
    service: tableUserService.getStateProcess,
    successSelector: (response) =>
      normalizeOptions(response.data, 'processList')
  }),
  getEconomicGroups: () => ({
    type: actions.GET_ECONOMIC_GROUPS,
    target: 'groupsList',
    service: tableUserService.getEconomicGroups,
    successSelector: (response) => normalizeOptions(response.data, 'groupsList')
  }),
  getCities: () => ({
    type: actions.GET_CITIES_DATA,
    target: 'citiesList',
    service: tableUserService.getCitiesData,
    successSelector: (response) => normalizeOptions(response.data, 'citiesList')
  }),
  getVpc: () => ({
    type: actions.GET_VPC_DATA,
    target: 'vpcList',
    service: tableUserService.getVpcData,
    successSelector: (response) => normalizeOptions(response.data, 'vpcList')
  }),
  getPrograms: (programs) => ({
    type: actions.GET_PROGRAMS_DATA,
    target: 'programsData',
    service: tableUserService.getProgramsData,
    payload: programs,
    successSelector: (response) => response.data
  }),
  getGeneralStates: () => ({
    type: actions.GET_GENERAL_STATES,
    target: 'generalStateList',
    service: tableUserService.getGeneralStates,
    successSelector: (response) => response.data.detail,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(
          actionsCreators.setGeneralStateOptions(
            normalizeOptions(response.data.detail, 'generalStateOptions')
          )
        )
      })
    ]
  }),
  getGeneralStatesFull: (fullList) => ({
    type: actions.GET_GENERAL_STATESFULL,
    target: 'generalStateListFull',
    service: tableUserService.getGeneralStateFull,
    successSelector: (response) => response.data.detail,
    payload: fullList,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(
          actionsCreators.setGeneralStateOptions(
            normalizeOptions(response.data.detail, 'generalStateOptionsFull')
          )
        )
      })
    ]
  }),
  downloadUsers: () => ({
    type: actions.DOWNLOAD_USERS,
    target: 'reportUser',
    service: tableUserService.downloadUsers,
    successSelector: (response) => response.data
  }),
  setGeneralStateOptions: (value) => ({
    type: actions.SET_GENERAL_STATE_OPTIONS,
    payload: value
  }),
  changeFilter: (value) => ({
    type: actions.CHANGE_FILTER,
    payload: value
  }),
  changeFilterDates: (value) => ({
    type: actions.CHANGE_FILTER_DATES,
    payload: value
  }),
  changeFilterCurrentValorationDates: (value) => ({
    type: actions.CHANGE_FILTER_CURRENT_VALORATION_DATES,
    payload: value
  }),
  setShowFilterCard: (value) => ({
    type: actions.SET_SHOW_FILTER_CARD,
    payload: value
  }),
  setShowDocumentCard: (value) => ({
    type: actions.SET_SHOW_DOCUMENT_CARD,
    payload: value
  }),
  setShowUpdateCard: (value) => ({
    type: actions.SET_SHOW_UPDATE_CARD,
    payload: value
  }),
  clearFilters: () => ({
    type: actions.CLEAR_FILTERS
  }),
  changeOptions: (value) => ({
    type: actions.CHANGE_OPTIONS,
    payload: value
  }),
  setFormError: (value) => ({
    type: actions.SET_FORM_ERROR,
    payload: value
  }),
  resetFiltersError: () => ({
    type: actions.RESET_FORM_ERROR
  }),
  addRowSelected: (payload) => ({
    type: actions.ADD_ROW_SELECTED,
    payload
  }),
  addUpdateType: (payload) => ({
    type: actions.ADD_UPDATE_TYPE,
    payload
  }),
  addUserstoUpdate: (payload) => ({
    type: actions.ADD_USERS_TOUPDATE,
    payload
  }),
  deleteRowSelected: (payload) => ({
    type: actions.DELETE_ROW_SELECTED,
    payload
  }),
  updateUserSelected: (payload) => ({
    type: actions.UPDATE_USER_SELECTED,
    payload
  }),
  setTableColumns: (payload) => ({
    type: actions.SET_TABLE_COLUMNS,
    payload
  }),
  selectAll: (payload) => ({
    type: actions.SELECT_ALL_ROWS,
    payload
  }),
  setTakerId: (value) => ({
    type: actions.SET_TAKER_ID,
    payload: value
  }),
  setTakerNitUpdate: (value) => ({
    type: actions.SET_NIT_UPDATE,
    payload: value
  }),
  setDisabled: (value) => ({
    type: actions.SET_DISABLED,
    payload: value
  }),
  setDisabledTaker: (value) => ({
    type: actions.SET_DISABLED_TAKER,
    payload: value
  }),
  setOptionName: (value) => ({
    type: actions.UPDATE_OPTION_NAME,
    payload: value
  }),
  setReportUser: (value) => ({
    type: actions.SET_REPORT_USER,
    payload: value
  }),
  enableSuccesMessage: (value) => ({
    type: actions.ENABLE_SUCCESS_MESSAGE,
    payload: value
  }),
  setShowActions: (value) => ({
    type: actions.SET_SHOW_ACTIONS,
    payload: value
  }),
  setEmptyUsers: (value) => ({
    type: actions.SET_EMPTY_USERS,
    payload: value
  }),
  setDocument: (value) => ({
    type: actions.SET_DOCUMENT,
    payload: value
  }),

  setPrograms: (value) => ({
    type: actions.SET_PROGRAMS_DATA,
    payload: value
  }),
  setShowModal: (value) => ({
    type: actions.SET_SHOW_MODAL,
    payload: value
  }),
  setStatusCode: (value) => ({
    type: actions.SET_STATUS_CODE,
    payload: value
  }),
  setErrorMessage: (value) => ({
    type: actions.SET_ERROR_MESSAGE,
    payload: value
  })
}

export default actionsCreators
