import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

import { getCurrentRoleInterface } from '../../utils/roleFunctions'

const initialState = {
  userExperience: {
    wayOfBeingView: false,
    financialView: false,
    showExperience: false,
    showButton: false,
    showVideo: false,
    enabledButtonPhone: false,
    enabledButtonEmail: false
  },
  currentRoleInterface: null,
  controlClose: false
}

const reducerDescription = {
  primaryActions: [actions.PUT_DATA, actions.GET_DATA],
  override: {
    [actions.SET_CONTROL_CLOSE]: (state, action) => ({
      ...state,
      controlClose: action.payload
    }),
    [actions.SET_ROLE_INTERFACE]: (state, action) => ({
      ...state,
      currentRoleInterface: getCurrentRoleInterface(action.payload)
    })
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
