/* eslint-disable react/prop-types */
import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import { DEFAULT_MAX_DURATION } from './constants'

// import Loading from '~components/Spinner/components/loading';
import Loading from '../../components/Spinner/components/loading'

function CustomSuspense({ maxDuration, fallback, children }) {
  return (
    <Suspense maxDuration={maxDuration} fallback={fallback}>
      {children}
    </Suspense>
  )
}

CustomSuspense.defaultProps = {
  fallback: <Loading />,
  maxDuration: DEFAULT_MAX_DURATION
}

CustomSuspense.propTypes = {
  fallback: PropTypes.element,
  maxDuration: PropTypes.number
}

export default CustomSuspense
