import { orderByID } from '../utils/generalFunctions'

export const normalizePurposeData = (boxes) => {
  const boxesWithActive = boxes.map((box) => {
    box.active = false
    delete box.created_at
    delete box.updated_at
    return box
  })

  return orderByID(boxesWithActive)
}

export const normalizePurposeUser = (purposes) =>
  purposes.map((purpose) => {
    purpose.id = purpose.purposeId
    delete purpose.created_at
    delete purpose.updated_at
    delete purpose.purposeId
    delete purpose.userId
    return purpose
  })

export const normalizePurposePriority = (purposes) => {
  const newPurposes = purposes.filter((item) => item.id !== null)
  newPurposes.map((purpose, index) => (purpose.priority = index + 1))
  return newPurposes
}
