import React, { Fragment } from 'react'
import { t } from 'i18next'

import styles from './styles.module.scss'

// import mailIcon from '~assets/ico-mail-dark.svg';
import mailIcon from '../../../../assets/ico-mail-dark.svg'
import whatsappIcon from '../../../../assets/Ico-WhatsApp-Dark.svg'
import phoneIcon from '../../../../assets/ico-phone-dark.svg'

export const contactItems = [
  {
    key: 'mail',
    icon: mailIcon,
    content: process.env.REACT_APP_CONTACT_MAIL
  },
  {
    key: 'whatsapp',
    icon: whatsappIcon,
    content: process.env.REACT_APP_CONTACT_WHATSAPP
  },
  {
    key: 'phone',
    icon: phoneIcon,
    content: process.env.REACT_APP_CONTACT_PHONE
  }
]

const FATAL_ERROR = {
  title: t('errorModal:somethingWrong'),
  bodyFirst: (
    <Fragment>
      {t('errorModal:platformError')} {t('errorModal:contact')}
    </Fragment>
  ),
  bodySecond: (
    <Fragment>
      {t('errorModal:reportError')}
      <br />
      <br />
      <img
        src={mailIcon}
        className={`m-2 ${styles.icon}`}
        alt="mailIcon"
        id="mailIcon"
      />{' '}
      {contactItems[0].content}
      <br />
      <img
        src={whatsappIcon}
        className={`m-2 ${styles.icon}`}
        alt="whatsappIcon"
        id="whatsappIcon"
      />
      {contactItems[1].content}
      <br />
      <img
        src={phoneIcon}
        className={`${styles.icon} ${styles.phoneIcon}`}
        alt="phoneIcon"
        id="phoneIcon"
      />
      {contactItems[2].content}
      <br />
      <br />
      {t('errorModal:thank')}
    </Fragment>
  ),
  button: null
}

export const ERRORS = {
  USER_WITHOUT_FLOW: FATAL_ERROR,
  CLIENT_ERROR: {
    title: t('errorModal:somethingWrong'),
    body: (
      <Fragment>
        {t('errorModal:platformError')} {t('errorModal:repeat')}
      </Fragment>
    ),
    button: t('errorModal:update'),
    styleButton: 'acceptButton'
  },
  AUTHENTICATION_ERROR: {
    title: 'Algo salió mal',
    body: (
      <Fragment>
        {t('errorModal:token')} <br />
      </Fragment>
    ),
    button: t('errorModal:update'),
    styleButton: 'acceptButton'
  },
  USER_ERROR: {
    title: t('errorModal:somethingWrong'),
    button: t('errorModal:close'),
    styleButton: 'acceptButton'
  },
  USER_SUCESS: {
    title: t('errorModal:somethingGreat'),
    button: t('errorModal:close'),
    styleButton: 'acceptButton'
  },
  NOT_FOUND: FATAL_ERROR
}
