import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  screen: 0,
  questionNumber: 0,
  questions: [],
  results: [],
  dataTreatmentLink: ' ',
  pollLoading: false,
  pollDataStruct: [],
  enableDoneButton: false
}

const reducerDescription = {
  primaryActions: [
    actions.POST_POLL,
    actions.GET_RESULTS,
    actions.GET_QUESTIONS,
    actions.GET_DOCUMENT_LINK
  ],
  override: {
    [actions.SCREEN_STATE]: (state, action) => ({
      ...state,
      screen: action.payload
    }),
    [actions.QUESTION_STATE]: (state, action) => ({
      ...state,
      questionNumber: action.payload
    }),
    [actions.POLL_DATA_STRUCT]: (state, action) => ({
      ...state,
      pollDataStruct: action.payload
    }),
    [actions.RESET_STATE]: (state) => ({
      ...state,
      screen: 0,
      questionNumber: 0
    }),
    [actions.ENABLE_DONE_BUTTON]: (state, action) => ({
      ...state,
      enableDoneButton: action.payload
    })
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
