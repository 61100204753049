/* eslint-disable import/no-anonymous-default-export */
export default {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  CUSTOMER: 'CUSTOMER',
  GUEST: 'GUEST'
}

export const ADMIN_ROLES = ['ADMINBUSINESS', 'SUPERADMIN', 'ADMINSWITCHES']
