import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose'
import { switchesService } from '../../services/BifiService'

export const actions = createTypes(
  completeTypes(['GET_SWITCHES', 'UPDATE_SWITCHES'], ['SET_REDIRECT_SWITCH']),
  '@@SWITCHES'
)

export const actionsCreators = {
  getSwitches: () => ({
    type: actions.GET_SWITCHES,
    target: 'switches',
    service: switchesService.getRedirectSwitch
  }),
  updateSwitches: (body) => ({
    type: actions.UPDATE_SWITCHES,
    target: 'switches',
    service: switchesService.updateRedirectSwitch,
    payload: body,
    injections: [
      withPostSuccess((dispatch, res) =>
        dispatch(actionsCreators.setRedirectSwitch(res.data.data[1][0]))
      )
    ]
  }),
  setRedirectSwitch: (value) => ({
    type: actions.SET_REDIRECT_SWITCH,
    payload: value
  })
}
