/* eslint-disable no-magic-numbers */
import {
  createTypes,
  completeTypes,
  withPostSuccess,
  withPostFailure
} from 'redux-recompose'

import { normalizeOptions } from '../../schemas/adminModuleSchema'
import {
  tableUserService,
  conversationsTableService
} from '../../services/BifiService'

export const actions = createTypes(
  completeTypes(
    [
      'GET_TABLE_DATA',
      'GET_GENERAL_STATES',
      'GET_PROGRAMS_DATA',
      'GET_PROFILES_DATA',
      'GET_CONVERSATIONS_DATA',
      'UPDATE_TABLE_DATA',
      'GET_ECONOMIC_GROUPS'
    ],
    [
      'ADD_ROW_SELECTED',
      'SET_TABLE_COLUMNS',
      'DELETE_ROW_SELECTED',
      'SELECT_ALL_ROWS',
      'SET_SHOW_FILTER_CARD',
      'SET_SHOW_DOCUMENT_CARD',
      'SET_GENERAL_STATE_OPTIONS',
      'SET_SOURCE_OPTIONS',
      'CHANGE_FILTER',
      'CHANGE_FILTER_DATES',
      'CLEAR_FILTERS',
      'CHANGE_OPTIONS',
      'SET_FORM_ERROR',
      'RESET_FORM_ERROR',
      'SET_TAKER_ID',
      'SET_DISABLED',
      'SET_DISABLED_TAKER',
      'SET_FORM_ERROR',
      'UPDATE_USER_SELECTED',
      'SET_REPORT_USER',
      'UPDATE_OPTION_NAME',
      'ADD_UPDATE_TYPE',
      'ADD_USERS_TOUPDATE',
      'ENABLE_SUCCESS_MESSAGE',
      'SET_SHOW_ACTIONS',
      'SET_VALUE_UPDATE',
      'SET_EMPTY_USERS',
      'SET_DOCUMENT',
      'SET_ENABLE_BUTTON',
      'SET_SHOW_MODAL',
      'SET_STATUS_CODE'
    ]
  ),
  '@@CONVERSATIONS'
)

export const actionsCreators = {
  getTableData: (filters) => ({
    type: actions.GET_TABLE_DATA,
    target: 'tableData',
    service: conversationsTableService.getTableData,
    payload: filters,
    successSelector: (response) => response.data,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionsCreators.setStatusCode(response?.status))
      }),
      withPostFailure((dispacth, response) => {
        dispacth(actionsCreators.setStatusCode(response?.status))
      })
    ]
  }),
  getPrograms: (programs) => ({
    type: actions.GET_PROGRAMS_DATA,
    target: 'programsData',
    service: tableUserService.getProgramsData,
    payload: programs,
    successSelector: (response) => response.data
  }),
  getEconomicGroups: () => ({
    type: actions.GET_ECONOMIC_GROUPS,
    target: 'groupsList',
    service: tableUserService.getEconomicGroups,
    successSelector: (response) => normalizeOptions(response.data, 'groupsList')
  }),
  getConversations: () => ({
    type: actions.GET_CONVERSATIONS_DATA,
    target: 'conversationsList',
    service: conversationsTableService.getConversationsData,
    successSelector: (response) =>
      normalizeOptions(response.data, 'conversationsList')
  }),
  changeFilter: (value) => ({
    type: actions.CHANGE_FILTER,
    payload: value
  }),
  changeFilterDates: (value) => ({
    type: actions.CHANGE_FILTER_DATES,
    payload: value
  }),
  setShowFilterCard: (value) => ({
    type: actions.SET_SHOW_FILTER_CARD,
    payload: value
  }),
  setShowDocumentCard: (value) => ({
    type: actions.SET_SHOW_DOCUMENT_CARD,
    payload: value
  }),
  clearFilters: () => ({
    type: actions.CLEAR_FILTERS
  }),
  changeOptions: (value) => ({
    type: actions.CHANGE_OPTIONS,
    payload: value
  }),
  setFormError: (value) => ({
    type: actions.SET_FORM_ERROR,
    payload: value
  }),
  resetFiltersError: () => ({
    type: actions.RESET_FORM_ERROR
  }),
  addRowSelected: (payload) => ({
    type: actions.ADD_ROW_SELECTED,
    payload
  }),
  addUpdateType: (payload) => ({
    type: actions.ADD_UPDATE_TYPE,
    payload
  }),
  addUserstoUpdate: (payload) => ({
    type: actions.ADD_USERS_TOUPDATE,
    payload
  }),
  setTableColumns: (payload) => ({
    type: actions.SET_TABLE_COLUMNS,
    payload
  }),
  selectAll: (payload) => ({
    type: actions.SELECT_ALL_ROWS,
    payload
  }),
  setTakerId: (value) => ({
    type: actions.SET_TAKER_ID,
    payload: value
  }),
  setDisabled: (value) => ({
    type: actions.SET_DISABLED,
    payload: value
  }),
  setDisabledTaker: (value) => ({
    type: actions.SET_DISABLED_TAKER,
    payload: value
  }),
  setReportUser: (value) => ({
    type: actions.SET_REPORT_USER,
    payload: value
  }),
  enableSuccesMessage: (value) => ({
    type: actions.ENABLE_SUCCESS_MESSAGE,
    payload: value
  }),
  showActions: (value) => ({
    type: actions.SET_SHOW_ACTIONS,
    payload: value
  }),
  setEmptyUsers: (value) => ({
    type: actions.SET_EMPTY_USERS,
    payload: value
  }),
  setEnableButton: (value) => ({
    type: actions.SET_ENABLE_BUTTON,
    payload: value
  }),
  setShowModal: (value) => ({
    type: actions.SET_SHOW_MODAL,
    payload: value
  }),
  setStatusCode: (value) => ({
    type: actions.SET_STATUS_CODE,
    payload: value
  })
}

export default actionsCreators
