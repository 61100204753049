export const TABLE = 1

export const ZERO = 0

export const EXPENSES_CONTENT = [
  {
    id: 1,
    title: 'firstTitle',
    content: 'firstContent'
  },
  {
    id: 2,
    title: 'secondTitle',
    content: 'secondContent'
  },
  {
    id: 3,
    title: 'thirdTitle',
    content: 'thirdContent'
  },
  {
    id: 4,
    title: 'fourthTitle',
    content: 'fourthContent'
  },
  {
    id: 5,
    title: 'fifthTitle',
    content: 'fifthContent'
  },
  {
    id: 6,
    title: 'sixthTitle',
    content: 'sixthContent'
  }
]
