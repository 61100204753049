import i18next from 'i18next'

i18next.addResources('es', 'dashboard', {
  dashboard: 'Dashboard',
  filters: 'Filtros',
  totalClients: 'Clientes totales',
  totalLeads: 'Leads totales',
  totalUsers: 'Usuarios totales- Plan de recompensas',
  totalConversations: 'Conversatorios totales',
  completedClients: 'Clientes completos',
  finishedClients: 'Clientes finalizados',
  indicator: 'Indicador',
  states: 'Estado',
  levelRisk: 'Nivel de riesgo',
  monthlyHistory: 'Histórico mensual',
  active: 'Totales',
  completed: 'Completos',
  documents: 'Documentos buscados:',
  update: 'Usuarios que  has actualizado',
  comunIndicator: 'Desde el ultimo mes',
  objetive:
    'El objetivo de la solución es que más del 60% de los clientes completen su valoración.',
  reward:
    'Cantidad total de usuarios que han obtenido nuestros puntos por finalizar su valoración de salud financiera'
})

i18next.addResources('es', 'dashboardStates', {
  Seguimiento: 'Seguimiento',
  Finalizado: 'Finalizado',
  Desistido: 'Desistido',
  'Pendiente Valoracion': 'Pendiente de valoración'
})

i18next.addResources('es', 'dashboardLevelRisk', {
  selfManagement: 'Autogestión',
  low: 'Bajo',
  medium: 'Medio',
  high: 'Alto'
})

i18next.addResources('es', 'dashboardFilters', {
  filters: 'Filtros',
  levelRisk: 'Nivel de riesgo',
  cias: 'Fuentes',
  generalStatesGroup: 'Estado de proceso',
  generalStates: 'Estado de proceso',
  program: 'Programa',
  socialReason: 'Tomador',
  city: 'Ciudad',
  stateProcess: 'Estados Proceso',
  economicGroup: 'Grupo Económico',
  selfManagement: 'Autogestión',
  low: 'Bajo',
  medium: 'Medio',
  mediumHigh: 'Medio - Alto',
  high: 'Alto',
  data: 'Datos',
  source: 'Fuente',
  state: 'Estado',
  date: 'Fecha',
  submit: 'crear filtro',
  clear: 'limpiar filtro',
  to: 'hasta',
  conversations: 'Conversatorio',
  documents: 'Documentos',
  dateToolTip:
    "Esta fecha hace referencia a la cual el usuario hizo por primera vez la valoración completa de Salud Financiera y para 'clientes totales' es la fecha de creación del usuario en la plataforma",
  currentValorationDate: 'Fecha de Valoracion Vigente'
})

i18next.addResources('es', 'dashboardFiltersValidation', {
  endDateEror: 'Debes ingresar la fecha de finalización',
  beginDateError: 'Debes ingresar la fecha de inicio'
})
