import { createTypes, completeTypes } from 'redux-recompose'

// import { ciaExperiencieService } from '~services/BifiService';
import { ciaExperiencieService } from '../../services/BifiService'
// import { normalizeData } from '~schemas/ciaExperienceSchema';
import { normalizeData } from '../../schemas/ciaExperienceSchema'

export const actions = createTypes(
  completeTypes(['GET_DATA'], []),
  '@@CIA_EXPERIENCE'
)

export const actionsCreators = {
  getData: (params) => ({
    type: actions.GET_DATA,
    target: 'ciaExperience',
    service: ciaExperiencieService.getCiaExperience,
    payload: params,
    successSelector: (response) => normalizeData(response.data)
  })
}

export default actionsCreators
