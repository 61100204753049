import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

import ProfileIcon from '../../../../../assets/ico-UserProfile.svg'
// import ProfileIcon from '~assets/ico-UserProfile.svg';

const Toolbar = ({ onHandleClick, onHandleDrawerClick }) => (
  <header className={styles.toolbar}>
    <nav className={`${styles.toolbarNavigation} full-width clearfix`}>
      <div className={styles.toolbarToggleButton}>
        <span
          id="home_toolbar_button"
          type="button"
          onClick={onHandleClick('HOME')}
          className={`${styles.linkToHome} float-left ${styles.logoSura}`}
        />
      </div>

      <div className={styles.userIcon}>
        <div className={styles.userThumbnail}>
          <img
            id="user_thumbnail_image"
            className="thumbnail-image"
            src={ProfileIcon}
            alt="iconImg"
            onClick={onHandleDrawerClick}
          />
        </div>
      </div>
    </nav>
  </header>
)

Toolbar.propTypes = {
  onHandleClick: PropTypes.func.isRequired,
  onHandleDrawerClick: PropTypes.func.isRequired
}

export default Toolbar
