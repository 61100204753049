/* eslint-disable no-magic-numbers */
import {
  createTypes,
  completeTypes,
  withPostSuccess,
  withPostFailure,
  withPreFetch
} from 'redux-recompose'

import { programsTableService } from '../../services/BifiService'
import { normalizeOptions } from '../../schemas/adminModuleSchema'

const sortArray = (data, key) =>
  [...data].sort((x, y) => x[key].localeCompare(y[key]))

const optionsToUpdate = {
  NAME: 'programName',
  CUSTOMTEXT: 'textCustome',
  LEAD: 'lead',
  SOURCE: 'source',
  PROGRAMVPC: 'programVpc'
}

const normalizedDataToUpdate = (data, option, value) => ({
  programName: data.programName,
  nit: data.nit,
  option,
  value
})

const normalizedDataDelete = (data) => ({
  programName: data.programName,
  nit: data.nit
})

export const actions = createTypes(
  completeTypes(
    [
      'GET_TABLE_DATA',
      'UPDATE_TABLE_DATA',
      'GET_SOURCE_DATA',
      'GET_VPC',
      'DELETE_TABLE_DATA'
    ],
    [
      'SET_DATA_SELECTED',
      'CLEAR_DATA_SELECTED',
      'SET_SHOW_MODAL',
      'SET_SHOW_MODAL_ERROR',
      'REPLACE',
      'SET_SHOW_CARD',
      'SET_OPTION_UPDATE_NAME',
      'UPDATE_SELECTION',
      'ADD_UPDATE_TYPE',
      'UPDATE_OPTION_VALUE',
      'SET_SHOW_CONFIRM',
      'CHANGE_FILTER',
      'GET_COUNT_HOLDERS',
      'SET_VALUE_TO_SEARCH',
      'SET_SHOW_HOLDER_CARD',
      'SET_STATUS_CODE',
      'SET_IS_DELETE',
      'SET_PROGRAMS_WORKING',
      'SET_REPORT_DATA',
      'SET_PROGRAMS_UPDATING',
      'SET_SHOW_MODAL_UPDATE'
    ]
  ),
  '@@PROGRAMS'
)

export const actionsCreators = {
  getTableData: (filters) => ({
    type: actions.GET_TABLE_DATA,
    target: 'tableData',
    service: programsTableService.getTableData,
    payload: filters,
    successSelector: (response) =>
      sortArray(sortArray(response.data.programs, 'holder'), 'economicGroup'),
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionsCreators.setStatusCode(response?.status))
      }),
      withPostFailure((dispatch, response) => {
        dispatch(actionsCreators.setStatusCode(response?.status))
        dispatch(actionsCreators.setShowModalError(true))
      })
    ]
  }),
  getTableCount: (filters) => ({
    type: actions.GET_COUNT_HOLDERS,
    target: 'countHolders',
    service: programsTableService.getTableData,
    payload: filters,
    successSelector: (response) => response.data.countHolders
  }),
  getVpc: () => ({
    type: actions.GET_VPC,
    target: 'vpcList',
    service: programsTableService.getVpcData,
    successSelector: (response) => normalizeOptions(response.data, 'vpcList')
  }),
  updateTableData: (data, option, value) => ({
    type: actions.UPDATE_TABLE_DATA,
    service: programsTableService.updateTableData,
    payload: normalizedDataToUpdate(data, option, value),
    injections: [
      withPreFetch((dispatch) => {
        dispatch(actionsCreators.setProgramsUpdating(true))
        dispatch(actionsCreators.setStatusCode(null))
        dispatch(actionsCreators.setShowModalUpdate(true))
        dispatch(actionsCreators.setReportData(null))
      }),
      withPostSuccess((dispatch, response) => {
        dispatch({
          type: actions.REPLACE,
          target: 'tableData',
          index: data.index.toString(),
          payload: {
            ...data,
            [optionsToUpdate[option]]: value
          }
        })
        dispatch(actionsCreators.setReportData(response?.data))
        dispatch(actionsCreators.setStatusCode(response?.status))
        dispatch(actionsCreators.setProgramsUpdating(false))
      }),
      withPostFailure((dispatch, response) => {
        dispatch(actionsCreators.setReportData(response?.data))
        dispatch(actionsCreators.setStatusCode(response?.status))
        dispatch(actionsCreators.setProgramsUpdating(false))
      })
    ]
  }),
  deleteTableData: (data) => ({
    type: actions.DELETE_TABLE_DATA,
    service: programsTableService.deleteTableData,
    payload: normalizedDataDelete(data),
    injections: [
      withPreFetch((dispatch) => {
        dispatch(actionsCreators.setProgramsWorking(true))
        dispatch(actionsCreators.setStatusCode(null))
        dispatch(actionsCreators.setShowModalError(true))
        dispatch(actionsCreators.setReportData(null))
      }),
      withPostSuccess((dispatch, response) => {
        dispatch(actionsCreators.setStatusCode(response?.status))
        dispatch(actionsCreators.setProgramsWorking(false))
        dispatch(actionsCreators.getTableData())
        dispatch(actionsCreators.getTableCount())
      }),
      withPostFailure((dispatch, response) => {
        dispatch(actionsCreators.setReportData(response?.data))
        dispatch(actionsCreators.setStatusCode(response?.status))
        dispatch(actionsCreators.setProgramsWorking(false))
      })
    ]
  }),
  getSource: () => ({
    type: actions.GET_SOURCE_DATA,
    target: 'sourceList',
    service: programsTableService.getSourceData,
    successSelector: (response) => normalizeOptions(response.data, 'sourceList')
  }),
  setDataSelected: (data) => ({
    type: actions.SET_DATA_SELECTED,
    payload: data
  }),
  clearDataSelected: () => ({
    type: actions.CLEAR_DATA_SELECTED
  }),
  setShowModal: (value) => ({
    type: actions.SET_SHOW_MODAL,
    payload: value
  }),

  setShowModalError: (value) => ({
    type: actions.SET_SHOW_MODAL_ERROR,
    payload: value
  }),
  setShowModalUpdate: (value) => ({
    type: actions.SET_SHOW_MODAL_UPDATE,
    payload: value
  }),
  setShowCard: (value) => ({
    type: actions.SET_SHOW_CARD,
    payload: value
  }),
  updateOptionSelected: (payload) => ({
    type: actions.UPDATE_SELECTION,
    payload
  }),
  setOptionUpdateName: (value) => ({
    type: actions.SET_OPTION_UPDATE_NAME,
    payload: value
  }),
  addUpdateType: (payload) => ({
    type: actions.ADD_UPDATE_TYPE,
    payload
  }),
  updateOptionValue: (payload) => ({
    type: actions.UPDATE_OPTION_VALUE,
    payload
  }),
  setShowConfirmCard: (payload) => ({
    type: actions.SET_SHOW_CONFIRM,
    payload
  }),
  changeFilter: (value) => ({
    type: actions.CHANGE_FILTER,
    payload: value
  }),
  setValue: (value) => ({
    type: actions.SET_VALUE_TO_SEARCH,
    payload: value
  }),
  setShowHolderCard: (value) => ({
    type: actions.SET_SHOW_HOLDER_CARD,

    payload: value
  }),
  setStatusCode: (value) => ({
    type: actions.SET_STATUS_CODE,
    payload: value
  }),
  setIsDelete: (value) => ({
    type: actions.SET_IS_DELETE,
    payload: value
  }),
  setProgramsWorking: (value) => ({
    type: actions.SET_PROGRAMS_WORKING,
    payload: value
  }),
  setProgramsUpdating: (value) => ({
    type: actions.SET_PROGRAMS_UPDATING,
    payload: value
  }),
  setReportData: (value) => ({
    type: actions.SET_REPORT_DATA,
    payload: value
  })
}

export default actionsCreators
