import React, { useEffect, useState } from 'react'
import { CircularProgress, Switch } from '@mui/material'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { actionsCreators } from '../../../../../redux/Switches/SwitchActions'

const RedirectSwitch = ({
  redirectSwitch,
  switchoLoader,
  // eslint-disable-next-line react/prop-types
  updateRedirectSwitch
}) => {
  const [value, setValue] = useState(false)

  useEffect(() => {
    setValue(redirectSwitch?.value)
  }, [redirectSwitch, switchoLoader])

  return (
    <div className="d-flex align-items-center">
      <p className="d-flex bg-white p-1 rounded my-0 mx-1">Switch VIVEMAS</p>
      {switchoLoader ? (
        <CircularProgress color="warning" />
      ) : (
        <Switch
          checked={!!value}
          onChange={(e) => {
            setValue(e.target.checked)
            updateRedirectSwitch({ value: e.target.checked })
          }}
          color="warning"
        />
      )}
    </div>
  )
}

RedirectSwitch.propTypes = {
  redirectSwitch: PropTypes.object.isRequired,
  switchoLoader: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  redirectSwitch: state.switchReducer.switches.data,
  switchoLoader: state.switchReducer.switchesLoading
})

const mapDispatchToProps = (dispatch) => ({
  updateRedirectSwitch: (body) => dispatch(actionsCreators.updateSwitches(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(RedirectSwitch)
