import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  screen: 0,
  question: 0,
  isVisited: false,
  finance: {
    entries: {
      manual: 0,
      preLoad: 0,
      total: 0,
      totalWithoutSavings: 0
    },
    expenses: [],
    total: '',
    totalWithoutSavings: ''
  },
  cifin: { data: [], date: null },
  historicalReport: {},
  financeLoading: false,
  table: {
    name: '',
    percentage: '',
    details: []
  },
  financialPlanner: true,
  history: {},
  dates: {
    currentMonth: null,
    currentYear: null
  },
  questionError: null,
  questionAlert: null,
  entriesLoading: false,
  financialPlannerLoading: false,
  enableDoneButton: false,
  currentValue: 0,
  noneOption: {
    id: 'none',
    name: 'No tengo tarjeta de crédito',
    active: false,
    disbled: false
  },
  noneSavings: {
    id: 'none_savings',
    name: 'No tengo ahorro ni inversiones',
    active: false,
    disabled: true
  },
  haveCreditCard: {
    id: 'have_card',
    name: 'Tengo tarjeta de crédito pero no la utilizo',
    active: false,
    disabled: false
  },
  userOptionsSelected: null,
  options: [],

  financeEvents: [{ name: null, financeEventOptions: [] }],
  isEdit: false
}

const reducerDescription = {
  primaryActions: [
    actions.GET_DATA,
    actions.GET_HISTORICAL_REPORT,
    actions.POST_FINANCIAL_PLANNING,
    actions.ADD_ENTRIES,
    actions.HISTORY,
    actions.GET_REPORT_CIFIN
  ],
  override: {
    [actions.SCREEN_STATE]: (state, action) => ({
      ...state,
      screen: action.payload
    }),
    [actions.SET_TABLE]: (state, action) => ({
      ...state,
      table: action.payload
    }),
    [actions.SET_DATES]: (state, action) => ({
      ...state,
      dates: action.payload
    }),
    [actions.SET_QUESTION_STATE]: (state, action) => ({
      ...state,
      question: action.payload
    }),
    [actions.SET_QUESTION_ERROR]: (state, action) => ({
      ...state,
      questionError: action.payload
    }),
    [actions.SET_QUESTION_ALERT]: (state, action) => ({
      ...state,
      questionAlert: action.payload
    }),
    [actions.RESET_STATE]: () => ({
      ...initialState
    }),
    [actions.ENABLE_DONE_BUTTON]: (state, action) => ({
      ...state,
      enableDoneButton: action.payload
    }),
    [actions.SELECT_NONE]: (state, action) => ({
      ...state,
      noneOption: {
        active: action.payload,
        id: 'none',
        name: 'No tengo tarjeta de crédito'
      }
    }),
    [actions.SELECT_HAVE_CREDIT]: (state, action) => ({
      ...state,
      haveCreditCard: {
        active: action.payload,
        id: 'have_card',
        name: 'Tengo tarjeta de crédito pero no la utilizo'
      }
    }),
    [actions.SELECT_NONE_SAVINGS]: (state, action) => ({
      ...state,
      noneSavings: {
        active: action.payload,
        id: 'none_savings',
        name: 'No tengo ahorro ni inversiones',
        disabled: true
      }
    }),
    [actions.DISABLE_NONE]: (state, action) => ({
      ...state,
      noneOption: {
        active: false,
        id: 'none',
        name: 'No tengo tarjeta de crédito',
        disabled: action.payload
      }
    }),
    [actions.DISABLE_CREDIT_CARD]: (state, action) => ({
      ...state,
      haveCreditCard: {
        active: false,
        id: 'have_card',
        name: 'Tengo tarjeta de crédito pero no la utilizo',
        disabled: action.payload
      }
    }),
    [actions.USER_OPTIONS_SELECTED]: (state, action) => ({
      ...state,
      userOptionsSelected: action.payload
    }),
    [actions.CURRENT_OPTIONS]: (state, action) => ({
      ...state,
      options: action.payload
    }),
    [actions.SET_CURRENT_VALUE]: (state, action) => ({
      ...state,
      currentValue: action.payload
    }),
    [actions.SET_EDIT]: (state, action) => ({
      ...state,
      isEdit: action.payload
    })
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
