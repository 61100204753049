import i18next from 'i18next'

i18next.addResources('es', 'familyOnBoarding', {
  title: 'Mi entorno',
  subTitle:
    'Lo que me rodea forma parte de mis decisiones y del logro de mis propósitos.',
  buttonLabel: 'Continuar'
})

i18next.addResources('es', 'whereLiveFamily', {
  title: '¿Dónde vives?',
  subTitle: 'Elige tu tipo de vivienda',
  room: 'Habitación',
  home: 'Casa',
  apartment: 'Apartamento',
  countryHouse: 'Finca',
  iLiveIn: 'Vivo en: ',
  rent: 'Arriendo',
  own: 'Propio',
  family: 'Familiar'
})

i18next.addResources('es', 'withWhomYouLive', {
  title: '¿Con quién vives?',
  subTitle: 'Conforma tu grupo familiar de acuerdo con quienes vives.',
  yes: 'Si',
  no: 'no',
  continue: 'Continuar',
  count: 'Cuántos',
  single: 'Solo',
  couple: 'Pareja',
  children: 'Hijos',
  parents: 'Padres',
  brothers: 'Hermanos',
  pets: 'Mascotas',
  others: 'Otros',
  both: 'Ambos',
  oneOfTwo: 'Uno de los dos',
  none: 'Ninguno'
})

i18next.addResources('es', 'dependents', {
  dependentOnYou: '¿Tienes personas que dependan económicamente de ti?',
  dependentSpecial:
    '¿Alguno de ellos tiene una situación de discapacidad, condición médica especial o está en algún tipo de tratamiento de salud?',
  howMany:
    '¿Cuántas de las personas tienen una situación de discapacidad, condición médica especial o está en algún tipo de tratamiento de salud?'
})

i18next.addResources('es', 'doneFamily', {
  altImg: 'check',
  title: 'Has completado',
  world: 'Mi entorno',
  content: '¡Sigue avanzando!',
  labelButton: 'Continuar'
})
