import { createReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  isSideDrawerOpen: false,
  isMyResultsOpen: false,
  isPersonalInformationOpen: false,
  isAdminNadbar: false
}

const reducerDescription = {
  [actions.SET_SIDE_DRAWER_OPEN]: (state, action) => ({
    ...state,
    isSideDrawerOpen: action.payload
  }),
  [actions.SET_DROPDOWN_OPTIONS]: (state, action) => ({
    ...state,
    isMyResultsOpen: action.payload.isMyResultsOpen,
    isPersonalInformationOpen: action.payload.isPersonalInformationOpen
  })
}

export default createReducer(initialState, reducerDescription)
