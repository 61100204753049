import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  popUpContent: []
}

const reducerDescription = {
  primaryActions: [actions.GET_MESSAGE]
}

export default createReducer(initialState, completeReducer(reducerDescription))
