import {
  createTypes,
  completeTypes,
  withPostSuccess,
  withPostFailure
} from 'redux-recompose'

import { eventService } from '../../services/BifiService'
import { orderByID } from '../../utils/generalFunctions'
import { showErrorModalAction } from '../../redux/Modal/actions'

export const actions = createTypes(
  completeTypes(
    ['POST_EVENTS', 'GET_DATA', 'GET_USER_DATA'],
    [
      'SIDE_STATE',
      'CURRENT_OPTIONS',
      'USER_EVENTS_SELECTED',
      'SELECT_NONE',
      'SET_EDIT',
      'RESET_STATE',
      'ENABLE_DONE_BUTTON'
    ]
  ),
  '@@EVENTS'
)

export const actionsCreators = {
  postData: ({ dataEvents, update }) => ({
    type: actions.POST_EVENTS,
    target: 'events',
    service: update ? eventService.putEvents : eventService.postEvent,
    payload: dataEvents,
    injections: [
      withPostSuccess((dispatch) => {
        dispatch(actionsCreators.getEvents())
        dispatch(actionsCreators.enableDoneButton(true))
        dispatch(actionsCreators.getEventsByUser())
      }),
      withPostFailure((dispatch, response) => {
        showErrorModalAction(dispatch, response)
        dispatch(actionsCreators.enableDoneButton(false))
      })
    ]
  }),
  getEvents: () => ({
    type: actions.GET_DATA,
    target: 'events',
    service: eventService.getData,
    successSelector: (response) => orderByID(response.data)
  }),
  getEventsByUser: () => ({
    type: actions.GET_USER_DATA,
    target: 'user',
    service: eventService.getDataByUser
  }),
  setEventSide: (value) => ({
    type: actions.SIDE_STATE,
    payload: value
  }),
  setCurrentOptions: (value) => ({
    type: actions.CURRENT_OPTIONS,
    payload: value
  }),
  setSelectectedEvents: (value) => ({
    type: actions.USER_EVENTS_SELECTED,
    payload: value
  }),
  setSelectNone: (value) => ({
    type: actions.SELECT_NONE,
    payload: value
  }),
  setEdit: (value) => ({
    type: actions.SET_EDIT,
    payload: value
  }),
  resetState: () => ({
    type: actions.RESET_STATE
  }),
  enableDoneButton: (value) => ({
    type: actions.ENABLE_DONE_BUTTON,
    payload: value
  })
}

export default actionsCreators
