export const normalizeData = (data) => {
  const newData = {
    wayOfBeingView: data.way_of_being_view,
    financialView: data.financial_view,
    showExperience: data.show_experience,
    showVideo: data.show_video,
    showButton: false,
    enabledButtonPhone: data.IsEnabledButtonComunicationPhone,
    enabledButtonEmail: data.IsEnabledButtonComunicationEmail
  }

  if (data.way_of_being_view && data.financial_view && !data.show_experience) {
    newData.showButton = true
  }
  return newData
}
