/* eslint-disable camelcase */
import i18next from 'i18next'

i18next.addResources('es', 'programs', {
  title: 'Administración de programas',
  createConversation: 'Crear conversatorio',
  tableTitle: 'Programas',
  total: 'Fuentes totales: ',
  name: 'Nombre Programa',
  source: 'Fuente',
  customText: 'Texto personalizado',
  select: 'Seleccione un campo',
  button: 'Actualizar',
  acept: 'Confirmar',
  cancel: 'Cancelar',
  lead: 'Actualizar lead',
  question: '¿Está seguro que deseas modificar este lead?',
  delete: '¿Está seguro que deseas eliminar este registro?',
  deleteTitle: 'Eliminar Registro',
  createPrograms: 'Crear Programa',
  holder: 'Tomador buscado',
  error: 'El tomador buscado no existe',
  writeError: 'La razon social debe ser alfabetico',
  okMessage: 'El programa fue eliminado correctamente',
  alertTitle: '¡Atención!',
  deleting: 'Eliminar Registro',
  searching: 'Buscador',
  programVpc: 'VPC',
  update: 'Actualizar',
  updateMessage: 'La actualización fue exitosa',
  updateError:
    'Error en la actualización, intentelo de nuevo. Si el error perciste, contacte el administrador del sistema',
  serverErrorMessage:
    'El programa tiene usuarios registrados y no se puede eliminar'
})

i18next.addResources('es', 'programsActions', {
  deleteSelection: 'Borrar selección',
  editSelection: 'Selecciona el campo que quiere editar',
  name: 'Escriba el nuevo valor para el campo NOMBRE PROGRAMA',
  source: 'Seleccione el nuevo valor para el campo FUENTE',
  programVpc: 'Seleccione el nuevo valor para el campo VPC',
  customText: 'Escriba el nuevo valor para el campo TEXTO PERSONALIZADO',
  confirm: 'Confirma la edición :'
})
