import i18next from 'i18next'

i18next.addResources('es', 'FinancialPlannerFields', {
  Arriendo: 'Arriendo, administración o predial',
  'Manutención a terceros': 'Dependientes económicos',
  'Servicios publicos': 'Servicios públicos',
  'Creditos de vivienda': 'Créditos de vivienda',
  'Tarjetas de credito/creditos rotativos':
    'Tarjetas de crédito o créditos rotativos',
  'Otros prestamos con entidades financieras':
    'Otros préstamos con entidades financieras',
  'Prestamos con persona natural': 'Préstamos con persona natural',
  Ahorro: 'Ahorros',
  Educacion: 'Educación',
  Otros: 'Otros gastos',
  'Creditos de vehiculo': 'Créditos de vehículo'
})

i18next.addResources('es', 'FinancialPlannerPlaceholder', {
  Ingresos: 'Tus ingresos mensuales',
  Alimentación: 'Valor en alimentación mensual ',
  Arriendo: 'Arriendo, administración y/o predial mensual',
  'Manutención a terceros': 'Manutención mensual de dependientes económicos',
  'Servicios publicos': 'Valor mensual de servicios públicos',
  Transporte: 'Transporte mensual ',
  'Embargos, multas o sanciones':
    'Valor mensual en embargos, multas o sanciones',
  'Retenciones de ley': 'Retenciones mensuales de Ley ',
  'Seguridad social': 'Seguridad social mensual',
  'Creditos de vehiculo': 'Cuota mensual de crédito de vehículo',
  'Creditos de vivienda': 'Cuota mensual de crédito de vivienda',
  'Otros prestamos con entidades financieras':
    'Cuota mensual de otros préstamos con entidades financieras',
  'Prestamos con persona natural':
    'Cuota mensual de préstamos con personas naturales',
  'Tarjetas de credito/creditos rotativos':
    'Cuota mensual de tarjetas de crédito o créditos rotativos',
  Educacion: 'Cuota mensual para educación',
  Entretenimiento: 'Valor mensual para entretenimiento',
  Mascotas: 'Costo mensual de mascotas',
  Otros: 'Valor mensual para otros gastos',
  Seguros: 'Cuota mensual para seguros',
  Ahorro: 'Ahorro mensual'
})

i18next.addResources('es', 'FinancialDescription', {
  Ingresos:
    'Sueldo, nóminas de trabajos alternos, comisiones, arriendos, honorarios, pensión, entre otros. Este valor no debe incluir ninguna deducción',
  NoSavings:
    'Recuerda que en la pantalla anterior no agregaste un valor para ahorro ni inversión'
})
