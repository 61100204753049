import { t } from 'i18next'

import { EXPENSES_CONTENT, TABLE, ZERO } from '../constants/finance'
import {
  numberWithDots,
  toCamel,
  orderByNumber
} from '../utils/generalFunctions'
import { months } from '../utils/dictionary'

export const normalizeFinanceData = (data) => {
  const newObject = {}
  Object.entries(data).forEach(([key, value]) => {
    newObject[toCamel(key)] = value
  })

  const incomeSalary = {
    id: 6,
    name: 'Ingresos',
    total: newObject.entries.total,
    details: [
      {
        id: 'incomes',
        name: 'Ingresos',
        total: newObject.entries.total,
        manual: newObject.entries.manual,
        preLoad: newObject.entries.preLoad
      }
    ]
  }
  const [saving, basic, commitments, elective, legal] = newObject.outcomes
  newObject.outcomes = [
    incomeSalary,
    saving,
    basic,
    commitments,
    elective,
    legal
  ]

  const totalFixed = newObject.total || ZERO
  const totalFixedWithoutSavings = newObject.totalWithoutSavings || ZERO
  const entriesNew = {
    manual: newObject.entries.manual.value,
    preLoad: newObject.entries.preLoad,
    total: newObject.entries.total || ZERO,
    totalWithoutSavings: newObject.entries.totalWithoutSavings || ZERO
  }
  const expenseWithContent = newObject.outcomes.map((expense) => {
    const { title, content, id } = EXPENSES_CONTENT.find(
      (expenseContent) => expenseContent.id === expense.id
    )
    const detailsNew = expense?.details.map((details) => ({
      ...details,
      percentage: details.percentage || ZERO,
      options: details.options?.map((item) => item.id)
    }))
    return {
      ...expense,
      total: numberWithDots(expense.total),
      percentage: expense.percentage || ZERO,
      details: detailsNew,
      id,
      title: t(`finance:${title}`),
      content: t(`finance:${content}`)
    }
  })

  return {
    entries: entriesNew,
    expenses: expenseWithContent,
    total: totalFixed,
    totalWithoutSavings: totalFixedWithoutSavings
  }
}

const filterUnnecessaryData = (details) => {
  const DEBIT_CARD_INFO = 22
  const SAVES_AND_INVESTMENTS = 23
  return details.filter(
    (detail) =>
      detail.id !== DEBIT_CARD_INFO && detail.id !== SAVES_AND_INVESTMENTS
  )
}

export const normalizeTableData = (expenses, id = TABLE) => {
  const value = expenses.find((expense) => expense.id === id)
  if (value) {
    return {
      name: value.name,
      percentage: value.percentage || ZERO,
      details: filterUnnecessaryData(value.details)
    }
  }
  return {}
}

export const normalizeHistoryData = ({ dates }) => {
  const years = orderByNumber(Object.keys(dates), true)
  years.forEach((year) => (dates[year] = orderByNumber(dates[year], true)))
  const firstYear = years[years.length - 1]
  dates.years = years
  dates.firstYear = firstYear
  dates.firstMonth = dates[firstYear][dates[firstYear].length - 1]
  dates.actuallyYear = years[0]
  dates.actuallyMonth = dates[years[0]][0]
  return dates
}

const checkMora = (cuota, valorMora) =>
  cuota > 0 && cuota === valorMora ? '*' : ''
const condition = (element) => element === '*'

export const normalizeCifinData = (data) => {
  const cifinData = {}
  const date = data ? data[0].fechaConsulta.split(' ') : null
  cifinData.date = date ? `${months[date[1]]} ${date[0]} de ${date[2]} ` : null
  const alert = []
  data.map((report) => {
    const enMora = checkMora(report.cuota, report.valorMora)
    report.nombreEntidad = report.nombreEntidad || '-'
    report.tipoCredito = report.tipoCredito || '-'
    report.estado = report.estado || '-'
    report.periocidad = report.periocidad || '-'
    report.deudaInicial = report.deudaInicial
      ? `$${numberWithDots(report.deudaInicial)}`
      : '-'
    report.seDebe = report.seDebe ? `$${numberWithDots(report.seDebe)}` : '-'
    report.cuota = report.cuota
      ? `${enMora} $${numberWithDots(report.cuota)}`
      : '-'
    report.valorMora = report.valorMora
      ? `$${numberWithDots(report.valorMora)}`
      : '-'
    alert.push(enMora)
    delete report.fechaConsulta
    return report
  })
  cifinData.enMora = alert.some(condition)
  cifinData.data = data
  return cifinData
}
