import i18next from 'i18next'

i18next.addResources('es', 'assistance', {
  title: 'Ayuda',
  subTitle: 'Preguntas frecuentes',
  nickTitle: 'Hola',
  legend:
    'Si tienes dudas o preguntas puedes comunicarte con nosotros a través de los siguientes medios',
  subleged: 'Dejanos solucionar tus dudas'
})
