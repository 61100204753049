import React, { Component } from 'react'
import { bool, func, string } from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { t } from 'i18next'
import { connect } from 'react-redux'

import styles from './styles.module.scss'

class SaveModal extends Component {
  handleClose = () => {
    this.props.closeModal()
  }

  handleCancel = () => {
    this.props.closeModal('cancel')
  }

  handleAgree = () => {
    this.props.acceptModal()
  }

  render() {
    const { typeOfAlertModal, showAlertModal } = this.props
    return (
      <Modal
        id="alert_modal"
        className={styles.modal}
        show={showAlertModal}
        onHide={this.handleClose}
        centered
      >
        <Modal.Header
          id="alert_modal_header"
          className={styles.modalHeader}
          closeButton
        >
          <Modal.Title className={styles.modalTitle}>
            {t(`modal:${typeOfAlertModal}Title`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="alert_modal_body" className={styles.modalBody}>
          {t(`modal:${typeOfAlertModal}Body`)}
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            id="cancel_button"
            className={styles.cancelButton}
            onClick={
              typeOfAlertModal === 'update'
                ? this.handleCancel
                : this.handleClose
            }
          >
            {t('modal:cancel')}
          </Button>
          <Button
            id="accept_button"
            className={styles.acceptButton}
            onClick={this.handleAgree}
          >
            {t('modal:accept')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

SaveModal.propTypes = {
  acceptModal: func.isRequired,
  closeModal: func.isRequired,
  showAlertModal: bool.isRequired,
  typeOfAlertModal: string
}

const mapStateToProps = (state) => ({
  showAlertModal: state.modal.showAlertModal,
  typeOfAlertModal: state.modal.typeOfAlertModal
})

export default connect(mapStateToProps)(SaveModal)
