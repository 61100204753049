import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Dropdown from 'react-bootstrap/Dropdown'
import {
  faSignOutAlt,
  faQuestion,
  faUsersCog
} from '@fortawesome/free-solid-svg-icons'
import { t } from 'i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Toolbar from './components/Toolbar/Toolbar'
import SideDrawer from './components/SideDrawer/SideDrawer'
import Divider from '@material-ui/core/Divider'
import styles from './styles.module.scss'
import { NAVIGATION } from './constant'

// import ProfileIcon from '~assets/ico-UserProfile.svg';
import ProfileIcon from '../../../assets/ico-UserProfile.svg'

class NavBar extends Component {
  menuRender = () => (
    <Navbar.Collapse
      id="basic-navbar-nav"
      className={`${styles.mainMenu} justify-content-center`}
    >
      <Nav>
        {NAVIGATION.map((nav) => {
          if (nav.hasSubmenu) {
            return this.listRender(nav)
          }
          return this.itemRender(nav)
        })}
      </Nav>
    </Navbar.Collapse>
  )

  userMenuRender = () => {
    const { nick, onHandleClick, isAdmin } = this.props
    return (
      <Nav>
        <Dropdown alignRight>
          <Dropdown.Toggle id="user_dropdown" className={styles.header}>
            <div className={styles.userThumbnail}>
              <img
                className="thumbnail-image"
                src={ProfileIcon}
                alt="iconImg"
              />
            </div>
            {nick}
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.dropdownMenu}>
            {isAdmin && (
              <Dropdown.Item
                id="admin_dropdown_item"
                onClick={onHandleClick('DASHBOARD')}
              >
                <FontAwesomeIcon icon={faUsersCog} />
                {t('navBar:adminMode')}
              </Dropdown.Item>
            )}
            <Dropdown.Item
              id="assistance_dropdown_item"
              onClick={onHandleClick('ASSISTANCE')}
            >
              <FontAwesomeIcon icon={faQuestion} />
              {t('navBar:assistance')}
            </Dropdown.Item>
            <Dropdown.Item
              id="logout_dropdown_item"
              onClick={onHandleClick('LOGOUT')}
            >
              <FontAwesomeIcon icon={faSignOutAlt} />
              {t('navBar:signOut')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    )
  }

  itemRender = (nav) => {
    const { learningUrl, onHandleClick } = this.props
    const isLearning = nav.key === 'learning'
    const target = '_blank'
    return isLearning === true ? (
      <Nav.Link
        id={`${nav.key}Nav`}
        key={`${nav.key}Nav`}
        target={target}
        href={learningUrl}
        className={styles.menuItem}
      >
        {nav.title}
      </Nav.Link>
    ) : (
      <Nav.Link
        id={`${nav.key}Nav`}
        key={`${nav.key}Nav`}
        onClick={onHandleClick(nav.route)}
        className={styles.menuItem}
      >
        {nav.title}
      </Nav.Link>
    )
  }

  listRender = (nav) => {
    const { onHandleShowDropdown, onHandleClick } = this.props
    const listItems = nav.submenu.map((item) => (
      <NavDropdown.Item
        id={`${item.key}Nav`}
        key={item.key}
        onClick={onHandleClick(item.route)}
        className={styles.dropdownItem}
      >
        {item.title}
      </NavDropdown.Item>
    ))
    return (
      <NavDropdown
        key={nav.key}
        title={nav.title}
        id={`${nav.key}Dropdown`}
        className={`${styles.dropdownItemMenu}`}
        onClick={onHandleShowDropdown(nav.key)}
        onMouseEnter={onHandleShowDropdown(nav.key)}
        onMouseLeave={onHandleShowDropdown(nav.key)}
      >
        {listItems}
      </NavDropdown>
    )
  }

  render() {
    const { onHandleDrawerClick, nick, onHandleClick, show, hasPendingWorld } =
      this.props
    return (
      <Fragment>
        <Toolbar
          onHandleDrawerClick={onHandleDrawerClick}
          onHandleClick={onHandleClick}
        />
        <SideDrawer
          show={show}
          nick={nick}
          hasPendingWorld={hasPendingWorld}
          itemRender={this.itemRender}
          listRender={this.listRender}
          onHandleClick={onHandleClick}
        />
        <Navbar expand="md" className={styles.navbar}>
          <Navbar.Brand onClick={onHandleClick('HOME')}>
            <article></article>
            <span className={styles.logoSura} />
          </Navbar.Brand>
          <section>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className={styles.divider}
            />
          </section>
          <section className={styles.brandTitle}>
            <span className={styles.text}>Salud financiera</span>
          </section>
          {!hasPendingWorld && this.menuRender()}

          <Navbar.Collapse className={`justify-content-end ${styles.userMenu}`}>
            {this.userMenuRender()}
          </Navbar.Collapse>
        </Navbar>
      </Fragment>
    )
  }
}

NavBar.propTypes = {
  hasPendingWorld: PropTypes.bool,
  isAdmin: PropTypes.bool,
  learningUrl: PropTypes.string,
  nick: PropTypes.string,
  show: PropTypes.bool,
  onHandleClick: PropTypes.func,
  onHandleDrawerClick: PropTypes.func,
  onHandleShowDropdown: PropTypes.func
}
export default NavBar
