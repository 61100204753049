import React from 'react'

import { whatsappId, whatsappUrl } from '../../constants'

import styles from './styles.module.scss'

import FloatingButton from '../../../Buttons/FloatingButton'

function FloatingSection() {
  const currentLocation = window.location.pathname

  return (
    <div className={styles.actions}>
      <FloatingButton
        currentLocation={currentLocation}
        id={whatsappId}
        url={whatsappUrl}
      />
    </div>
  )
}

export default FloatingSection
