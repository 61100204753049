/* eslint-disable max-lines */
/* eslint-disable camelcase */
import {
  oneOfType,
  shape,
  string,
  number,
  arrayOf,
  bool,
  objectOf,
  func,
  instanceOf
} from 'prop-types'

export const loginPropTypes = shape({
  email: string,
  password: string,
  confirmpassword: string
})

export const completeRegisterPropType = shape({
  document: string,
  nick: string,
  age: number,
  city: string,
  occupation: string,
  typeDocument: string
})

export const contentPropType = shape({
  key: string
})

export const dropPropType = shape({
  id: number,
  content: contentPropType,
  priority: number
})

export const boxPropType = shape({
  id: number,
  name: string,
  active: bool
})

export const headerDateType = shape({
  month: string,
  year: number
})

export const lastCardPropType = shape({
  message: string,
  title: string
})

export const contentCardPropType = shape({
  id: number,
  question: string,
  title: string
})

export const expenseCard = shape({
  total: string,
  percentage: oneOfType([string, number]),
  title: string,
  content: string
})

export const expensesTableData = shape({
  id: number,
  name: string,
  percentage: oneOfType([number, string])
})

export const entriesPropType = shape({
  manual: number,
  preLoad: number,
  total: number,
  totalWithoutSavings: number
})

export const financePropType = shape({
  total: oneOfType([number, string]),
  totalWithoutSavings: oneOfType([number, string]),
  entries: entriesPropType,
  expenses: arrayOf(expenseCard)
})

export const financeValuesPropType = shape({
  manual: number,
  preLoad: number,
  total: number
})

export const word = shape({
  complete: bool,
  active: bool
})

export const profileWords = shape({
  FAMILY: word,
  THE_WAY_I_AM: word,
  EVENTS: word,
  FINANCIAL: word
})

export const optionsFammily = shape({
  COUPLE: number,
  CHILD: number,
  PARENT: number,
  SIBLING: number,
  OTHER: number,
  PET: number
})

export const familyPropType = shape({
  type: string,
  ownership: string,
  wholive: string,
  depends: number,
  disability: number,
  option: objectOf(optionsFammily)
})

export const familyOptionPropType = shape({
  count: number,
  option: string
})

export const questionPropType = shape({
  id: number,
  question: string
})

export const faqsPropType = shape({
  id: number,
  question: string,
  answer: string
})

export const resultPropType = shape({
  userId: string,
  dimension: number,
  result: string,
  interpretation: string,
  name: string,
  detail: number
})

export const optionsEvent = shape({
  id: string || number,
  text: string,
  active: bool
})

export const optionsPropType = shape({
  options: objectOf(optionsEvent)
})

export const questionsScreenData = shape({
  icon: string
})

export const assessmentClassNamePropType = shape({
  square: string,
  box: string
})

export const assessmentResultsPropType = shape({
  name: string,
  result: string,
  interpretation: string
})

export const registerPropType = shape({
  email: string,
  password: string
})

export const engineCardsPropType = shape({
  title: string,
  content: string
})

export const engineOptionPropType = shape({
  text: string,
  header: string,
  headerPercent: number,
  creditCardRotativePercent: number,
  debtWithSupportPercent: number,
  volunteerServicesPercent: number,
  creditCardRotativeText: string,
  debtWithSupportText: string,
  volunteerServicesText: string,
  maxCreditCardRotativeText: string,
  coDeptorDebtPercent: number,
  mainDebtPercent: number,
  mainDebtPercentText: string,
  mainDebtText: string,
  coDeptorDebtText: string,
  percentage: string
})

export const engineDataPropType = shape({
  name: string,
  data: engineOptionPropType
})

export const historicalDataSetPropType = shape({
  label: string,
  labelString: string,
  fill: bool,
  lineTension: number,
  borderColor: string,
  borderCapStyle: string,
  borderDash: arrayOf(number),
  borderDashOffset: number,
  borderJoinStyle: string,
  pointBorderColor: string,
  pointBackgroundColor: string,
  pointBorderWidth: number,
  pointHoverRadius: number,
  pointHoverBackgroundColor: string,
  pointHoverBorderColor: string,
  pointHoverBorderWidth: number,
  pointRadius: number,
  pointHitRadius: number,
  data: arrayOf(number)
})

export const historicalReportPropType = {
  labels: arrayOf(string),
  datasets: arrayOf(historicalDataSetPropType)
}

export const datasetPropType = shape({
  values: arrayOf(string),
  backgroundColor: arrayOf(string)
})

export const pieChartPropType = shape({
  labels: arrayOf(string),
  datasets: arrayOf(datasetPropType)
})

export const metaPropType = shape({
  active: bool,
  asyncValidating: bool,
  autofilled: bool,
  dirty: bool,
  dispatch: func,
  error: string,
  form: string,
  invalid: bool,
  pristine: bool,
  submitFailed: bool,
  submitting: bool,
  touched: bool,
  valid: bool,
  visited: bool
})

export const inputPropType = shape({
  onBlur: func,
  name: string,
  onChange: func,
  onDragStart: func,
  onDrop: func,
  onFocus: func,
  value: string
})

export const priorityCombinationPropType = shape({
  psychologistSupport: number,
  psychologistMonitoring: number,
  financialSupport: number,
  financialMonitoring: number,
  levelRisk: string
})

export const modalErrorProptype = shape({
  showErrorModal: bool,
  errorType: string,
  reportError: bool,
  errorMessage: string
})

export const ciaExperienceProptype = shape({
  ciaName: string,
  source: string,
  title: string,
  text: string,
  showContactInfo: bool,
  showMesaggeBox: bool
})

export const cifinReportProptype = shape({
  nombreEntidad: string,
  tipoCredito: string,
  estado: string,
  deudaInicial: string,
  seDebe: string,
  cuota: string,
  periocidad: string,
  numeroCuotas: number,
  valorMora: string,
  cuotaMora: string
})

export const historyProptype = shape({
  length: number,
  action: string,
  location: shape({
    pathname: string,
    search: string
  }),
  push: func,
  replace: func
})

export const userProptype = shape({
  nick: string,
  id: string,
  phone: string,
  email: string,
  cia: string,
  feupdatedCommunicationChannel: instanceOf(Date)
})

export const checboxProptype = shape({
  id: string,
  label: string,
  name: string
})

export const adminDashboardProptype = shape({
  totalClientsActive: string,
  totalClientsCompleted: {
    count: string,
    percentage: number
  },
  totalUsersFinishedStateGeneral: string,
  totalUsersGroupByStateGeneral: arrayOf(
    shape({
      clasification: string,
      count: string,
      percentage: number
    })
  ),
  totalUsersGroupByRiskLevel: arrayOf(
    shape({
      count: string,
      level_risk: string,
      percentage: number
    })
  ),
  totalUsersTimeSeries: shape({
    active: arrayOf(
      shape({
        count: string,
        fecha: string
      })
    ),
    completed: arrayOf(
      shape({
        count: string,
        fecha: string
      })
    )
  })
})
export const adminTableProptype = shape({
  countItems: number,
  items: arrayOf(
    shape({
      economic_group: string,
      social_reason: string,
      nit: string,
      program_name: string,
      porcent_holder: number,
      porcent_user: number,
      fullname: string,
      document: string,
      city: string,
      creation: string,
      active: bool,
      email: string,
      phone: string,
      general_state: string,
      state_process: string,
      level_risk: string,
      financial_schedule: number,
      psychological_schedule: number,
      solutions: string,
      vpc_desarrollo: string,
      vpc_fidelizacion: string,
      rewards_plan: bool,
      profile_type: string,
      observations: string,
      device_access: string
    })
  ),
  totalPages: number,
  currentPage: number
})

export const filtersDashboardProptype = shape({
  data: arrayOf(string),
  cias: arrayOf(string),
  generalStates: arrayOf(string),
  documents: arrayOf(string)
})

export const filtersUsersTableProptype = shape({
  data: arrayOf(string),
  cias: arrayOf(string),
  generalStates: arrayOf(string)
})

export const filtersDashboardValidation = shape({
  beginDateError: string,
  endDateError: string
})

export const userDataProptype = shape({
  source: arrayOf(string),
  documents: arrayOf(string),
  name: string,
  email: string
})

export const optionSelecteProptype = shape({ label: string, value: string })
