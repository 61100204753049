import { WORLD_STATE } from '../constants/profile'

export const normalizeProfileData = (data) => {
  const profile = {}
  data.forEach((item) => {
    if (item.world !== 'LEARNING_ROUTES') {
      const updatedDate = new Date(item.updatedAt)
      profile[item.world] = {
        active: item.state === WORLD_STATE.ACTIVE,
        complete: item.state === WORLD_STATE.COMPLETE,
        updated: updatedDate.getMonth()
      }
    }
  })
  return profile
}
