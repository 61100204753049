import { createTypes, completeTypes } from 'redux-recompose'

import { getMessage } from '../../services/BifiService'

export const actions = createTypes(completeTypes(['GET_MESSAGE']), '@@MESSAGES')

export const actionsCreators = {
  getMessage: () => ({
    type: actions.GET_MESSAGE,
    target: 'popUpContent',
    service: getMessage,
    successSelector: (response) => response.data && response.data.pop_up_content
  })
}

export default actionsCreators
