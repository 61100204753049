export const options = {
  appLoading: 'appLoading',
  current: 'currentUser',
  header: 'Authorization',
  unknownPassword: 'unknownPassword',
  alterPassword: 'alterPassword',
  register: 'register',
  complete: 'completeRegister',
  resend: 'resend',
  enabledUser: 'enableUser'
}

export const SURA_ADDRESS = `${process.env.REACT_APP_API_BASE_URL}/login`

export const LOGOUT = `${process.env.REACT_APP_LOGOUT}?client_id=${process.env.REACT_APP_CLIENT_ID}&returnTo=${process.env.REACT_APP_URL}`

export const RESEND = 409

export const NOT_ALLWED_ERRROR = 'not_allowed_error'
export const REGISTERED_USER = 'registered_user'
