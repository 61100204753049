import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  side: 0,
  economicGroup: '',
  holdersNumber: 0,
  holdersData: {
    socialReason: '',
    nit: '',
    programs: '',
    source: '',
    customMessage: '',
    programVpc: '',
    holderExists: false
  },
  eGroupsList: [],
  economicGroupsList: [],
  programList: [],
  holders: [],
  sources: [],
  vpcList: [],
  currentTab: 0,
  isDisabled: true,
  showModal: false,
  postProgramsLoading: false,
  statusCode: null,
  responseError: null,
  activeStep: 0,
  economicGroupExists: false,
  holderExists: false,
  isDisableNit: false
}

const reducerDescription = {
  primaryActions: [
    actions.POST_PROGRAMS,
    actions.GET_SOURCES,
    actions.GET_ECONOMIC_GROUPS,
    actions.GET_PROGRAMS,
    actions.GET_VPC,
    actions.GET_EGROUPS_VALIDATION
  ],
  override: {
    [actions.SET_ECONOMIC_GROUP]: (state, action) => ({
      ...state,
      economicGroup: action.payload
    }),
    [actions.SET_SOCIAL_REASON]: (state, action) => ({
      ...state,
      holdersData: {
        ...state.holdersData,
        socialReason: action.payload
      }
    }),
    [actions.SET_NIT]: (state, action) => ({
      ...state,
      holdersData: {
        ...state.holdersData,
        nit: action.payload
      }
    }),
    [actions.SET_PROGRAMS]: (state, action) => ({
      ...state,
      holdersData: {
        ...state.holdersData,
        programs: action.payload
      }
    }),
    [actions.SET_SOURCE]: (state, action) => ({
      ...state,
      holdersData: {
        ...state.holdersData,
        source: action.payload
      }
    }),
    [actions.SET_VPC]: (state, action) => ({
      ...state,
      holdersData: {
        ...state.holdersData,
        programVpc: action.payload
      }
    }),
    [actions.SET_HOLDER_STATUS]: (state, action) => ({
      ...state,
      holdersData: {
        ...state.holdersData,
        holderExists: action.payload
      }
    }),
    [actions.SET_MESSAGE]: (state, action) => ({
      ...state,
      holdersData: {
        ...state.holdersData,
        customMessage: action.payload
      }
    }),
    [actions.SET_HOLDERS_DATA]: (state) => ({
      ...state,
      holdersData: {
        customMessage: '',
        source: '',
        programs: '',
        nit: '',
        socialReason: '',
        programVpc: '',
        holderExists: false
      }
    }),
    [actions.SET_HOLDERS_NUMBER]: (state, action) => ({
      ...state,
      holdersNumber: action.payload
    }),
    [actions.SET_HOLDERS_TOTAL]: (state, action) => ({
      ...state,
      holders: [...state.holders, action.payload]
    }),
    [actions.CLEAR_HOLDERS_DATA]: (state) => ({
      ...state,
      holders: []
    }),
    [actions.SIDE_STATE]: (state, action) => ({
      ...state,
      side: action.payload
    }),
    [actions.SET_CURRENT_TAB]: (state, action) => ({
      ...state,
      currentTab: action.payload
    }),
    [actions.ENABLE_BUTTON]: (state, action) => ({
      ...state,
      isDisabled: action.payload
    }),
    [actions.SET_SHOW_MODAL]: (state, action) => ({
      ...state,
      showModal: action.payload
    }),
    [actions.SET_POST_PROGRAMS_LOADING]: (state, action) => ({
      ...state,
      postProgramsLoading: action.payload
    }),
    [actions.SET_STATUS_CODE]: (state, action) => ({
      ...state,
      statusCode: action.payload
    }),
    [actions.SET_RESPONSE_ERROR]: (state, action) => ({
      ...state,
      responseError: action.payload
    }),
    [actions.SET_STEPS]: (state, action) => ({
      ...state,
      activeStep: action.payload
    }),
    [actions.SET_EGROUP_STATUS]: (state, action) => ({
      ...state,
      economicGroupExists: action.payload
    }),
    [actions.SET_DISABLE_NIT]: (state, action) => ({
      ...state,
      isDisableNit: action.payload
    })
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
