import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import { func, string } from 'prop-types'

import styles from './styles.module.scss'
import { ERRORS } from './constants'

import { actions } from '../../../../redux/Modal/actions'
import { modalErrorProptype } from '../../../../constants/propTypes'

class ErrorModal extends Component {
  componentDidMount() {
    const { modalData, reportError } = this.props
    if (modalData.reportError && modalData.errorType === 'USER_WITHOUT_FLOW') {
      reportError({
        identifierSubject: 'beginingEvaluation',
        bodyMessage: modalData.errorMessage
      })
    }
    if (modalData.reportError && modalData.errorType === 'NOT_FOUND') {
      reportError({
        identifierSubject: 'carePlan',
        bodyMessage: modalData.errorMessage
      })
    }
  }

  handleClose = () => {
    const { showErrorModal } = this.props
    showErrorModal({
      showErrorModal: false
    })
  }

  handleClickFiltersModal = () => {
    const { showErrorModal } = this.props
    showErrorModal({
      showErrorModal: false
    })
  }

  handleClick = () => {
    window.location.reload()
  }

  render() {
    const { modalData, currentEmailUser } = this.props
    const { showErrorModal, errorType, reportError, errorMessage } = modalData
    return (
      <Modal
        className={styles.modal}
        show={showErrorModal}
        onHide={this.handleClickFiltersModal}
        centered
      >
        <Modal.Header className={styles.modalHeader}>
          <Modal.Title id="modalTitle" className={styles.modalTitle}>
            {ERRORS[errorType].title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="modalBody" className={styles.modalBody}>
          {reportError && ERRORS[errorType].bodyFirst}
          {reportError && currentEmailUser}
          {reportError && ERRORS[errorType].bodySecond}
          {!reportError && ERRORS[errorType].body}
          {!reportError && errorMessage ? <p>{errorMessage}</p> : ''}
        </Modal.Body>
        {ERRORS[errorType].button && (
          <Modal.Footer id="modalFooter" className={styles.modalFooter}>
            <Button
              id={ERRORS[errorType].styleButton}
              className={styles[ERRORS[errorType].styleButton]}
              onClick={this.handleClick}
            >
              {ERRORS[errorType].button}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    )
  }
}

ErrorModal.propTypes = {
  currentEmailUser: string.isRequired,
  modalData: modalErrorProptype.isRequired,
  reportError: func.isRequired,
  showErrorModal: func.isRequired
}

const mapStateToProps = (state) => ({
  modalData: state.modal,
  currentEmailUser: state.auth.currentUser.email
})

const mapDispatchToProps = (dispatch) => ({
  reportError: (values) => dispatch(actions.reportError(values)),
  showErrorModal: (values) => dispatch(actions.showErrorModal(values))
})
export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal)
