import { createReducer, completeReducer } from 'redux-recompose'
import onReplace from 'redux-recompose/lib/effects/onReplace'

import { actions } from './actions'

const initialState = {
  tableData: [],
  countHolders: '',
  tableDataLoading: true,
  dataSelected: {
    economicGroup: '',
    holder: '',
    lead: false,
    nit: '',
    programName: '',
    source: '',
    textCustome: ''
  },
  showModal: false,
  showModalError: false,
  showCard: false,
  showUpdateSelect: false,
  updateOptionName: '',
  optionUpdateType: '',
  sourceList: [],
  vpcList: [],
  optionValue: '',
  showConfirmCard: false,
  filters: {
    nits: []
  },
  valueToSearch: '',
  showHolderCard: false,
  statusCode: null,
  isDelete: false,
  programsWorking: false,
  programsUpdating: false,
  reportData: null,
  showModalUpdate: false
}

const changeFilters = (filter, value) => {
  const index = filter.findIndex((element) => element === value)

  if (index < 0) {
    return [...filter, value]
  }
  filter.splice(index, 1)
  return filter
}

const reducerDescription = {
  primaryActions: [
    actions.GET_TABLE_DATA,
    actions.UPDATE_TABLE_DATA,
    actions.DELETE_TABLE_DATA,
    actions.GET_SOURCE_DATA,
    actions.GET_VPC,
    actions.GET_COUNT_HOLDERS
  ],
  override: {
    [actions.SET_DATA_SELECTED]: (state, action) => ({
      ...state,
      dataSelected: action.payload
    }),
    [actions.CLEAR_DATA_SELECTED]: (state) => ({
      ...state,
      dataSelected: initialState.dataSelected
    }),
    [actions.SET_SHOW_MODAL]: (state, action) => ({
      ...state,
      showModal: action.payload
    }),
    [actions.SET_SHOW_MODAL_ERROR]: (state, action) => ({
      ...state,
      showModalError: action.payload
    }),
    [actions.SET_SHOW_MODAL_UPDATE]: (state, action) => ({
      ...state,
      showModalUpdate: action.payload
    }),
    [actions.SET_SHOW_CARD]: (state, action) => ({
      ...state,
      showCard: action.payload
    }),
    [actions.UPDATE_SELECTION]: (state, action) => ({
      ...state,
      showCard: action.payload,
      showUpdateSelect: action.payload
    }),
    [actions.SET_OPTION_UPDATE_NAME]: (state, action) => ({
      ...state,
      updateOptionName: action.payload
    }),
    [actions.ADD_UPDATE_TYPE]: (state, action) => ({
      ...state,
      optionUpdateType: action.payload
    }),
    [actions.UPDATE_OPTION_VALUE]: (state, action) => ({
      ...state,
      optionValue: action.payload
    }),
    [actions.SET_SHOW_CONFIRM]: (state, action) => ({
      ...state,
      showConfirmCard: action.payload
    }),
    [actions.CHANGE_FILTER]: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        [action.payload.filter]: changeFilters(
          state.filters[action.payload.filter],
          action.payload.value
        )
      }
    }),
    [actions.SET_VALUE_TO_SEARCH]: (state, action) => ({
      ...state,
      valueToSearch: action.payload
    }),
    [actions.SET_SHOW_HOLDER_CARD]: (state, action) => ({
      ...state,
      showHolderCard: action.payload
    }),

    [actions.SET_STATUS_CODE]: (state, action) => ({
      ...state,
      statusCode: action.payload
    }),
    [actions.SET_IS_DELETE]: (state, action) => ({
      ...state,
      isDelete: action.payload
    }),
    [actions.SET_PROGRAMS_WORKING]: (state, action) => ({
      ...state,
      programsWorking: action.payload
    }),
    [actions.SET_PROGRAMS_UPDATING]: (state, action) => ({
      ...state,
      programsUpdating: action.payload
    }),
    [actions.SET_REPORT_DATA]: (state, action) => ({
      ...state,
      reportData: action.payload
    }),
    [actions.REPLACE]: onReplace()
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
