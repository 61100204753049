import { createReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  showPersonalInfoOptions: false,
  showMyResultsOptions: false
}

const reducerDescription = {
  [actions.SET_SHOW_PERSONAL_INFO]: (state, action) => ({
    ...state,
    showPersonalInfoOptions: action.payload,
    showMyResultsOptions: false
  }),
  [actions.SET_SHOW_MY_RESULTS]: (state, action) => ({
    ...state,
    showMyResultsOptions: action.payload,
    showPersonalInfoOptions: false
  })
}

export default createReducer(initialState, reducerDescription)
