import i18next from 'i18next'

i18next.addResources('es', 'homeCards', {
  nickTitle: 'Hola',
  myWayOfBeingTitle: 'Mi forma de ser',
  myWayOfBeingSummary:
    'Descubre los resultados de los aspectos de tu personalidad y conoce tus fortalezas y oportunidades de mejora en la búsqueda de una buena salud financiera.',
  myFinanceTitle: 'Mis finanzas',
  myFinanceSummary:
    'Conoce el resumen de tus gastos mensuales, acompañado de un análisis y recomendaciones sobre tu situación financiera actual.',
  myActionPlanTitle: 'Mi plan de acción',
  myActionPlanSummary:
    'Si quieres conocer más de nuestros servicios personalizados, te invitamos a contactarnos',
  learningPathsTitle: 'Rutas de aprendizaje',
  learningPathsSummary:
    'Explora y desarrolla el contenido para avanzar en la transformación de tus comportamientos financieros y acercarte al logro de tus propósitos.',
  whatsApp: 'WhatsApp',
  assistance: 'Ayuda',
  alert: 'Recuerda actualizar tu información financiera del último mes.'
})
