import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import { NavLink } from 'react-router-dom'
import { t } from 'i18next'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import UserMenuRender from './components/Dropdown'

// import PropTypes from 'prop-types';
// import logoSura from '~assets/logo-sura.svg';
import logoSura from '../../../assets/logo-sura.svg'
// import Routes from '~constants/routes';
import Routes from '../../../constants/routes'
import RedirectSwitch from './components/RedirectSwitch/RedirectSwitch'
import { connect } from 'react-redux'

function AdminNavbar({ nick, rol, redirectSwitch }) {
  return (
    <Navbar className={styles.navbar}>
      <Navbar.Brand>
        <NavLink to={Routes.DASHBOARD}>
          <img
            alt={t('adminNavbar:logo')}
            src={logoSura}
            className={styles.logoSura}
          />
        </NavLink>
      </Navbar.Brand>
      {rol === 'ADMINSWITCHES' && <RedirectSwitch />}
      <UserMenuRender nick={nick} redirectSwitch={redirectSwitch} />
    </Navbar>
  )
}

AdminNavbar.propTypes = {
  nick: PropTypes.string.isRequired,
  rol: PropTypes.string.isRequired,
  redirectSwitch: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  rol: state.auth.currentUser.rol,
  redirectSwitch: state.switchReducer.switches?.data?.value
})
export default connect(mapStateToProps)(AdminNavbar)
