export const normalizeData = (data) => {
  const { psychologicalSchedule, financialSchedule } = data.priorityCombination
  const psychologistSupport = psychologicalSchedule > 0 ? 1 : 0
  const psychologistMonitoring =
    psychologicalSchedule > 1 ? psychologicalSchedule - 1 : 0
  const financialSupport = financialSchedule > 0 ? 1 : 0
  const financialMonitoring = financialSchedule > 1 ? financialSchedule - 1 : 0
  const newData = {
    psychologistSupport,
    psychologistMonitoring,
    financialSupport,
    financialMonitoring,
    levelRisk: data.priorityCombination.levelRisk
  }
  return newData
}
