import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  results: [],
  finance: {
    entries: {
      manual: 0,
      preLoad: 0,
      total: 0,
      totalWithoutSavings: 0
    },
    expenses: [],
    total: '',
    totalWithoutSavings: ''
  },
  history: {}
}

const reducerDescription = {
  primaryActions: [
    actions.GET_POLL_RESULTS,
    actions.GET_FINANCIAL_DATA,
    actions.GET_HISTORY
  ]
}

export default createReducer(initialState, completeReducer(reducerDescription))
