import { t } from 'i18next'

// import Routes from '~constants/routes';
import Routes from '../../../constants/routes'

export const TIME = 10

export const NAVIGATION = [
  {
    key: 'home',
    title: t('navBar:home'),
    path: `${Routes.APP}${Routes.HOME_CARDS}`,
    submenu: [],
    route: 'HOME_CARDS'
  },
  {
    key: 'myResults',
    title: t('navBar:myResults'),
    path: '#',
    hasSubmenu: true,
    submenu: [
      {
        key: 'myPlan',
        title: t('navBar:myPlan'),
        path: `${Routes.APP}${Routes.MY_PLAN}`,
        route: 'MY_PLAN'
      },
      {
        key: 'myWayOfBeing',
        title: t('navBar:myWayOfBeing'),
        path: `${Routes.APP}${Routes.MY_WAY_OF_BEING}`,
        route: 'MY_WAY_OF_BEING'
      },
      {
        key: 'myFinance',
        title: t('navBar:myFinance'),
        path: `${Routes.APP}${Routes.FINANCE}`,
        route: 'FINANCE'
      }
    ]
  },
  {
    key: 'learning',
    title: t('navBar:learning'),
    path: Routes.LEARNING,
    hasSubmenu: false,
    submenu: []
  },
  {
    key: 'personalInformation',
    title: t('navBar:personalInformation'),
    path: '#',
    hasSubmenu: true,
    submenu: [
      {
        key: 'purpose',
        title: t('navBar:purpose'),
        path: `${Routes.APP}${Routes.PURPOSE}/edit`,
        route: 'EDIT_PURPOSE'
      },
      {
        key: 'family',
        title: t('navBar:family'),
        path: `${Routes.APP}${Routes.PROFILE}/family/edit`,
        route: 'EDIT_FAMILY'
      },
      {
        key: 'events',
        title: t('navBar:events'),
        path: `${Routes.APP}${Routes.PROFILE}/events/edit`,
        route: 'EDIT_EVENTS'
      },
      {
        key: 'financial',
        title: t('navBar:financial'),
        path: `${Routes.APP}${Routes.PROFILE}/financial/edit`,
        route: 'EDIT_FINANCIAL'
      }
    ]
  }
]

export const NAVIGATION_MOBILE = [
  NAVIGATION[0],
  {
    key: 'myResults',
    title: t('navBar:myResults'),
    path: '#',
    hasSubmenu: true,
    submenu: [
      {
        key: 'myWayOfBeing',
        title: t('navBar:myWayOfBeing'),
        path: `${Routes.APP}${Routes.MY_WAY_OF_BEING}`,
        route: 'MY_WAY_OF_BEING'
      },
      {
        key: 'myFinance',
        title: t('navBar:myFinance'),
        path: `${Routes.APP}${Routes.FINANCE}`,
        route: 'FINANCE'
      }
    ]
  },
  {
    key: 'myPlan',
    title: t('navBar:myPlan'),
    path: `${Routes.APP}${Routes.MY_PLAN}`,
    route: 'MY_PLAN'
  },
  NAVIGATION[2],
  NAVIGATION[3]
]

export default NAVIGATION
