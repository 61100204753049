import React from 'react'
import { t } from 'i18next'
import Dropdown from 'react-bootstrap/Dropdown'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

import styles from './styles.module.scss'

// import ProfileIcon from '~assets/ico-UserProfile.svg';
import ProfileIcon from '../../../../../assets/ico-UserProfile.svg'
// import Routes from '~constants/routes';
import Routes from '../../../../../constants/routes'

function UserMenuRender({ nick, redirectSwitch }) {
  return (
    <Dropdown className={styles.dropdown}>
      <Dropdown.Toggle id="user_dropdown" className={styles.header}>
        <div className={styles.userThumbnail}>
          <img className="thumbnail-image" src={ProfileIcon} alt="iconImg" />
        </div>
        {nick}
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.dropdownMenu}>
        {!redirectSwitch && (
          <Dropdown.Item id="assistance_dropdown_item">
            <Link to={Routes.HOME_CARDS}>
              <FontAwesomeIcon icon={faUser} />
              {t('adminNavbar:userMode')}
            </Link>
          </Dropdown.Item>
        )}
        <Dropdown.Item id="logout_dropdown_item">
          <Link to={Routes.LOGOUT}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            {t('adminNavbar:signOut')}
          </Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

UserMenuRender.propTypes = {
  nick: PropTypes.string.isRequired,
  redirectSwitch: PropTypes.bool.isRequired
}
export default UserMenuRender
