import {
  createTypes,
  completeTypes,
  withPostSuccess,
  withPostFailure
} from 'redux-recompose'

import {
  normalizeFinanceData,
  normalizeTableData,
  normalizeHistoryData,
  normalizeCifinData
} from '../../schemas/financeSchema'
import { financeService, cifinService } from '../../services/BifiService'
import { showErrorModalAction } from '../../redux/Modal/actions'

export const actions = createTypes(
  completeTypes(
    [
      'GET_DATA',
      'GET_HISTORICAL_REPORT',
      'POST_FINANCIAL_PLANNING',
      'ADD_ENTRIES',
      'HISTORY',
      'GET_REPORT_CIFIN'
    ],
    [
      'SCREEN_STATE',
      'SET_TABLE',
      'SET_DATES',
      'SET_ITEM_SELECTED',
      'SET_QUESTION_STATE',
      'SET_QUESTION_ERROR',
      'SET_QUESTION_ALERT',
      'RESET_STATE',
      'ENABLE_DONE_BUTTON',
      'SET_CURRENT_VALUE',
      'CURRENT_OPTIONS',
      'USER_OPTIONS_SELECTED',
      'SELECT_NONE',
      'SELECT_NONE_SAVINGS',
      'SELECT_HAVE_CREDIT',
      'DISABLE_NONE',
      'DISABLE_CREDIT_CARD',
      'SET_EDIT',
      'DISABLED_NONE'
    ]
  ),
  '@@FINANCE'
)

export const actionsCreators = {
  getData: (params, redirect) => ({
    type: actions.GET_DATA,
    target: 'finance',
    service: financeService.getData,
    payload: params,
    successSelector: (response) => normalizeFinanceData(response.data),
    failureSelector: (response) => ({ error: response.error }),
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionsCreators.setTable(response.data.outcomes))
        if (redirect) {
          dispatch(actionsCreators.screenState(3))
        }
      }),
      withPostFailure((dispatch) => {
        if (redirect) {
          dispatch(actionsCreators.screenState(3))
        }
      })
    ]
  }),
  addEntries: (entries) => ({
    type: actions.ADD_ENTRIES,
    target: 'entries',
    service: financeService.addEntry,
    payload: { entries },
    injections: [
      withPostSuccess((dispatch) => dispatch(actionsCreators.getData()))
    ]
  }),

  setTable: (data, id) => ({
    type: actions.SET_TABLE,
    payload: normalizeTableData(data, id)
  }),
  screenState: (value) => ({
    type: actions.SCREEN_STATE,
    payload: value
  }),
  postFinancialPlanning: (data) => ({
    type: actions.POST_FINANCIAL_PLANNING,
    target: 'financialPlanner',
    payload: { data },
    service: financeService.postFinancialPlanning,
    injections: [
      withPostSuccess((dispatch) => {
        dispatch(actionsCreators.enableDoneButton(true))
      }),
      withPostFailure((dispatch, response) => {
        showErrorModalAction(dispatch, response)
        dispatch(actionsCreators.enableDoneButton(false))
      })
    ]
  }),
  getHistory: () => ({
    type: actions.HISTORY,
    target: 'history',
    service: financeService.getHistory,
    successSelector: (response) => normalizeHistoryData(response.data)
  }),

  getReportCifin: () => ({
    type: actions.GET_REPORT_CIFIN,
    target: 'cifin',
    service: cifinService.getReportCifin,
    successSelector: (response) => normalizeCifinData(response.data)
  }),

  setDates: (values) => ({
    type: actions.SET_DATES,
    payload: values
  }),
  questionState: (value) => ({
    type: actions.SET_QUESTION_STATE,
    payload: value
  }),
  setQuestionError: (value) => ({
    type: actions.SET_QUESTION_ERROR,
    payload: value
  }),
  setQuestionAlert: (value) => ({
    type: actions.SET_QUESTION_ALERT,
    payload: value
  }),
  resetState: () => ({
    type: actions.RESET_STATE
  }),
  enableDoneButton: (value) => ({
    type: actions.ENABLE_DONE_BUTTON,
    payload: value
  }),
  setCurrentOptions: (value) => ({
    type: actions.CURRENT_OPTIONS,
    payload: value
  }),
  setCurrentValue: (value) => ({
    type: actions.SET_CURRENT_VALUE,
    payload: value
  }),
  setSelectectedOptions: (value) => ({
    type: actions.USER_OPTIONS_SELECTED,
    payload: value
  }),
  setSelectNone: (value) => ({
    type: actions.SELECT_NONE,
    payload: value
  }),
  setSelectNoneSavings: (value) => ({
    type: actions.SELECT_NONE_SAVINGS,
    payload: value
  }),
  setSelectHaveCredit: (value) => ({
    type: actions.SELECT_HAVE_CREDIT,
    payload: value
  }),
  disableNone: (value) => ({
    type: actions.DISABLE_NONE,
    payload: value
  }),
  disableCreditCard: (value) => ({
    type: actions.DISABLE_CREDIT_CARD,
    payload: value
  }),
  setEdit: (value) => ({
    type: actions.SET_EDIT,
    payload: value
  })
}

export default actionsCreators
