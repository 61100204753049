// import IconLearning from '~assets/ico-bar-learning.svg';
import IconLearning from '../../../assets/ico-bar-learning.svg'
import IconMyPlan from '../../../assets/ico-bar-plan.svg'
import IconMyResults from '../../../assets/ico-bar-results.svg'
import IconPersonalInfo from '../../../assets/ico-bar-personalInfo.svg'
import IconLearningActive from '../../../assets/ico-bar-learning-active.svg'
import IconMyPlanActive from '../../../assets/ico-bar-plan-active.svg'
import IconMyResultsActive from '../../../assets/ico-bar-results-active.svg'
import IconPersonalInfoActive from '../../../assets/ico-bar-personalInfo-active.svg'

export const OPTIONS = [
  {
    key: 0,
    name: 'myResults',
    icon: IconMyResults,
    iconActive: IconMyResultsActive
  },
  {
    key: 1,
    name: 'myPlan',
    icon: IconMyPlan,
    iconActive: IconMyPlanActive,
    route: 'MY_PLAN'
  },
  {
    key: 2,
    name: 'learning',
    icon: IconLearning,
    iconActive: IconLearningActive,
    route: 'LEARNING'
  },
  {
    key: 3,
    name: 'personalInfo',
    icon: IconPersonalInfo,
    iconActive: IconPersonalInfoActive,
    route: 'PROFILE'
  }
]

export const OPTIONS_PERSONAL_INFO = [
  {
    key: 0,
    name: 'purposes',
    route: 'app/purpose/edit'
  },
  {
    key: 1,
    name: 'family',
    route: 'app/profile/family/edit'
  },
  {
    key: 2,
    name: 'events',
    route: 'app/profile/events/edit'
  },
  {
    key: 3,
    name: 'financial',
    route: 'app/profile/financial/edit'
  }
]

export const OPTIONS_MY_RESULTS = [
  {
    key: 0,
    name: 'being',
    route: 'app/my_way_of_being'
  },
  {
    key: 1,
    name: 'finance',
    route: 'app/finance'
  }
]
