// import { DEFAULT_WHO_LIVE } from '~constants/InitialValues/initialValuesFamily';
import { DEFAULT_WHO_LIVE } from '../constants/InitialValues/initialValuesFamily'

export const denormalizeDataFamily = (data) => {
  const newOptions = []
  let wholive = 'no'

  if (data.single) {
    wholive = 'yes'
    const pet = data.options.find((item) => item.option === 'PET')
    if (pet !== undefined && pet.count !== 0 && pet.count !== null) {
      newOptions.push(pet)
    }
  } else {
    // eslint-disable-next-line array-callback-return
    data.options.map((option) => {
      if (option.count !== 0 && option.count !== null) {
        newOptions.push({ option: option.option, count: option.count })
      }
    })
  }

  if (data.depends === false || data.depends === 0) {
    delete data.dependency
    delete data.dependsinput
    data.disability = 0
  }

  if (data.disability === false || data.disability === 0) {
    delete data.medical
    delete data.disabilityinput
  }

  data.options = newOptions
  data.wholive = wholive
  return data
}

export const normalizeFamilyData = (data) => {
  const formOption = { ...DEFAULT_WHO_LIVE }
  data.options.forEach((option) => {
    formOption[option.option] = option.count
  })
  data.options = formOption
  data.wholive = data.single ? 'yes' : 'no'
  ;[data.depends, data.dependsinput] = data.dependency
    ? [1, data.dependency]
    : [0, 0]
  ;[data.disability, data.disabilityinput] = data.medical
    ? [1, data.medical]
    : [0, 0]
  return data
}

export const normalizeFamilyDataStruct = (familyData) => {
  const options = Object.entries(familyData.options)
  const newOption = []
  options.map((option) =>
    newOption.push({ option: option[0], count: option[1] })
  )
  if (familyData.depends === 0) {
    familyData.disability = null
    familyData.dependsinput = null
    familyData.disabilityinput = null
  }
  return { ...familyData, options: newOption }
}
