import React from 'react'
import { Link } from 'react-router-dom'

import styles from './styles.module.scss'
import { Navigation } from './constants'

function AdminSidebar() {
  return (
    <nav className={styles.nav}>
      <ul>
        {Navigation.map((parent) => (
          <li key={parent.key} className={styles.parent}>
            {parent.title}
            <ul>
              {parent.submenu.map((children) => (
                <li key={children.key} className={styles.children}>
                  <Link className={styles.link} to={children.path}>
                    {children.title}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </nav>
  )
}

AdminSidebar.propTypes = {}
export default AdminSidebar
