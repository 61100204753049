import i18next from 'i18next'

i18next.addResources('es', 'myActionPlan', {
  mainTitle: 'Mi plan de acción',
  linkButton: 'Solicitar'
})

i18next.addResources('es', 'myActionPlan_purpose', {
  content:
    'Un programa a tu medida que incluye sesiones individuales con expertos, quienes estudiarán al detalle toda tu información para potenciar los resultados y darle vida a tus propósitos.',
  purposes: 'Mis propósitos'
})

i18next.addResources('es', 'myActionPlan_details', {
  title: '¡Pensamos en cada detalle para llevarte al siguiente nivel!',
  first:
    'Acompañamiento personalizado y ajustado a tus propósitos, finanzas y forma de ser',
  second:
    'Un equipo para ti, con el sello SURA, expertos en finanzas, psicología del comportamiento financiero y aspectos tributarios para evaluar cada detalle',
  third:
    'En las sesiones individuales puedes participar con un familiar. Es probable que para ti sea más valioso en equipo',
  fourth:
    'Contenidos gratuitos ajustados a tus fortalezas, oportunidades de mejora y aspectos que debes potenciar. Sabías que hay una sección para niños'
})

i18next.addResources('es', 'myActionPlan_personal', {
  mainTitle: 'Tu plan personalizado',
  initialSupport: 'Acompañamiento inicial',
  monitoring: 'Citas de seguimiento',
  psychologistSupport: 'Psicológico en comportamiento financiero',
  financialSupport: 'Financiero                    ',
  time: 'Duración',
  hour: 'hora',
  minutes: 'minutos',
  session: 'sesión',
  sessions: 'sesiones'
})

i18next.addResources('es', 'myActionPlan_selfManagement', {
  title: '¡Pensamos en cada detalle para llevarte al siguiente nivel!',
  content:
    'Después de realizar tu valoración en la plataforma de Salud Financiera, ponemos a disposición contenido de interés que hemos desarrollado para ti y que te permitirá seguir aprendiendo a tener mayor consciencia y control de todas aquellas emociones y decisiones que impactan tus finanzas y a desarrollar otros conocimientos para mejorar o potencializar tus hábitos financieros.',
  budget: 'Conversemos de presupuesto',
  health: 'Videos y artículos de interés',
  life: 'Finanzas para la vida',
  guides: 'Guías para desarrollar tus capacidades psicológicas',
  children: 'Presupuesto para niños',
  secondaryTitle:
    'Si deseas recibir acompañamiento personalizado también podrás hacerlo, estos son las citas que tenemos disponibles para ti:',
  financial: 'Acompañamiento financiero',
  financialPsychological: 'Acompañamiento psicológico financiero',
  taxPlanning: 'Acompañamiento para la planeación tributaria'
})

i18next.addResources('es', 'myActionPlan_contactInfo', {
  mainTitle: '¿Quieres que te contactemos?',
  content: 'Cuál de estas opciones prefieres',
  phone: 'Teléfono',
  phoneText: 'Ingresa tu numero de teléfono y pronto nos comunicaremos contigo',
  email: 'Correo',
  emailText: 'Ingresa tu correo y pronto nos comunicaremos contigo',
  button: 'Enviar',
  required: 'Este campo es obligatorio',
  phoneFormat: 'Este campo solo puede registrar números',
  phoneLength: 'Por favor ingresa un número de teléfono fijo o celular válido',
  emailFormat: 'Introduzca una dirección de correo electrónico válida',
  done: '¡Gracias! Recibimos tú información y pronto te contactaremos por el medio seleccionado.',
  altImg: 'doneIcon'
})
