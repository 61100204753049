import i18next from 'i18next'

i18next.addResources('es', 'purposeChoose', {
  title: 'Propósitos',
  content:
    'Elige máximo tres propósitos que quieras cumplir pronto y ordénalos de acuerdo con tus prioridades',
  button: 'Continuar',
  secondTitle: '¡Has priorizado tus propósitos!',
  secondContent:
    'Te acompañaremos a tomar las mejores decisiones para que puedas lograrlos.',
  option: 'Arrastra aquí tu {{value}} propósito',
  selectPurpose: 'Selecciona tu {{value}} propósito',
  or: 'o',
  addButton: 'Añádelo aquí',
  optional:
    'Elegiste tu segundo propósito ¿Quieres agregar uno mas o finalizar tu selección de propósitos?',
  addOptional: 'Agregar',
  endOptional: 'Terminar'
})
