// import { ReactComponent as iconPartner } from '~assets/ico-Environtment-Couple.svg';
import { ReactComponent as iconPartner } from '../assets/ico-Environtment-Couple.svg'
import { ReactComponent as iconChildren } from '../assets/ico-Environtment-Children.svg'
import { ReactComponent as iconParents } from '../assets/ico-Environtment-Parents.svg'
import { ReactComponent as iconBrothers } from '../assets/ico-Environtment-Brothers.svg'
import { ReactComponent as iconPet } from '../assets/ico-Environtment-Pets.svg'
import { ReactComponent as iconOthers } from '../assets/ico-Environtment-Others.svg'

export const FORM_TYPE_WHO_LIVE = {
  PARTNER: 'COUPLE',
  CHILDREN: 'CHILD',
  PARENTS: 'PARENT',
  BROTHERS: 'SIBLING',
  PET: 'PET',
  OTHER: 'OTHER',
  WHO_LIVE_LIST: 'options'
}

export const FORM_ICONS_WHO_LIVE = {
  partner: iconPartner,
  parents: iconParents,
  children: iconChildren,
  brothers: iconBrothers,
  others: iconOthers,
  pet: iconPet
}

export const AFFIRMATIVE = 'yes'
export const NEGATIVE = 'no'
export const MIN_DEPENDS = 1
export const POLL_FORM = 'PollForm'
