import i18next from 'i18next'

i18next.addResources('es', 'financeDashboard', {
  mainTitle: 'Mis Finanzas',
  nickTitle: 'Hola',
  summaryTitle: 'Resumen',
  contentSummary: 'Así esta el resumen de tus gastos durante el mes.'
})

i18next.addResources('es', 'financialSummary', {
  outcomePlusSaving: 'Gastos + ahorros',
  income: 'Ingresos',
  savings: 'Ahorros',
  savingsInvestments: 'Ahorro e inversiones',
  expenses: 'Gastos',
  available: 'Saldo disponible'
})

i18next.addResources('es', 'financialExpenses', {
  content:
    'Selecciona cada una de las categorías para conocer el detalle de tu información.',
  money: '$',
  percentage: '%',
  seeDetails: 'Ver detalles',
  TextSaving: 'Ahorro',
  Incomes: 'Ingresos',
  button: 'Editar mis finanzas  '
})

i18next.addResources('es', 'financialRecommendations', {
  mainTitle: 'Recomendaciones de tu información financiera',
  maxDebtWithSupportText: 'Créditos de vivienda o vehículo:',
  maxDebtWithoutSupportText: 'Otros préstamos:',
  maxCreditCardRotativeText:
    'Deudas en tarjetas de crédito o créditos rotativos:',
  mainDebtPercentText: 'Moras como principal:',
  coDeptorDebtPercentText: 'Moras como codeudor:'
})

i18next.addResources('es', 'FinanceExpenses', {
  redContent:
    'Analiza tu estado actual y toma decisiones para que tu situación mejore',
  greenContent:
    'Tienes un balance adecuado, continúa con tus hábitos, potencia tu situación y estarás más cerca de tus propósitos',
  orangeContent:
    'Tienes una situación moderada, evalúa lo que puedes mejorar y haz cambios conscientes'
})

i18next.addResources('es', 'headerFinanceDashboard', {
  income: 'Ingresos'
})

i18next.addResources('es', 'expensesDetails', {
  title: 'Detalles',
  content: 'Así están distribuidos tus gastos ',
  Alimentacion: 'Alimentación',
  Salud: 'Gastos en Salud',
  'Servicios publicos': 'Servicios públicos',
  'Manutención a terceros': 'Dependientes económicos',
  'Prestamos con persona natural': 'Préstamos con persona natural',
  'Otros prestamos con entidades financieras':
    'Otros préstamos con entidades financieras',
  'Tarjetas de credito/creditos rotativos':
    'Tarjetas de crédito o créditos rotativos',
  'Creditos de vivienda': 'Créditos de vivienda',
  'Creditos de vehiculo': 'Créditos de vehículo',
  Educacion: 'Educación',
  Basico: 'Básicos',
  Legal: 'Legales',
  Compromiso: 'Compromisos',
  Electivo: 'Elecciones',
  Ahorro: 'Ahorros',
  Otros: 'Varios',
  TextSaving: 'Ahorro',
  Incomes: 'Ingresos',
  Ingresos: 'Ingresos',
  Arriendo: 'Arriendo, administración o predial',
  Embargos: 'Embargos, multas y/o sanciones',
  Total: 'Total'
})

i18next.addResources('es', 'months', {
  january: 'Enero',
  february: 'Febrero',
  march: 'Marzo',
  april: 'Abril',
  may: 'Mayo',
  june: 'Junio',
  july: 'Julio',
  august: 'Agosto',
  september: 'Septiembre',
  october: 'Octubre',
  november: 'Noviembre',
  december: 'Diciembre'
})

i18next.addResources('es', 'FinanceSavings', {
  greenContent: 'Con el hábito de ahorro te estas acercando a tus propósitos'
})

i18next.addResources('es', 'recommendationEngine', {
  header: 'Mis resultados',
  content:
    ', de acuerdo con el análisis de tu información, encontramos que es importante profundizar en algunos aspectos de tu personalidad que pueden estar influyendo en tus decisiones financieras.',
  button: 'volver',
  leftOption: 'Mi forma de ser',
  rightOption: 'Mis finanzas',
  commitments: 'Compromisos',
  debts: 'Créditos en mora ',
  savings: 'Ahorros',
  insurances: 'Seguros',
  and: 'y',
  footerTitle: '¡Queremos acompañarte!',
  footerContentFirst:
    'Para que desarrolles tus oportunidades de mejora, transformes tu comportamiento financiero y te',
  footerContentSecond: 'acerques al logro de tus propósitos de:',
  message:
    'Pronto te estaremos contactando para conocer tus inquietudes y recorrer juntos este camino',
  creditCardRotativePercent: 'Tarjetas de crédito / créditos rotativos',
  debtWithSupportPercent: 'Créditos de vivienda / vehículo',
  debtWithoutSupportPercent: 'Otros préstamos',
  coDeptorDebtPercent: 'Total moras como principal',
  mainDebtPercent: 'Total moras como codeudor',
  btnFinalize: 'FINALIZAR'
})
