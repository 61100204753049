import React, { Component } from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'
import { shape, func, bool, string } from 'prop-types'

import Routes from './layout'

import { history } from '../../../redux/store'
import actions from '../../../redux/Auth/actions'
import { actionsCreators } from '../../../redux/Switches/SwitchActions'
import profileActions from '../../../redux/Profile/actions'
import { familyPropType } from '../../../constants/propTypes'
import userExperienceActions from '../../../redux/UserExperience/actions'
import { Box, CircularProgress } from '@mui/material'
import { REDIRECT_SWITCH_ROUTES } from '../../../constants/routes'

class AppRoutesContainer extends Component {
  componentDidMount() {
    const { getUser, getWorlds, getUserExperience, getRedirectSwitch } =
      this.props
    getUser()
    getRedirectSwitch()
    getWorlds()
    getUserExperience()
  }

  render() {
    const {
      currentUser,
      loading,
      hasPendingWorld,
      showFloatingOptions,
      modalData,
      currentRoleInterface,
      profileLoading,
      switchLoading,
      redirectSwitchValue,
      isAdmin
    } = this.props

    if (switchLoading) {
      return (
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )
    }

    if (!switchLoading && redirectSwitchValue && !isAdmin) {
      window.location.href = REDIRECT_SWITCH_ROUTES
      return null
    }

    return (
      <ConnectedRouter history={history}>
        <Routes
          loading={loading && profileLoading && switchLoading}
          currentUser={currentUser}
          hasPendingWorld={hasPendingWorld}
          showFloatingOptions={showFloatingOptions}
          modalData={modalData}
          currentRoleInterface={currentRoleInterface}
        />
      </ConnectedRouter>
    )
  }
}

AppRoutesContainer.propTypes = {
  getUser: func.isRequired,
  getRedirectSwitch: func.isRequired,
  getUserExperience: func.isRequired,
  getWorlds: func.isRequired,
  hasPendingWorld: bool.isRequired,
  loading: bool.isRequired,
  profileLoading: bool.isRequired,
  switchLoading: bool.isRequired,
  modalData: familyPropType.isRequired,
  showFloatingOptions: bool.isRequired,
  currentRoleInterface: string,
  currentUser: shape({
    nick: string,
    id: string
  }),
  redirectSwitchValue: bool.isRequired,
  isAdmin: bool.isRequired
}

const mapStateToForm = (state) => ({
  currentUser: state.auth.currentUser,
  currentRoleInterface: state.userExperience.currentRoleInterface,
  hasPendingWorld: state.auth.hasPendingWorld,
  showFloatingOptions: state.auth.showFloatingOptions,
  loading: state.auth.appLoading,
  profileLoading: state.profile.profileLoading,
  switchLoading: state.switchReducer.switchesLoading,
  redirectSwitchValue: state.switchReducer.switches?.data?.value,
  profile: state.profile.profile,
  modalData: state.modal,
  isAdmin: state.auth.isAdmin
})

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(actions.getUser()),
  getRedirectSwitch: () => dispatch(actionsCreators.getSwitches()),
  getWorlds: () => dispatch(profileActions.getProfile()),
  getUserExperience: () => dispatch(userExperienceActions.getData()),
  setRoleInterface: (values) =>
    dispatch(userExperienceActions.setRoleInterface(values))
})

export default connect(mapStateToForm, mapDispatchToProps)(AppRoutesContainer)
