import i18next from 'i18next'

i18next.addResources('es', 'resultsMyWayOfBeing', {
  title: 'Mi forma de ser',
  nickTitle: 'Hola',
  openingParagraph:
    'En este gráfico podrás visualizar los resultados de la valoración del mundo Mi forma de ser',
  middleParagraph:
    'Si la capacidad está cercana al centro del gráfico, indica que tienes una oportunidad de mejora en este aspecto.',
  endingParagraph:
    'Por el contrario, si está alejada del centro, quiere decir que es una fortaleza con la que cuentas.',
  strengths: 'Fortalezas',
  whatIShouldEnhance: 'Lo que debo potenciar',
  improvementOpportunities: 'Oportunidades de mejora',
  textstrengths:
    'Convierte tus oportunidades de mejora en tu mayor fortaleza para dar pasos firmes en la construcción de un futuro más tranquilo y sostenible.' +
    '<br><br>' +
    'Este es solo el inicio de un paso a paso en el que descubrirás los ejercicios y acompañamiento diseñados especialmente para ti.' +
    '<br><br>' +
    'Te invitamos a ingresar a la pestaña Rutas de aprendizaje y allí encontrarás las <strong>Guías de desarrollo</strong> para seguir fortaleciendo los comportamientos que te conducirán a incrementar tu salud financiera.',
  textwhatIShouldEnhance:
    'Te invitamos a mirar con detalle los resultados de esta valoración y compararlos con tu autoconocimiento, identifica esos aspectos que tú mas que nadie sabes, debes llevar a un nuevo nivel para transformarlos en verdaderas fortalezas.' +
    '<br><br>' +
    'Te invitamos a ingresar a la pestaña Rutas de aprendizaje y allí encontrarás las <strong>Guías de desarrollo</strong> para seguir fortaleciendo los comportamientos que te conducirán a incrementar tu salud financiera.',
  textimprovementOpportunities:
    '¡Felicitaciones! de acuerdo con tus resultados, cuentas con fortalezas que te ayudarán a seguir mejorado cada día tu realidad financiera.' +
    '<br><br>' +
    'Te encuentras en la mejor posición para avanzar en esa búsqueda de sumar cada vez más experiencias que te mantengan cerca de tu propósito de seguir incrementando tu calidad de vida y bienestar.' +
    '<br><br>' +
    'Te invitamos a ingresar a la pestaña Rutas de aprendizaje donde encontrarás material diseñado para seguirte acompañando en el fortalecimiento de los comportamientos que te llevarán cada día a mantener tu salud financiera.',
  SelfEfficacy: 'Autoeficacia',
  FinancialSelfEfficacy: 'Autoeficacia financiera',
  SelfRegulation: 'Autorregulación',
  EmotionalStability: 'Estabilidad emocional',
  ExternalLocusControl: 'Locus de control externo',
  InternalLocusControl: 'Locus de control interno',
  NeedRecognition: 'Necesidad de reconocimiento',
  FutureVision: 'Visión de futuro',
  lowLevel: 'Bajo',
  mediumLevel: 'Medio',
  highLevel: 'Alto',
  myResults: 'Mi Resultado'
})
