/* eslint-disable react/forbid-foreign-prop-types */
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'

import Routes from '../../../../constants/routes'

function AuthenticatedRoute({
  isPrivateRoute,
  isPublicRoute,
  currentUser,
  component: Comp,
  ...props
}) {
  return (
    <Route
      {...props}
      // eslint-disable-next-line react/jsx-no-bind
      render={(routeProps) => {
        const authIni = 0
        const auth = Object.keys(currentUser).length > authIni
        if (auth && isPublicRoute) {
          return (
            <Redirect
              to={{
                pathname: Routes.HOME,
                state: { from: props.location }
              }}
            />
          )
        }
        if (!auth && isPrivateRoute) {
          return (
            <Redirect
              to={{
                pathname: Routes.LOGIN,
                state: { from: props.location }
              }}
            />
          )
        }
        return <Comp {...routeProps} />
      }}
    />
  )
}

AuthenticatedRoute.defaultProps = {
  currentUser: {}
}

AuthenticatedRoute.propTypes = {
  ...Route.propTypes,
  component: PropTypes.elementType,
  currentUser: PropTypes.shape({
    name: PropTypes.string
  }),
  isPrivateRoute: PropTypes.bool,
  isPublicRoute: PropTypes.bool
}

const mapStatetoProps = (state) => ({
  currentUser: state.auth.currentUser
})

export default withRouter(connect(mapStatetoProps)(AuthenticatedRoute))
