import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  tableData: {
    countItems: null,
    items: [],
    totalPages: null,
    currentPage: null
  },
  tableDataLoading: false,
  levelRiskListLoading: false,
  showConfirmButton: false,
  usersSelected: [],
  showActions: false,
  programsData: [],
  groupsList: [],
  filters: {
    documents: [],
    economicGroup: [],
    socialReason: [],
    program: []
  },
  showFilterCard: false,
  showDocumentCard: false,
  validationForm: {
    beginDateError: null,
    endDateErro: null
  },
  idTomador: 0,
  isDisabled: true,
  isDisabledTaker: false,
  showUpdateSelect: false,
  reportUser: null,
  emailOptionName: '',
  usersToEmail: {
    optionComunication: '',
    users: []
  },
  enableSuccesMessage: false,
  valueUpdate: '',
  documentoToSearch: '',
  enable: false,
  showModal: false,
  statusCode: null,
  showModalMessage: false,
  sendingEmail: false
}
const deleteRow = (list, row) => {
  const index = list.findIndex((item) => item === row)
  list.splice(index, 1)
  return list
}

const changeFilters = (filter, value) => {
  const index = filter.findIndex((element) => element === value)

  if (index < 0) {
    return [...filter, value]
  }
  filter.splice(index, 1)
  return filter
}

const changeOption = (options, value, option) => {
  const index = options.findIndex((element) => element.value === value)
  if (index < 0) {
    return [...options, { value, label: value, option }]
  }
  options.splice(index, 1)
  return options
}

const reducerDescription = {
  primaryActions: [
    actions.GET_TABLE_DATA,
    actions.GET_PROGRAMS_DATA,
    actions.GET_PROFILES_DATA,
    actions.SEND_EMAIL_USER,
    actions.GET_ECONOMIC_GROUPS
  ],
  override: {
    [actions.SET_GENERAL_STATE_OPTIONS]: (state, action) => ({
      ...state,
      generalStateOptions: action.payload
    }),
    [actions.ADD_ROW_SELECTED]: (state, action) => ({
      ...state,
      usersSelected: [...state.usersSelected, action.payload],
      showActions: true,
      canDelete: [...state.usersSelected, action.payload].length === 1
    }),
    [actions.DELETE_ROW_SELECTED]: (state, action) => ({
      ...state,
      usersSelected: deleteRow(state.usersSelected, action.payload),
      showActions: state.usersSelected.length > 0,
      canDelete: state.usersSelected.length === 1
    }),
    [actions.UPDATE_USER_SELECTED]: (state, action) => ({
      ...state,
      showUpdateSelect: action.payload,
      showActions: action.payload
    }),
    [actions.SET_SHOW_CONFIRM]: (state, action) => ({
      ...state,
      showConfirmButton: action.payload
    }),
    [actions.SELECT_ALL_ROWS]: (state, action) => ({
      ...state,
      usersSelected: action.payload,
      showActions: action.payload.length > 0,
      canDelete: action.payload.length === 1
    }),
    [actions.CHANGE_OPTIONS]: (state, action) => ({
      ...state,
      [action.payload.option]: changeOption(
        state[action.payload.option],
        action.payload.value,
        action.payload.option
      )
    }),
    [actions.CHANGE_FILTER]: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        [action.payload.filter]: changeFilters(
          state.filters[action.payload.filter],
          action.payload.value
        )
      }
    }),
    [actions.CHANGE_FILTER_DATES]: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        dates: action.payload
      }
    }),
    [actions.SET_SHOW_FILTER_CARD]: (state, action) => ({
      ...state,
      showFilterCard: action.payload
    }),
    [actions.SET_SHOW_DOCUMENT_CARD]: (state, action) => ({
      ...state,
      showDocumentCard: action.payload
    }),
    [actions.SET_TAKER_ID]: (state, action) => ({
      ...state,
      idTomador: action.payload
    }),
    [actions.SET_DOCUMENT]: (state, action) => ({
      ...state,
      documentoToSearch: action.payload
    }),
    [actions.SET_DISABLED]: (state, action) => ({
      ...state,
      isDisabled: action.payload
    }),
    [actions.ADD_EMAIL_TYPE]: (state, action) => ({
      ...state,
      usersToEmail: {
        optionComunication: action.payload
      }
    }),
    [actions.ADD_USERS_TOEMAIL]: (state, action) => ({
      ...state,
      usersToEmail: {
        ...state.usersToEmail,
        users: action.payload
      }
    }),
    [actions.SET_DISABLED_TAKER]: (state, action) => ({
      ...state,
      isDisabledTaker: action.payload
    }),
    [actions.EMAIL_OPTION_NAME]: (state, action) => ({
      ...state,
      emailOptionName: action.payload
    }),
    [actions.CLEAR_FILTERS]: (state) => ({
      ...state,
      filters: initialState.filters
    }),
    [actions.SET_FORM_ERROR]: (state, action) => ({
      ...state,
      validationForm: {
        ...state.validationForm,
        [action.payload.key]: action.payload.message
      }
    }),
    [actions.SET_REPORT_USER]: (state, action) => ({
      ...state,
      reportUser: action.payload
    }),
    [actions.ENABLE_SUCCESS_MESSAGE]: (state, action) => ({
      ...state,
      enableSuccesMessage: action.payload
    }),
    [actions.SET_VALUE_UPDATE]: (state, action) => ({
      ...state,
      valueUpdate: action.payload
    }),
    [actions.SET_SHOW_ACTIONS]: (state, action) => ({
      ...state,
      showActions: action.payload
    }),
    [actions.HIDE_EMAIL_ACTIONS]: (state, action) => ({
      ...state,
      showActions: action.payload
    }),
    [actions.SET_EMPTY_USERS]: (state, action) => ({
      ...state,
      usersSelected: action.payload
    }),
    [actions.SET_ENABLE_BUTTON]: (state, action) => ({
      ...state,
      enable: action.payload
    }),
    [actions.SET_SHOW_MODAL]: (state, action) => ({
      ...state,
      showModal: action.payload
    }),
    [actions.SET_SHOW_MESSAGE]: (state, action) => ({
      ...state,
      showModalMessage: action.payload
    }),
    [actions.SET_STATUS_CODE]: (state, action) => ({
      ...state,
      statusCode: action.payload
    }),
    [actions.SET_SENDING_EMAIL]: (state, action) => ({
      ...state,
      sendingEmail: action.payload
    })
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
