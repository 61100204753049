import { createTypes, completeTypes, withPostFailure } from 'redux-recompose'

import { priorityCombinationService } from '../../services/BifiService'
import { normalizeData } from '../../schemas/priorityCombinationSchema'
import modalActions from '../../redux/Modal/actions'

export const actions = createTypes(
  completeTypes(['GET_DATA']),
  '@@PRIORITY_COMBINATION'
)

export const actionsCreators = {
  getData: () => ({
    type: actions.GET_DATA,
    target: 'priorityCombination',
    service: priorityCombinationService.getData,
    successSelector: (response) => normalizeData(response.data),
    injections: [
      withPostFailure((dispatch, response, state) => {
        if (
          response.data.internal_code === 'not_found' &&
          state.modal.showErrorModal === false
        ) {
          dispatch(
            modalActions.showErrorModal({
              showErrorModal: true,
              errorType: 'NOT_FOUND',
              reportError: true,
              errorMessage: response.data.message
            })
          )
        }
      })
    ]
  })
}

export default actionsCreators
