import i18next from 'i18next'

i18next.addResources('es', 'adminSidebar', {
  dashboard: 'Dashboard',
  home: 'Inicio',
  users: 'Usuarios',
  usersPanel: 'Panel de usuarios',
  schedulingPanel: 'Panel de Acompañamientos',
  UsersManagement: 'Administración de usuarios',
  usersByAllProgramsPanel: 'Panel de usuarios con todos los programas',
  createUsers: 'Crear Usuarios',
  createPrograms: 'Crear Programas',
  conversationsTittle: 'Conversatorios',
  conversations: 'Registro de conversatorios',
  emailTitle: 'Correos',
  emails: 'Envío de correos',
  programsPanel: 'Panel de programas'
})
