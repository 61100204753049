import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  purposes: [
    { id: null, priority: 1, content: null },
    { id: null, priority: 2, content: null },
    { id: null, priority: 3, content: null }
  ],
  boxes: [],
  currentBox: null,
  selected: false,
  editPurposes: false,
  currentPage: 1,
  optionalPurpose: false,
  purposesLoading: false,
  boxesLoading: false
}

const reducerDescription = {
  primaryActions: [
    actions.POST_PURPOSES,
    actions.GET_DATA,
    actions.GET_DATA_USER
  ],
  override: {
    [actions.SET_EDIT_PURPOSES]: (state, action) => ({
      ...state,
      editPurposes: action.payload
    }),
    [actions.SET_PURPOSES]: (state, action) => ({
      ...state,
      boxes: action.payload.boxes,
      purposes: action.payload.purposes
    }),
    [actions.CLEAR_PURPOSES]: (state, action) => ({
      ...state,
      purposes: action.payload,
      selected: false
    }),
    [actions.SET_CURRENT_BOX]: (state, action) => ({
      ...state,
      currentBox: action.payload
    }),
    [actions.CLEAR_CURRENT_BOX]: (state) => ({
      ...state,
      currentBox: null
    }),
    [actions.SET_CURRENT_PAGE]: (state, action) => ({
      ...state,
      currentPage: action.payload
    }),
    [actions.SET_OPTIONAL_PURPOSE]: (state, action) => ({
      ...state,
      optionalPurpose: action.payload
    })
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
