import i18next from 'i18next'

i18next.addResources('es', 'finance', {
  firstTitle: 'Básicos',
  firstContent: 'Todo lo que necesitas para sobrevivir',
  secondTitle: 'Legales',
  secondContent: 'Lo que debes pagar como ciudadano de este país',
  thirdTitle: 'Compromisos',
  thirdContent: 'Aquello que debes pagar con alguna entidad o persona',
  fourthTitle: 'Elecciones',
  fourthContent: 'Lo que eliges para disfrutar o entretenerte',
  fifthTitle: 'Ahorros',
  fifthContent:
    'Para el logro de mis propósitos mi tranquilidad y respaldo futuro',
  sixthTitle: 'Ingresos',
  sixthContent:
    'Para el logro de mis propósitos mi tranquilidad y respaldo futuro'
})
