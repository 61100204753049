// import { ReactComponent as IconDevelopment } from '~assets/ico-Events-Developing.svg';
import { ReactComponent as IconDevelopment } from '../assets/ico-Events-Developing.svg'
import { ReactComponent as IconAcquisition } from '../assets/ico-Events-Goods.svg'
import { ReactComponent as IconHome } from '../assets/ico-Events-Living-Place-Change.svg'
import { ReactComponent as IconStatus } from '../assets/ico-Events-Family-Change.svg'
import { ReactComponent as IconLabor } from '../assets/ico-Events-Job-Change.svg'
import { ReactComponent as IconDebts } from '../assets/ico-Events-Debts.svg'
import { ReactComponent as IconProblems } from '../assets/ico-Events-Losses.svg'
import { ReactComponent as IconMaterials } from '../assets/ico-Events-Health.svg'
import { ReactComponent as IconUnexpected } from '../assets/ico-Events-Income.svg'

export const ICONS_EVENTS = [
  IconDevelopment,
  IconAcquisition,
  IconHome,
  IconStatus,
  IconLabor,
  IconDebts,
  IconProblems,
  IconMaterials,
  IconUnexpected
]

export const NEWNESS = 'Novedades'
export const UNPRESENTED = 'Imprevistos'

export const ERROR_CODES = {
  auth: 401,
  noBack: 400,
  notFound: 404,
  exist: 409,
  server: 500
}

export const INTERNAL_CODES = {
  alreadyRegistered: 'unconfirmed_user',
  unregistered: 'unregistered user',
  invalidToken: 'authentication_error'
}

export const INTERNAL_CODE = 'internal_code'

export const HISTORICAL_DATASET_EXPENSES = {
  labelString: '%',
  fill: false,
  lineTension: 0,
  backgroundColor: '#DF4E4E',
  borderColor: '#DF4E4E',
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: '#DF4E4E',
  pointBackgroundColor: '#DF4E4E',
  pointBorderWidth: 0,
  pointHoverRadius: 8,
  pointHoverBackgroundColor: '#DF4E4E',
  pointHoverBorderColor: '#FFF',
  pointHoverBorderWidth: 9,
  pointRadius: 1,
  pointHitRadius: 10
}

export const HISTORICAL_DATASET_SAVING = {
  labelString: '%',
  fill: false,
  lineTension: 0,
  backgroundColor: '#00B2A9',
  borderColor: '#00B2A9',
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: '00B2A9',
  pointBackgroundColor: '#00B2A9',
  pointBorderWidth: 0,
  pointHoverRadius: 8,
  pointHoverBackgroundColor: '#00B2A9',
  pointHoverBorderColor: '#FFF',
  pointHoverBorderWidth: 9,
  pointRadius: 1,
  pointHitRadius: 10
}

export const PDF_PAGE = {
  width: 216,
  height: 279
}
