import React, { Fragment } from 'react'
import { shape, string, bool } from 'prop-types'

import styles from './styles.module.scss'

import NavBarBottom from '../../../NavbarBottom'
// import NavBarBottom from '~components/NavbarBottom';
import FooterComponent from '../../../Footer'
// import FooterComponent from '~components/Footer';

function getFooterState() {
  const currentLocation = window.location.pathname
  const validation =
    currentLocation.includes('purpose') ||
    currentLocation === '/' ||
    currentLocation === '/app' ||
    currentLocation === '/app/'
  return !validation
}

function Footer({ currentUser, hasPendingWorld, isAdminPage }) {
  const showFooter = getFooterState()
  return currentUser.id && showFooter ? (
    <Fragment>
      {!hasPendingWorld && !isAdminPage && (
        <div className="d-block d-md-none">
          <NavBarBottom />
        </div>
      )}
      <footer
        className={`${
          hasPendingWorld || isAdminPage ? '' : styles.footerMargin
        } ${styles.footer}`}
      >
        <FooterComponent />
      </footer>
    </Fragment>
  ) : null
}

Footer.propTypes = {
  currentUser: shape({
    id: string,
    nick: string,
    city: string
  }),
  hasPendingWorld: bool,
  isAdminPage: bool
}

export default Footer
