import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  dashboard: {
    totalClientsActive: null,
    totalClientsCompleted: {
      count: null,
      percentage: null
    },
    totalUsersFinishedStateGeneral: null,
    totalUsersGroupByStateGeneral: [],
    totalUsersGroupByRiskLevel: [],
    totalUsersTimeSeries: null
  },
  dashboardLoading: false,
  generalStateListLoading: false,
  sourcesListLoading: false,
  levelRiskListLoading: false,
  levelRiskList: [],
  sourcesList: [],
  generalStateList: [],
  generalStateOptions: [],
  programsData: [],
  generalStateListFull: [],
  processList: [],
  groupsList: [],
  generalStateOptionsFull: [],
  filters: {
    documents: [],
    levelRisk: [],
    generalStatesGroup: [],
    stateProcess: [],
    economicGroup: [],
    program: [],
    socialReason: [],
    dates: { begin: null, end: null }
  },
  idTomador: 0,
  isDisabled: true,
  isDisabledTaker: false,
  showFilterCard: false,
  validationForm: {
    beginDateError: null,
    endDateErro: null
  },
  enable: false,
  showModal: false
}

const changeFilters = (filter, value) => {
  const index = filter.findIndex((element) => element === value)
  if (index < 0) {
    return [...filter, value]
  }
  filter.splice(index, 1)
  return filter
}

const changeOption = (options, value, option) => {
  const index = options.findIndex((element) => element.value === value)
  if (index < 0) {
    return [...options, { value, label: value, option }]
  }
  options.splice(index, 1)
  return options
}

const reducerDescription = {
  primaryActions: [
    actions.GET_DASHBOARD_DATA,
    actions.GET_LEVEL_RISK,
    actions.GET_GENERAL_STATES,
    actions.GET_GENERAL_STATESFULL,
    actions.GET_PROGRAMS_DATA,
    actions.GET_STATE_PROCESS,
    actions.GET_ECONOMIC_GROUPS
  ],
  override: {
    [actions.SET_GENERAL_STATE_OPTIONS]: (state, action) => ({
      ...state,
      generalStateOptions: action.payload
    }),
    [actions.CHANGE_FILTER]: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        [action.payload.filter]: changeFilters(
          state.filters[action.payload.filter],
          action.payload.value
        )
      }
    }),
    [actions.CHANGE_FILTER_DATES]: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        dates: action.payload
      }
    }),
    [actions.SET_SHOW_FILTER_CARD]: (state, action) => ({
      ...state,
      showFilterCard: action.payload
    }),
    [actions.CLEAR_FILTERS]: (state) => ({
      ...state,
      filters: initialState.filters
    }),
    [actions.CHANGE_OPTIONS]: (state, action) => ({
      ...state,
      [action.payload.option]: changeOption(
        state[action.payload.option],
        action.payload.value,
        action.payload.option
      )
    }),
    [actions.SET_FORM_ERROR]: (state, action) => ({
      ...state,
      validationForm: {
        ...state.validationForm,
        [action.payload.key]: action.payload.message
      }
    }),
    [actions.RESET_FORM_ERROR]: (state) => ({
      ...state,
      validationForm: {
        beginDateError: null,
        endDateErro: null
      }
    }),
    [actions.SET_TAKER_ID]: (state, action) => ({
      ...state,
      idTomador: action.payload
    }),
    [actions.SET_DISABLED]: (state, action) => ({
      ...state,
      isDisabled: action.payload
    }),
    [actions.SET_DISABLED_TAKER]: (state, action) => ({
      ...state,
      isDisabledTaker: action.payload
    }),
    [actions.SET_ENABLE_BUTTON]: (state, action) => ({
      ...state,
      enable: action.payload
    }),
    [actions.SET_SHOW_MODAL]: (state, action) => ({
      ...state,
      showModal: action.payload
    })
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
