import { createReducer, completeReducer, onReadValue } from 'redux-recompose'

import { actions } from './actions'

/* ------------- Auth reducer ------------- */
const initialState = {
  appLoading: true,
  currentUser: {},
  currentUserError: '',
  currentUserLoading: false,
  unknownPassword: '',
  unknownPasswordError: '',
  unknownPasswordLoading: false,
  alterPassword: '',
  alterPasswordError: '',
  alterPasswordLoading: false,
  register: false,
  registerError: '',
  registerLoading: false,
  resend: '',
  hasPendingWorld: true,
  showFloatingOptions: false,
  comunicationChannelLoading: false,
  isAdmin: false
}

const reducerDescription = {
  primaryActions: [actions.GET_USER, actions.PUT_COMUNICATION_CHANNEL],
  override: {
    [actions.SET_ERROR]: (state, action) => ({
      ...state,
      [`${action.payload.property}Error`]: action.payload.value
    }),
    [actions.CLEAR_CLIENT_ERROR]: (state, action) => ({
      ...state,
      [`${action.payload}Error`]: ''
    }),
    [actions.SET_STATUS_WORLD]: (state, action) => ({
      ...state,
      hasPendingWorld: action.payload
    }),
    [actions.SET_FLOATING_OPTIONS]: (state, action) => ({
      ...state,
      showFloatingOptions: action.payload
    }),
    [actions.SET_ROLE]: (state, action) => ({
      ...state,
      isAdmin: action.payload
    }),
    [actions.LOGOUT]: onReadValue(),
    [actions.SET_APP_LOADING]: onReadValue()
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
