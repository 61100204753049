// import api from '~config/api';
import api from '../config/api'
import { isLab } from '../constants/environment'

export const logout = () => api.post('/logout')

export const getUser = () => api.get('/user')

export const verifyToken = (token) =>
  api.post(`${isLab() ? '/users/validate_session' : '/validatesession'}`, {
    token
  })
