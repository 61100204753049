import i18next from 'i18next'

i18next.addResources('es', 'utils', {
  auth: 'El correo electrónico ya se encuentra registrado.',
  confirm: 'No se ha confirmado el correo electrónico.',
  unregistered: 'Este correo electrónico no se encuentra registrado.',
  invalidToken: 'Hay un problema con la autenticación. Por favor verifique.',
  alreadyRegistered:
    'Este correo ya se encuentra registrado. Por favor verifique.',
  server: 'El servidor no responde.',
  expired: 'Está invitación ya expiró. Hemos enviado una nueva a tu correo.',
  registeredUser: 'El usuario ya se encuentra registrado',
  unregisteredUser: 'El usuario NO se encuentra registrado',
  databaseError:
    'El servidor no responde en este momento. Por favor intente más tarde.',
  authenticationError: 'Usuario o contraseña incorrectos',
  unconfirmedUser:
    'No has completado tu registro, verifica tu correo y completa el proceso',
  activeUser: 'El usuario ya se encuentra registrado',
  userWithoutPermission:
    'Gracias por registrarse. Alguien de nuestro equipo pronto se pondrá en contacto con usted. En el momento no puede INGRESAR a la aplicación.',
  january: 'Enero',
  february: 'Febrero',
  march: 'Marzo',
  april: 'Abril',
  may: 'Mayo',
  june: 'Junio',
  july: 'Julio',
  august: 'Agosto',
  september: 'Septiembre',
  october: 'Octubre',
  november: 'Noviembre',
  december: 'Diciembre'
})
