import { ADMIN_ROUTES, USER_ROUTES } from '../constants/routes'

export function getAllowedRoutes(routes, allowedPaths) {
  return routes.filter(
    (route) =>
      allowedPaths.filter((allowedPath) => route.path === allowedPath).length
  )
}

export function getCurrentRoleInterface(payload) {
  if (
    (Object.values(ADMIN_ROUTES).includes(payload.path) ||
      Object.values(ADMIN_ROUTES)
        .map((route) => `/app${route}`)
        .includes(payload.path)) &&
    payload.isAdmin
  ) {
    return 'admin'
  }
  if (
    Object.values(USER_ROUTES).includes(payload.path) ||
    Object.values(USER_ROUTES)
      .map((route) => `/app${route}`)
      .includes(payload.path) ||
    !payload.isAdmin
  ) {
    return 'user'
  }
  return 'admin'
}
