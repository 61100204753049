/* eslint-disable camelcase */
import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  programsData: [],
  sourcesList: [],
  nitList: [],
  documentList: [],
  citiesList: [],
  profilesList: [],
  conversationsList: [],
  vpcList: [],
  reportData: null,
  showModal: false,
  postUserLoading: false,
  statusCode: null
}

const reducerDescription = {
  primaryActions: [
    actions.GET_SOURCES_DATA,
    actions.GET_PROGRAMS_DATA,
    actions.GET_DOCUMENT_DATA,
    actions.POST_CREATE_USER,
    actions.POST_UPLOAD_USERS,
    actions.GET_CITIES_DATA,
    actions.GET_PROFILES_DATA,
    actions.GET_CONVERSATIONS_DATA,
    actions.GET_VPC_DATA
  ],
  override: {
    [actions.SET_REPORT_DATA]: (state, action) => ({
      ...state,
      reportData: action.payload
    }),
    [actions.SET_SHOW_MODAL]: (state, action) => ({
      ...state,
      showModal: action.payload
    }),
    [actions.SET_POST_USER_LOADING]: (state, action) => ({
      ...state,
      postUserLoading: action.payload
    }),
    [actions.SET_STATUS_CODE]: (state, action) => ({
      ...state,
      statusCode: action.payload
    })
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
