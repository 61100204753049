import React from 'react'
import { bool } from 'prop-types'

import Loader from '../Loading'

function WithLoading(Component) {
  function WithLoadingComponent({ isLogin, ...props }) {
    if (!isLogin) {
      return <Component {...props} />
    }
    return (
      <div className="column center middle full-height full-width">
        <Loader />
      </div>
    )
  }

  WithLoadingComponent.propTypes = {
    isLogin: bool
  }

  return WithLoadingComponent
}

WithLoading.propTypes = {
  isLogin: bool.isRequired
}

export default WithLoading
