import React from 'react'
import { shape, string, bool } from 'prop-types'

import styles from './styles.module.scss'

import AdminNavbar from '../../../AdminNavbar'
import NavBar from '../../../NavBar'

function Header({ currentUser, isAdminPage, loading }) {
  return currentUser.id && !loading ? (
    <header className={styles.header}>
      {isAdminPage ? <AdminNavbar /> : <NavBar />}
    </header>
  ) : null
}

Header.propTypes = {
  loading: bool.isRequired,
  currentUser: shape({
    id: string,
    nick: string,
    city: string
  }),
  isAdminPage: bool
}

export default Header
