/* eslint-disable camelcase */
import i18next from 'i18next'

i18next.addResources('es', 'usersByAllPrograms', {
  title: 'Administración de usuarios con todos los programas',
  createUser: 'Crear usuario',
  tableTitle: 'Clientes Salud Financiera',
  total: 'Registros totales: ',
  download: 'Descargar',
  filters: 'Filtros',
  search: 'Buscar',
  valorationDateToolTip: 'Primer Nivel de riesgo del usuario en este programa'
})

i18next.addResources('es', 'usersActions', {
  deleteSelection: 'Borrar selección',
  editSelection: 'Selecciona el campo que quiere editar',
  city: 'Seleccione el nuevo valor para el campo CIUDAD',
  email: 'Escriba el nuevo valor para el campo CORREO',
  vpc: 'Seleccione un nuevo valor para el campo VPC',
  phone: 'Escriba el nuevo valor para el campo TELEFONO',
  program_name: 'Seleccione el nuevo valor para el campo PROGRAMA',
  general_state: 'Seleccione el nuevo valor para el campo ESTADO',
  observations: 'Escriba el nuevo valor para el campo HALLAZGOS',
  conversation_type: 'Seleccione el nuevo valor para el campo CONVERSATORIO',
  taker_name: 'Seleccione el TOMADOR'
})

i18next.addResources('es', 'tableFilters', {
  filters: 'Filtros',
  levelRisk: 'Nivel de riesgo',
  cias: 'Fuentes',
  generalStates: 'Estados',
  selfManagement: 'Autogestión',
  low: 'Bajo',
  medium: 'Medio',
  mediumHigh: 'Medio - Alto',
  high: 'Alto',
  data: 'Datos',
  programT: 'Tomador y Programa',
  source: 'Fuente',
  city: 'Ciudad',
  stateProcess: 'Estado Valoración',
  economicGroup: 'Grupo Económico',
  socialReason: 'Tomador',
  program: 'Programa',
  state: 'Estado de proceso',
  date: 'Fecha',
  submit: 'crear filtro',
  clear: 'limpiar filtro',
  to: 'hasta',
  dateToolTip:
    'Esta fecha hace referencia a la cual el usuario hizo por primera vez la valoración completa de Salud Financiera'
})
