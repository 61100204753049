export const normalizeData = (data) => {
  let title = null
  let text = data.ciaText || data.sourceText
  if (data.source !== 'Orgánica') {
    const splitText = text ? text.split('<br>') : null
    title = splitText[0]
    text = splitText[1]
  }
  const newData = {
    ciaName: data.ciaName,
    source: data.source,
    title,
    text,
    showContactInfo: data.showContactInfo,
    showMesaggeBox:
      data.source === 'Fuente digital' ||
      data.source === 'Salud' ||
      (!!text && data.source === 'Orgánica')
  }
  return newData
}
