import { createTypes } from 'redux-recompose'

export const actions = createTypes(
  ['SET_SIDE_DRAWER_OPEN', 'SET_DROPDOWN_OPTIONS'],
  '@@NAVBAR'
)

export const actionsCreators = {
  setSideDrawerOpen: (value) => ({
    type: actions.SET_SIDE_DRAWER_OPEN,
    payload: value
  }),
  setDropdownOptions: (value) => ({
    type: actions.SET_DROPDOWN_OPTIONS,
    payload: value
  })
}

export default actionsCreators
