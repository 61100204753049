import api from '../config/api'
import { isLab } from '../constants/environment'

import LocalStorageService from './LocalStorageService'
;(() => {
  const token = LocalStorageService.getSessionToken()
  if (token) {
    api.setHeader('Authorization', token)
  }
})()

export const financeService = {
  getData: (params) => api.get('/financial', params),
  addEntry: (payload) =>
    api.post(isLab() ? '/financial/entries' : '/entries', payload),
  postFinancialPlanning: (payload) => api.put('/financial', payload),
  getHistory: () =>
    api.get(isLab() ? '/financial/active_dates' : '/activedates')
}

export const financialResults = {
  getPollResults: () => api.get(isLab() ? '/the_way_i_am' : '/thewayiam'),
  getFinancialData: (params) =>
    isLab() ? api.get('/financial', params) : api.post('/financial', params),
  getHistory: () =>
    api.get(isLab() ? '/financial/active_dates' : '/activedates')
}

export const purposeService = {
  postPurpose: (body) => api.post('/purposes', { purposes: body }),
  putPurpose: (body) => api.put('/purposes', { purposes: body }),
  getData: () => api.get('/purposes'),
  getDataById: (idUser) => {
    const id = idUser || LocalStorageService.getCurrentAcc()
    return api.get(`/purposes/${id}`)
  }
}

export const profileService = {
  getData: () => api.get('/worlds')
}

export const familyService = {
  postData: (body) => api.post('/family', body),
  putData: (body) => api.put('/family', body),
  getData: () => api.get('/family')
}

export const beingService = {
  getQuestions: () =>
    api.get(isLab() ? '/the_way_i_am/questions' : '/questions'),
  postData: (body) => api.post(isLab() ? '/the_way_i_am' : '/thewayiam', body),
  getResults: () => api.get(isLab() ? '/the_way_i_am' : '/thewayiam'),
  getDataTreatmentLink: () =>
    api.get(isLab() ? '/documents/financial/data-treatment' : 'datatreatment')
}

export const eventService = {
  postEvent: (body) => api.post('/events', { options: body }),
  putEvents: (body) => api.put('/events', { options: body }),
  getData: () => api.get(isLab() ? '/events/all' : '/all'),
  getDataByUser: () => api.get('/events')
}

export const engineService = {
  getGeneral: () =>
    isLab() ? api.get('/recommendations/general') : api.post('/general'),
  getPsycological: () =>
    api.get(isLab() ? '/recommendations/psychological' : '/psychological'),
  getFinancial: (params) =>
    isLab()
      ? api.get('/recommendations/financial', params)
      : api.post('/financial', params)
}

export const userExperience = {
  getData: () => api.get(isLab() ? '/user_experience' : '/userexperience'),
  putData: (body) =>
    api.put(isLab() ? '/user_experience' : 'userexperience', body)
}

export const priorityCombinationService = {
  getData: () =>
    api.get(isLab() ? '/priority_combination' : '/prioritycombination')
}

export const getMessage = () =>
  api.get(isLab() ? '/financial/popup_content' : '/popupcontent')

export const getCompany = () => api.get('/companies')

export const getLearnUrl = { get: () => api.get('/learn') }

export const faqsService = {
  getFAQs: () => api.get('/faqs')
}

export const userService = {
  editUser: (body) =>
    api.put(
      isLab() ? '/user/comunication_channel' : '/comunicationchannel',
      body
    )
}

export const reportError = {
  postData: (body) => api.post(isLab() ? '/email/error' : 'mailerror', body)
}

export const ciaExperiencieService = {
  getCiaExperience: (params) => api.get('/ciaExperience', params)
}

export const cifinService = {
  getReportCifin: () => api.get(isLab() ? '/cifin/report' : '/cifinreport')
}

export const adminService = {
  getDashboardData: (body) => api.post('/dashboard', body),
  getSourcesData: () => api.get('/sources'),
  getLevelRisk: () => api.get('/levelsrisk'),
  getStateProcess: () => api.get('/stateprocess'),
  getGeneralStates: (body) => api.post('/stategeneral', body),
  getGeneralStateFull: (body) => api.post('/stategeneral', body),
  getProgramsData: (body) => api.post('/programs', body),
  getEconomicGroups: () => api.get('/economicgroups')
}

export const createUserService = {
  postUser: (body) => api.post('/user', JSON.stringify(body)),
  postUploadUsers: (body) =>
    api.post(isLab() ? '/user/upload' : '/upload', body, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }),
  getDocumentsData: () => api.get('/documentstypes'),
  getProgramsData: () => api.post('/programs'),
  getCitiesData: () => api.get('/cities'),
  getProfilesData: () => api.get('/profiles'),
  getConversationsData: () => api.get('/conversations')
}

export const createProgramsService = {
  getSources: () => api.get('/sources'),
  getEconomicGroups: () => api.get('/economicgroups'),
  getProgramsData: (body) => api.post('/programs', body),
  postPrograms: (body) => api.post('/holderprogram', body),
  getVpcData: () => api.get('/vpc')
}

export const tableUserService = {
  getTableData: (body) => api.post('/usersdashboard', body),
  updateTableData: (body) => api.put('/usersdashboard', body),
  getSourcesData: () => api.get('/sources'),
  getCitiesData: () => api.get('/cities'),
  getVpcData: () => api.get('/vpc'),
  getLevelRisk: () => api.get('/levelsrisk'),
  getStateProcess: () => api.get('/stateprocess'),
  getGeneralStates: (body) => api.post('/stategeneral', body),
  getGeneralStateFull: (body) => api.post('/stategeneral', body),
  getProgramsData: (body) => api.post('/programs', body),
  getEconomicGroups: () => api.get('/economicgroups'),
  downloadUsers: () => api.get('/downloadUsers')
}

export const tableSchedulingService = {
  getTableData: (body) => api.post('/schedulingsDashboard', body),
  updateTableData: (body) => api.put('/schedulingsDashboard', body),
  getSourcesData: () => api.get('/sources'),
  getCitiesData: () => api.get('/cities'),
  getVpcData: () => api.get('/vpc'),
  getLevelRisk: () => api.get('/levelsrisk'),
  getStateProcess: () => api.get('/stateprocess'),
  getGeneralStates: (body) => api.post('/stategeneral', body),
  getGeneralStateFull: (body) => api.post('/stategeneral', body),
  getProgramsData: (body) => api.post('/programs', body),
  getEconomicGroups: () => api.get('/economicgroups'),
  downloadUsers: () => api.get('/downloadUsers')
}

export const conversationsTableService = {
  getTableData: (body) => api.post('/dashboardconversations', body),
  getConversationsData: () => api.get('/conversations')
}

export const programsTableService = {
  getTableData: (body) => api.post('/programsgrouped', body),
  getSourceData: () => api.get('/sources'),
  getVpcData: () => api.get('/vpc'),
  updateTableData: (body) => api.put('/program', body),
  deleteTableData: (body) => api.delete('/program', null, { data: body })
}

export const emailsTableService = {
  getTableData: (body) => api.post('/comunicationsusersinformation', body),
  sendEmail: (body) => api.post('/comunicationsusers', body)
}

export const switchesService = {
  getRedirectSwitch: () => api.get('/redirectSwitch'),
  updateRedirectSwitch: (body) => api.put('/redirectSwitch', body)
}
