import React, { Component, Fragment } from 'react'
import { shape, string, bool, func } from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import SaveModal from '../Modals/SaveModal'

import Layout from './layout'
import { TIME } from './constant'
import Backdrop from './components/Backdrop/Backdrop'

import { profileWords } from '../../../constants/propTypes'
import navbarBottomActions from '../../../redux/NavbarBottom/actions'
import userExperienceActions from '../../../redux/UserExperience/actions'
import pollActions from '../../../redux/Poll/actions'
import profileActions from '../../../redux/Profile/actions'
import familyActions from '../../../redux/Family/actions'
import eventActions from '../../../redux/Event/actions'
import navbarActions from '../../../redux/Navbar/actions'
import financeActions from '../../../redux/Finance/actions'
import modalActions from '../../../redux/Modal/actions'
import Routes from '../../../constants/routes'

class NavBar extends Component {
  handleDrawerToggleClick = () => {
    const { setShowPersonalInfo, setSideDrawerOpen, isSideDrawerOpen } =
      this.props
    setSideDrawerOpen(!isSideDrawerOpen)
    setShowPersonalInfo(false)
  }

  handleBackdropClick = () => {
    this.props.setSideDrawerOpen(false)
  }

  handleShowDropdown = (dropdownKey) => (e) => {
    const { isMyResultsOpen, isPersonalInformationOpen, setDropdownOptions } =
      this.props
    e.stopPropagation()
    if (dropdownKey === 'myResults') {
      setDropdownOptions({
        isMyResultsOpen: !isMyResultsOpen,
        isPersonalInformationOpen: false
      })
    } else {
      setDropdownOptions({
        isMyResultsOpen: false,
        isPersonalInformationOpen: !isPersonalInformationOpen
      })
    }
  }

  handleClick = (route) => () => {
    const { currentPath, history, setSideDrawerOpen } = this.props
    setSideDrawerOpen(false)
    if (currentPath.includes('/profile')) {
      this.profileNavigationOptions(route)
    } else {
      history.push(Routes[route])
    }
  }

  profileNavigationOptions = (route) => {
    const { currentPath, history, worlds, isEditEvent, isEditFamily } =
      this.props
    const isWorldTouched = Object.values(worlds).filter(
      (value) => value.touched === true
    )
    const isEditWorld = isEditEvent || isEditFamily
    if (currentPath.includes('/profile') && isWorldTouched.length) {
      this.showModal(route, 'close')
    } else if (currentPath.includes('/profile') && isEditWorld) {
      this.showModal(route, 'update')
    } else {
      this.resetViews()
      history.push(Routes[route])
    }
  }

  showModal = (nextRoute, typeOfAlertModal) => {
    const { showAlertModalAction, setControlClose } = this.props
    setControlClose(true)
    showAlertModalAction({ isOpen: true, nextRoute, typeOfAlertModal })
  }

  closeModal = (value) => {
    const { history, setControlClose, showAlertModalAction, nextRoute } =
      this.props
    setControlClose(false)
    if (value && nextRoute) {
      this.resetViews()
      setTimeout(() => {
        history.push(Routes[nextRoute])
      }, TIME)
    }
    showAlertModalAction({
      isOpen: false,
      nextRoute: null,
      typeOfAlertModal: null
    })
  }

  acceptModal = () => {
    const { history, setControlClose, showAlertModalAction, nextRoute } =
      this.props
    if (!Routes[nextRoute].includes('/profile')) {
      this.resetViews()
      setControlClose(false)
    }
    showAlertModalAction({
      isOpen: false,
      nextRoute: null,
      typeOfAlertModal: null
    })
    history.push(Routes[nextRoute])
  }

  resetViews = () => {
    const { resetPoll, resetProfile, resetFamily, resetEvent, resetFinancial } =
      this.props
    resetFamily()
    resetEvent()
    resetPoll()
    resetFinancial()
    resetProfile()
    window.onbeforeunload = null
  }

  render() {
    const { nick, hasPendingWorld, learningUrl, isSideDrawerOpen, isAdmin } =
      this.props
    return (
      <Fragment>
        <Layout
          onHandleDrawerClick={this.handleDrawerToggleClick}
          nick={nick}
          onHandleClick={this.handleClick}
          show={isSideDrawerOpen}
          hasPendingWorld={hasPendingWorld}
          learningUrl={learningUrl}
          onHandleShowDropdown={this.handleShowDropdown}
          isAdmin={isAdmin}
        />
        {isSideDrawerOpen && (
          <div className="d-block d-md-none">
            <Backdrop
              className="d-block d-md-none"
              onBackdropClickHandler={this.handleBackdropClick}
            />
          </div>
        )}
        <SaveModal
          closeModal={this.closeModal}
          acceptModal={this.acceptModal}
        />
      </Fragment>
    )
  }
}

NavBar.propTypes = {
  currentPath: string.isRequired,
  hasPendingWorld: bool.isRequired,
  isAdmin: bool.isRequired,
  isEditEvent: bool.isRequired,
  isEditFamily: bool.isRequired,
  isMyResultsOpen: bool.isRequired,
  isPersonalInformationOpen: bool.isRequired,
  isSideDrawerOpen: bool.isRequired,
  nick: string.isRequired,
  setDropdownOptions: func.isRequired,
  setShowPersonalInfo: func.isRequired,
  setSideDrawerOpen: func.isRequired,
  showAlertModalAction: func.isRequired,
  worlds: profileWords.isRequired,
  history: shape({
    push: func.isRequired
  }),
  learningUrl: string,
  nextRoute: string,
  resetEvent: func,
  resetFamily: func,
  resetFinancial: func,
  resetPoll: func,
  resetProfile: func,
  setControlClose: func
}

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  nick: state.auth.currentUser.fullname?.split(' ')[0],
  hasPendingWorld: state.auth.hasPendingWorld,
  learningUrl: state.learning.learningUrl,
  currentPath: state.router.location.pathname,
  worlds: state.profile.profile,
  isEditEvent: state.event.isEdit,
  isEditFamily: state.family.isEdit,
  isSideDrawerOpen: state.navbar.isSideDrawerOpen,
  isMyResultsOpen: state.navbar.isMyResultsOpen,
  typeOfAlertModal: state.navbar.typeOfAlertModal,
  nextRoute: state.modal.nextRoute,
  isPersonalInformationOpen: state.navbar.isPersonalInformationOpen,
  learningRoute: state.profile.learningRoute,
  learningUrlLoading: state.learning.learningUrlLoading
})

const mapDispatchToProps = (dispatch) => ({
  setShowPersonalInfo: (value) =>
    dispatch(navbarBottomActions.setShowPersonalInfo(value)),
  setControlClose: (value) =>
    dispatch(userExperienceActions.setControlClose(value)),
  resetProfile: () => dispatch(profileActions.resetTouched()),
  resetFamily: () => dispatch(familyActions.resetState()),
  resetEvent: () => dispatch(eventActions.resetState()),
  resetPoll: () => dispatch(pollActions.resetState()),
  resetFinancial: () => dispatch(financeActions.resetState()),
  setSideDrawerOpen: (value) =>
    dispatch(navbarActions.setSideDrawerOpen(value)),
  setDropdownOptions: (value) =>
    dispatch(navbarActions.setDropdownOptions(value)),
  showAlertModalAction: (value) => dispatch(modalActions.showAlertModal(value))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar))
