import i18next from 'i18next'

i18next.addResources('es', 'financialResults', {
  mainTitle: 'Resultados psicológicos',
  text: 'Estos son los resultados de tu prueba de valoración psicológica en comportamiento financiero.'
})

i18next.addResources('es', 'updateCard', {
  mainTitle: 'Actualiza tus respuestas',
  text: 'para obtener nuevos resultados.',
  buttonLabel: 'Actualizar valoración'
})

i18next.addResources('es', 'resultsCard', {
  strengths: 'Fortalezas',
  potentiate: 'Para potenciar',
  improvement: 'Oportunidades de mejora'
})
