import i18next from 'i18next'

i18next.addResources('es', 'navbarBottom', {
  being: 'Mi forma de ser',
  finance: 'Mis finanzas',
  learning: 'Rutas de aprendizaje',
  personalInfo: 'Información personal',
  purposes: 'Propósitos',
  family: 'Mi entorno',
  events: 'Mis acontecimientos',
  financial: 'Mis finanzas',
  myResults: 'Mis resultados',
  myPlan: 'Mi plan de acción'
})
