import React, { lazy } from 'react'
import { Switch, BrowserRouter } from 'react-router-dom'
import { shape, string, bool } from 'prop-types'

import Header from './components/Header/index'
import Footer from './components/Footer/index'
import FloatingSection from './components/Floating/index'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import styles from './styles.module.scss'

import withLoading from '../../../hocs/Loading'
import Routes from '../../../constants/routes'
import Suspense from '../Suspense'
import ErrorModal from '../Modals/ErrorModal'
import AdminSidebar from '../AdminSidebar'
import { modalErrorProptype } from '../../../constants/propTypes'

const Home = lazy(() => import('../../screens/Dashboard'))
const Login = lazy(() => import('../../screens/Authentication/Login'))
const Auth = lazy(() => import('../../screens/Authentication/Auth'))

function AppRoutes({
  currentUser,
  hasPendingWorld,
  showFloatingOptions,
  modalData,
  currentRoleInterface,
  loading
}) {
  const currentLocation = window.location.pathname
  const adminPage = currentRoleInterface === 'admin'
  return (
    !loading && (
      <div className={`${adminPage ? styles.test : ''}`}>
        <Header
          loading={loading}
          currentUser={currentUser}
          isAdminPage={adminPage}
        />
        <main className={styles.container}>
          {adminPage && <AdminSidebar />}
          {showFloatingOptions && !adminPage && <FloatingSection />}
          <section
            className={`${adminPage ? styles.section : ''} ${
              styles.containerSection
            }`}
          >
            <Suspense>
              <BrowserRouter basename="/app" />
              <Switch>
                <AuthenticatedRoute
                  isPublicRoute
                  exact
                  path={Routes.LOGIN}
                  component={Login}
                />
                <AuthenticatedRoute
                  isPublicRoute
                  exact
                  path={Routes.AUTH}
                  component={Auth}
                />
                <AuthenticatedRoute
                  isPrivateRoute
                  path={Routes.HOME}
                  component={Home}
                />
              </Switch>
              {currentUser?.id &&
                currentLocation === `${Routes.APP}${Routes.PROFILE}`}
            </Suspense>
          </section>

          {modalData.showErrorModal && <ErrorModal />}
        </main>
        <Footer
          currentUser={currentUser}
          hasPendingWorld={hasPendingWorld}
          isAdmin={adminPage}
        />
      </div>
    )
  )
}

AppRoutes.propTypes = {
  hasPendingWorld: bool.isRequired,
  loading: bool.isRequired,
  modalData: modalErrorProptype.isRequired,
  showFloatingOptions: bool.isRequired,
  currentRoleInterface: string,
  currentUser: shape({
    id: string,
    nick: string
  })
}

export default withLoading(AppRoutes)
