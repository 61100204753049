import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const Backdrop = (props) => (
  <div
    id="backDrop"
    className={styles.backdrop}
    onClick={props.onBackdropClickHandler}
  />
)

Backdrop.propTypes = {
  onBackdropClickHandler: PropTypes.func.isRequired
}

export default Backdrop
