export const denormalizeData = (data) => {
  const newData = data.questions.map((item) => ({
    id: parseInt(item.id),
    question: item.question,
    answer: item.answer
  }))
  return newData
}

export const normalizeResults = (data) => data.results
