import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose'
import withPostFailure from 'redux-recompose/lib/injections/withPostFailure'

import { setSectionsUnlocked } from './utils'

import { showErrorModalAction } from '../../redux/Modal/actions'
import { familyService } from '../../services/BifiService'
import {
  normalizeFamilyData,
  denormalizeDataFamily,
  normalizeFamilyDataStruct
} from '../../schemas/familySchema'

export const actions = createTypes(
  completeTypes(
    ['POST_FAMILY', 'GET_FAMILY'],
    [
      'SCREEN_STATE',
      'SIDE_STATE',
      'FAMILY_DATA_STRUCT',
      'NEXT_NAVIGATION',
      'SECTIONS_UNLOCKED',
      'CURRENT_SECTION',
      'STEPS_TO_BACK',
      'RESET_STATE',
      'SET_EDIT',
      'ENABLE_DONE_BUTTON',
      'ENABLE_OWNERSHIP_OPTIONS',
      'ENABLE_OWN_OPTION',
      'SET_HAS_ERROR'
    ]
  ),
  '@@FAMILY'
)

export const actionsCreators = {
  postData: ({ data, update }) => ({
    type: actions.POST_FAMILY,
    target: 'family',
    service: update ? familyService.putData : familyService.postData,
    payload: denormalizeDataFamily(data),
    injections: [
      withPostSuccess((dispatch) => {
        dispatch(actionsCreators.enableDoneButton(true))
      }),
      withPostFailure((dispatch, response) => {
        showErrorModalAction(dispatch, response)
        dispatch(actionsCreators.enableDoneButton(false))
      })
    ]
  }),
  getData: () => ({
    type: actions.GET_FAMILY,
    target: 'environment',
    service: familyService.getData,
    successSelector: (response) => normalizeFamilyData(response.data),
    injections: [
      withPostSuccess((dispatch, response, state) => {
        const newFamilyDataStruct = normalizeFamilyDataStruct(response.data)
        dispatch(actionsCreators.setFamilyDataStruct(newFamilyDataStruct))
        const sectionsToUnlock = setSectionsUnlocked(
          response.data,
          state.family.sectionsUnlocked
        )
        dispatch(actionsCreators.setSectionsUnlocked(sectionsToUnlock))
        if (response.data.type) {
          dispatch(actionsCreators.setEnabledOwnershipOptions(true))
        }
        dispatch(
          actionsCreators.setEnabledOwnOption(response.data.type !== 'ROOM')
        )
      })
    ]
  }),
  setScreenState: (value) => ({
    type: actions.SCREEN_STATE,
    payload: value
  }),
  setFamilySide: (value) => ({
    type: actions.SIDE_STATE,
    payload: value
  }),
  setFamilyDataStruct: (value) => ({
    type: actions.FAMILY_DATA_STRUCT,
    payload: value
  }),
  enableNextNavigation: (value) => ({
    type: actions.NEXT_NAVIGATION,
    payload: value
  }),
  setSectionsUnlocked: (value) => ({
    type: actions.SECTIONS_UNLOCKED,
    payload: value
  }),
  setCurrentSection: (value) => ({
    type: actions.CURRENT_SECTION,
    payload: value
  }),
  setStepsToBack: (value) => ({
    type: actions.STEPS_TO_BACK,
    payload: value
  }),
  resetState: () => ({
    type: actions.RESET_STATE
  }),
  setEdit: (value) => ({
    type: actions.SET_EDIT,
    payload: value
  }),
  enableDoneButton: (value) => ({
    type: actions.ENABLE_DONE_BUTTON,
    payload: value
  }),
  setEnabledOwnershipOptions: (value) => ({
    type: actions.ENABLE_OWNERSHIP_OPTIONS,
    payload: value
  }),
  setEnabledOwnOption: (value) => ({
    type: actions.ENABLE_OWN_OPTION,
    payload: value
  }),
  setHasError: (value) => ({
    type: actions.SET_HAS_ERROR,
    payload: value
  })
}

export default actionsCreators
