import i18next from 'i18next'

i18next.addResources('es', 'cifinModal', {
  nombreEntidad: 'Nombre de la entidad',
  tipoCredito: 'Tipo de crédito',
  estado: 'Estado',
  deudaInicial: 'Deuda inicial',
  seDebe: 'Lo que debe(saldo)',
  cuota: 'Cuota',
  periocidad: 'Periodicidad',
  numeroCuotas: 'Numero de cuotas',
  valorMora: 'Valor de la mora',
  cuotaMora: 'Cuotas en mora'
})
