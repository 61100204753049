import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

import { currentMonth } from '../../utils/generalFunctions'

const initialState = {
  profile: {
    FAMILY: {
      complete: false,
      active: false,
      updated: null
    },
    THE_WAY_I_AM: {
      complete: false,
      active: false.FAMILY,
      updated: null
    },
    EVENTS: {
      complete: false,
      active: false,
      updated: null
    },
    FINANCIAL: {
      complete: false,
      active: false,
      updated: null
    },
    LEARNING_ROUTES: {
      complete: false,
      active: false,
      updated: null
    }
  },
  currentMonth: null,
  activeKey: 'CLOSE',
  learningRoute: false
}

const reducerDescription = {
  primaryActions: [actions.GET_DATA],
  override: {
    [actions.SET_TOUCHED]: (state, action) => ({
      ...state,
      profile: {
        ...state.profile,
        [action.payload]: {
          ...state.profile[action.payload],
          touched: true
        }
      }
    }),
    [actions.RESET_TOUCHED]: (state) => ({
      ...state,
      profile: {
        ...state.profile,
        FAMILY: {
          ...state.profile.FAMILY,
          touched: false
        },
        THE_WAY_I_AM: {
          ...state.profile.THE_WAY_I_AM,
          touched: false
        },
        EVENTS: {
          ...state.profile.EVENTS,
          touched: false
        },
        FINANCIAL: {
          ...state.profile.FINANCIAL,
          touched: false
        }
      }
    }),
    [actions.SET_CURRENT_MONTH]: (state) => ({
      ...state,
      currentMonth: currentMonth()
    }),
    [actions.SET_ACTIVE_KEY]: (state, action) => ({
      ...state,
      activeKey: action.payload
    }),
    [actions.SET_LEARNING_ROUTE]: (state, action) => ({
      ...state,
      learningRoute: action.payload
    })
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
