import { FORM_TYPE_WHO_LIVE } from '../../constants/forms'

export const FORM_NAME = 'WhereLiveFamily'

export const RESET_VALUE_COUNT = {
  [FORM_TYPE_WHO_LIVE.PARTNER]: 0,
  [FORM_TYPE_WHO_LIVE.CHILDREN]: 0,
  [FORM_TYPE_WHO_LIVE.PARENTS]: 0,
  [FORM_TYPE_WHO_LIVE.BROTHERS]: 0,
  [FORM_TYPE_WHO_LIVE.OTHER]: 0
}

export const DEFAULT_WHO_LIVE = {
  ...RESET_VALUE_COUNT,
  [FORM_TYPE_WHO_LIVE.PET]: 0
}

export const INITIAL_VALUES = {
  ownership: 'OWN',
  wholive: 'no',
  [FORM_TYPE_WHO_LIVE.WHO_LIVE_LIST]: {
    ...DEFAULT_WHO_LIVE
  },
  depends: 'no',
  disability: 'no'
}
