import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { reducer as form } from 'redux-form'
import { fetchMiddleware } from 'redux-recompose'
import thunk from 'redux-thunk'

import finance from './Finance/reducers'
import profile from './Profile/reducers'
import purpose from './Purpose/reducers'
import family from './Family/reducers'
import poll from './Poll/reducers'
import event from './Event/reducers'
import engine from './Engine/reducers'
import message from './Message/reducers'
import learning from './Learning/reducers'
import userExperience from './UserExperience/reducers'
import priorityCombination from './PriorityCombination/reducers'
import faqs from './Faqs/reducers'
import modal from './Modal/reducers'
import navbarBottom from './NavbarBottom/reducers'
import navbar from './Navbar/reducers'
import ciaExperience from './CiaExperience/reducers'
import adminModule from './AdminModule/reducers'
import users from './Users/reducers'
import createUsers from './CreateUser/reducers'
import conversations from './ConversationScreen/reducers'
import emails from './EmailsScreen/reducers'
import programs from './Programs/reducers'
import createPrograms from './CreatePrograms/reducers'
import financialResults from './FinancialResults/reducers'
import switchReducer from './Switches/SwitchReducer'
/*
 * Add this if you need it
 * import AnalyticsMiddleware from '../services/AnalyticsService';
 */
import auth from './Auth/reducer'
import { actions } from './Auth/actions'

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_PUBLIC_URL
})

export const reducers = combineReducers({
  auth,
  form,
  finance,
  profile,
  purpose,
  family,
  poll,
  event,
  engine,
  message,
  learning,
  userExperience,
  priorityCombination,
  faqs,
  modal,
  navbarBottom,
  ciaExperience,
  navbar,
  adminModule,
  users,
  createUsers,
  createPrograms,
  conversations,
  emails,
  programs,
  financialResults,
  switchReducer,
  router: connectRouter(history)
})

const middlewares = [thunk, routerMiddleware(history), fetchMiddleware]
const enhancers = []

// Add this if you need it.
/* ------------- Analytics Middleware ------------- */
// Middlewares.push(AnalyticsMiddleware);

/* ------------- Assemble Middleware ------------- */
enhancers.push(applyMiddleware(...middlewares))

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const rootReducer = (state, action) => {
  let newState = state
  if (action.type === actions.LOGOUT) {
    newState = undefined
  }
  return reducers(newState, action)
}

export const store = createStore(rootReducer, composeEnhancers(...enhancers))

export default store
