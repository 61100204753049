import {
  createTypes,
  completeTypes,
  withPostSuccess,
  withPostFailure
} from 'redux-recompose'

import { purposeService } from '../../services/BifiService'
import { cleanArray } from '../../utils/generalFunctions'
import {
  normalizePurposeData,
  normalizePurposeUser,
  normalizePurposePriority
} from '../../schemas/purposeSchema'

import profileActions from '../../redux/Profile/actions'

export const actions = createTypes(
  completeTypes(
    ['POST_PURPOSES', 'GET_DATA', 'GET_DATA_USER'],
    [
      'SET_PURPOSES',
      'CLEAR_PURPOSES',
      'SELECT',
      'SET_EDIT_PURPOSES',
      'SET_CURRENT_BOX',
      'CLEAR_CURRENT_BOX',
      'SET_CURRENT_PAGE',
      'SET_OPTIONAL_PURPOSE'
    ]
  ),
  '@@PURPOSES'
)

export const actionsCreators = {
  postData: ({ purposes, update }) => ({
    type: actions.POST_PURPOSES,
    target: 'purposes',
    service: update ? purposeService.putPurpose : purposeService.postPurpose,
    payload: normalizePurposePriority(purposes),
    successSelector: (response) => response.ok && purposes,
    injections: update
      ? null
      : [withPostSuccess((dispatch) => dispatch(profileActions.getProfile()))]
  }),

  getPurposes: () => ({
    type: actions.GET_DATA,
    target: 'boxes',
    service: purposeService.getData,
    successSelector: (response) => normalizePurposeData(response.data),
    failureSelector: (response) => ({ error: response.error })
  }),

  getPurposesByUser: (id) => ({
    type: actions.GET_DATA_USER,
    target: 'purposes',
    service: purposeService.getDataById,
    payload: id,
    successSelector: (response) => normalizePurposeUser(response.data)
  }),

  getPurposesByUserAndEdit: () => ({
    type: actions.GET_DATA_USER,
    target: 'purposes',
    service: purposeService.getDataById,
    successSelector: (response) => normalizePurposeUser(response.data),
    injections: [
      withPostSuccess((dispatch) =>
        dispatch(actionsCreators.setEditPurpose(true))
      ),
      withPostFailure((dispatch) =>
        dispatch(actionsCreators.setEditPurpose(false))
      )
    ]
  }),
  setPurpose: (values) => ({
    type: actions.SET_PURPOSES,
    payload: values
  }),
  setCurrentBox: (value) => ({
    type: actions.SET_CURRENT_BOX,
    payload: value
  }),
  clearPurposes: (values) => ({
    type: actions.CLEAR_PURPOSES,
    payload: cleanArray(values)
  }),
  setEditPurpose: (value) => ({
    type: actions.SET_EDIT_PURPOSES,
    payload: value
  }),
  clearCurrentBox: () => ({
    type: actions.CLEAR_CURRENT_BOX
  }),
  setCurrentPage: (value) => ({
    type: actions.SET_CURRENT_PAGE,
    payload: value
  }),
  setOptionalPurpose: (value) => ({
    type: actions.SET_OPTIONAL_PURPOSE,
    payload: value
  })
}

export default actionsCreators
