import { createReducer, completeReducer } from 'redux-recompose'
import { actions } from './SwitchActions'

const initialState = {
  switches: []
}

const reducerDescription = {
  primaryActions: [actions.GET_SWITCHES, actions.UPDATE_SWITCHES],
  override: {
    [actions.SET_REDIRECT_SWITCH]: (state, action) => ({
      ...state,
      switches: {
        data: action.payload
      }
    })
  }
}

export default createReducer(initialState, completeReducer(reducerDescription))
