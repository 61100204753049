import i18next from 'i18next'

i18next.addResources('es', 'modal', {
  accept: 'Aceptar',
  cancel: 'Cancelar',
  closeTitle: '¿Estás seguro que deseas salir?',
  closeBody:
    'Si sales en esta parte de la valoración tus respuestas no quedarán guardadas.',
  updateTitle: 'Registramos con éxito tus datos.',
  updateBody: '¿Estás seguro que deseas guardarlos?'
})
