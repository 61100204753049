import {
  createTypes,
  completeTypes,
  withPostSuccess,
  withPostFailure
} from 'redux-recompose'

import { beingService } from '../../services/BifiService'
import { options } from '../../constants/poll'
import {
  denormalizeDataPoll,
  normalizeQuestions,
  normalizeResults
} from '../../schemas/pollSchema'
import modalActions from '../../redux/Modal/actions'

export const actions = createTypes(
  completeTypes([
    'POST_POLL',
    'GET_RESULTS',
    'GET_QUESTIONS',
    'GET_DOCUMENT_LINK',
    'SCREEN_STATE',
    'POLL_DATA_STRUCT',
    'QUESTION_STATE',
    'RESET_STATE',
    'ENABLE_DONE_BUTTON'
  ]),
  '@@POLL'
)

export const actionsCreators = {
  postData: ({ data }) => ({
    type: actions.POST_POLL,
    target: 'poll',
    service: beingService.postData,
    payload: denormalizeDataPoll(data),
    injections: [
      withPostSuccess((dispatch) => {
        dispatch(actionsCreators.enableDoneButton(true))
      }),
      withPostFailure((dispatch, response, state) => {
        if (response.problem === 'TIMEOUT_ERROR') {
          dispatch(
            actionsCreators.postData({ data: state.poll.pollDataStruct })
          )
        } else {
          if (
            response.data &&
            response.data.internal_code === 'authentication_error'
          ) {
            dispatch(
              modalActions.showErrorModal({
                showErrorModal: true,
                errorType: 'AUTHENTICATION_ERROR'
              })
            )
          } else {
            dispatch(
              modalActions.showErrorModal({
                showErrorModal: true,
                errorType: 'CLIENT_ERROR'
              })
            )
          }
          dispatch(actionsCreators.enableDoneButton(false))
        }
      })
    ]
  }),
  getResults: () => ({
    type: actions.GET_RESULTS,
    target: options.results,
    service: beingService.getResults,
    successSelector: (response) => normalizeResults(response.data)
  }),
  getDataTreatmentLink: () => ({
    type: actions.GET_DOCUMENT_LINK,
    target: options.dataTreatmentLink,
    service: beingService.getDataTreatmentLink,
    successSelector: (response) => response.data
  }),
  getQuestions: () => ({
    type: actions.GET_QUESTIONS,
    target: options.questions,
    service: beingService.getQuestions,
    successSelector: (response) => normalizeQuestions(response.data)
  }),
  setPollStep: (value) => ({
    type: actions.SCREEN_STATE,
    payload: value
  }),
  setQuestionNumber: (value) => ({
    type: actions.QUESTION_STATE,
    payload: value
  }),
  setPollDataStruct: (value) => ({
    type: actions.POLL_DATA_STRUCT,
    payload: value
  }),
  resetState: () => ({
    type: actions.RESET_STATE
  }),
  enableDoneButton: (value) => ({
    type: actions.ENABLE_DONE_BUTTON,
    payload: value
  })
}

export default actionsCreators
