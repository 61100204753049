import { t } from 'i18next'

import WhatsappIcon from '../../../../assets/ico-WhatsApp.svg'
// import WhatsappIcon from '~assets/ico-WhatsApp.svg';
import HelpUsToImproveIcon from '../../../../assets/ico-HelpUsToImprove.svg'
// import HelpUsToImproveIcon from '~assets/ico-HelpUsToImprove.svg';

export const ACTIONS = {
  whatsapp: {
    icon: WhatsappIcon,
    title: t('floatingButton:whatsAppTitle'),
    className: 'whatsapp',
    externalUrl: true
  },
  helpUsToImprove: {
    icon: HelpUsToImproveIcon,
    title: t('floatingButton:helpUsToImproveTitle'),
    className: 'helpUsToImprove',
    externalUrl: true
  }
}
