/* eslint-disable camelcase */
import i18next from 'i18next'

i18next.addResources('es', 'emails', {
  title: 'Envio de correos',
  tableTitle: 'Clientes Salud Financiera',
  total: 'Registros totales: ',
  filters: 'Filtros',
  button: 'Enviar',
  cancel: 'Cancelar',
  acept: 'Confimar',
  accept: 'Aceptar',
  sendEmail: 'El Email fue enviado correctamente!',
  badRequestMessage: 'Algo salio mal, verifica el reporte de errores!',
  forbiddenMessage: 'Tu sesión ha expirado!, por favor recarga la pagina',
  serverErrorMessage: '¡Ups, algo va mal!. Inténtalo en un minuto'
})

i18next.addResources('es', 'emailActions', {
  deleteSelection: 'Borrar selección',
  chooseEmail: 'Selecciona el correo',
  referralInvitation: 'Correo: Bienvenida',
  incompleteRecord: 'Correo: Registro incompleto',
  incompleteAssessment: 'Correo: Valoracion incompleta',
  conversationAssistant: 'Correo: Recordatorio conversatorio',
  assessmentReminder: 'Correo: SMS Recordatorio',
  desistedAssassment: 'Correo: Desistido valoracion',
  desistedAccompaniment: 'Correo: Desistido acompañamiento',
  confirm: 'Confirma el envio:'
})

i18next.addResources('es', 'tableFilters', {
  filters: 'Filtros',
  levelRisk: 'Nivel de riesgo',
  cias: 'Fuentes',
  generalStates: 'Estados',
  selfManagement: 'Autogestión',
  low: 'Bajo',
  medium: 'Medio',
  mediumHigh: 'Medio - Alto',
  high: 'Alto',
  data: 'Datos',
  programT: 'Tomador y Programa',
  source: 'Fuente',
  city: 'Ciudad',
  stateProcess: 'Estado Valoración',
  economicGroup: 'Grupo Económico',
  socialReason: 'Tomador',
  program: 'Programa',
  state: 'Estado de proceso',
  date: 'Fecha',
  submit: 'crear filtro',
  clear: 'limpiar filtro',
  to: 'hasta'
})
