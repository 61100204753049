import { createTypes, completeTypes } from 'redux-recompose'

import { getLearnUrl } from '../../services/BifiService'

export const actions = createTypes(
  completeTypes(['GET_LEARN_URL']),
  '@@LEARNING'
)

export const actionsCreators = {
  getLearnUrl: () => ({
    type: actions.GET_LEARN_URL,
    target: 'learningUrl',
    service: getLearnUrl.get
  })
}

export default actionsCreators
