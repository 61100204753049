import { createTypes } from 'redux-recompose'

export const actions = createTypes(
  ['SET_SHOW_PERSONAL_INFO', 'SET_SHOW_MY_RESULTS'],
  '@@NAVBARBOTTOM'
)

export const actionsCreators = {
  setShowPersonalInfo: (value) => ({
    type: actions.SET_SHOW_PERSONAL_INFO,
    payload: value
  }),
  setShowMyResults: (value) => ({
    type: actions.SET_SHOW_MY_RESULTS,
    payload: value
  })
}

export default actionsCreators
