/* eslint-disable no-magic-numbers */
import {
  createTypes,
  completeTypes,
  withPostSuccess,
  withPostFailure
} from 'redux-recompose'

import { profileService } from '../../services/BifiService'
import { normalizeProfileData } from '../../schemas/profileSchema'
import modalActions from '../../redux/Modal/actions'
import { getHasPendingWorlds } from '../../utils/generalFunctions'
import authActions from '../../redux/Auth/actions'
import learningActions from '../../redux/Learning/actions'

export const actions = createTypes(
  completeTypes(
    ['GET_DATA'],
    [
      'SET_TOUCHED',
      'RESET_TOUCHED',
      'SET_CURRENT_MONTH',
      'SET_ACTIVE_KEY',
      'SET_LEARNING_ROUTE'
    ]
  ),
  '@@PROFILE'
)

export const actionsCreators = {
  getProfile: () => ({
    type: actions.GET_DATA,
    target: 'profile',
    service: profileService.getData,
    successSelector: (response) => normalizeProfileData(response.data),
    injections: [
      withPostSuccess((dispatch, response) => {
        const learningState = response.data.filter(
          (world) => world.world === 'LEARNING_ROUTES'
        )[0].state
        const hasPendingWorlds = getHasPendingWorlds(
          normalizeProfileData(response.data)
        )
        dispatch(authActions.setStatusWorld(hasPendingWorlds))
        dispatch(actionsCreators.setCurrentMonth())
        if (learningState === 'UNLOCKED') {
          dispatch(learningActions.getLearnUrl())
        }
        dispatch(actionsCreators.setLearningRoute(learningState))
      }),
      withPostFailure((dispatch, response, state) => {
        if (
          response.data.message === 'The user has not flow' &&
          state.modal.showErrorModal === false
        ) {
          dispatch(
            modalActions.showErrorModal({
              showErrorModal: false,
              errorType: 'USER_WITHOUT_FLOW',
              reportError: false,
              errorMessage: response.data.message
            })
          )
        }
      })
    ]
  }),
  setCurrentMonth: () => ({
    type: actions.SET_CURRENT_MONTH
  }),
  setTouched: (value) => ({
    type: actions.SET_TOUCHED,
    payload: value
  }),
  resetTouched: () => ({
    type: actions.RESET_TOUCHED
  }),
  setAccordionActiveKey: (value) => ({
    type: actions.SET_ACTIVE_KEY,
    payload: value
  }),
  setLearningRoute: (value) => ({
    type: actions.SET_LEARNING_ROUTE,
    payload: value
  })
}

export default actionsCreators
