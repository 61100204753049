import i18next from 'i18next'

i18next.addResources('es', 'eventOnBoarding', {
  title: 'Mis acontecimientos',
  content: 'En la vida se pueden presentar situaciones planeadas o inesperadas',
  secondContent: '¿Cuáles has vivido en el último año?',
  labelButton: 'Comenzar'
})

i18next.addResources('es', 'eventQuestions', {
  altImg: 'Event-icon',
  content: 'Selecciona de acuerdo con tus experiencias'
})

i18next.addResources('es', 'doneEvent', {
  altImg: 'check',
  title: 'Has completado',
  world: 'Mis acontecimientos',
  content: '¡Sigue avanzando!'
})

i18next.addResources('es', 'newness', {
  labelButton: 'Continuar'
})
