import i18next from 'i18next'

i18next.addResources('es', 'financeOnBoarding', {
  title: 'Mis finanzas',
  content:
    'Descubre y conecta la información con la que te acompañaremos para el desarrollo y fortalecimiento de tu situación financiera. Ten en cuenta:',
  contentFirst:
    'La información que debes diligenciar es la del mes anterior al actual.',
  contentSecondA:
    'Si visualizas datos precargados, estos corresponden a información reportada por las ',
  contentSecondLink: ' centrales de riesgo ',
  contentSecondB: '  y no pueden modificarse.',
  contentThirdA: 'Si tienes una deuda en ',
  contentThirdLink: ' mora',
  contentThirdB:
    ', te aparecerá el valor total de las cuotas en mora y no la cuota que pagas regularmente.'
})

i18next.addResources('es', 'questions', {
  labelButton: 'Continuar',
  incomes:
    'Este valor corresponde al salario reportado por tu empresa, si es un valor superior o tienes ingresos adicionales que califiquen como salario, ',
  1: 'Este es el valor precargado por el sistema, si los gastos son superiores ',
  2: 'Este es el valor precargado por el sistema, si los gastos son superiores ',
  3: 'Este es el valor precargado por el sistema, si los gastos son superiores ',
  4: 'Este es el valor precargado por el sistema, si los gastos son superiores ',
  6: 'Este valor corresponde a los bonos reportados por tu empresa, si es un valor superior ',
  10: 'Este valor corresponde a la cuota reportada por el sistema financiero, si es un valor superior ',
  11: 'Este valor corresponde a la cuota reportada por el sistema financiero, si es un valor superior ',
  12: 'Este valor corresponde a la cuota reportada por el sistema financiero, si es un valor superior ',
  13: 'Esta es la cuota precargada por el sistema, si es un valor superior ',
  20: 'Este valor corresponde a la cuota reportada por el sistema financiero, si es un valor superior ',
  7: 'Este valor corresponde a los datos reportados por tu empresa, si es un valor superior ',
  8: 'Este valor corresponde a los datos reportados por tu empresa, si es un valor superior ',
  9: 'Este es el valor precargado por el sistema, si los gastos son superiores ',
  14: 'Este valor corresponde a los datos reportados por tu empresa, si es un valor superior ',
  15: 'Este valor corresponde a la cuota reportada por tu empresa o el sistema financiero, si es un valor superior ',
  16: 'Este es el valor precargado por el sistema, si los gastos son superiores ',
  17: 'Este es el valor precargado por el sistema, si los gastos son superiores ',
  18: 'Este es el valor precargado por el sistema, si los gastos son superiores ',
  19: 'Este valor corresponde a la cuota reportada por el sistema financiero, si es un valor superior ',
  initialCommitmentsText: 'Este valor corresponde a la ',
  report: 'cuota reportada',
  endCommitmentsText: '  por el sistema financiero, si es un valor superior ',
  click: 'haz clic aquí',
  add: ' para sumarlos.'
})

i18next.addResources('es', 'doneFinancialPlanner', {
  altImg: 'check',
  title: 'Has completado',
  world: 'Mis finanzas',
  content:
    'Estás a punto de conocer los resultados de tu estado financiero y las recomendaciones que tenemos para ti',
  labelButton: 'Finalizar'
})

i18next.addResources('es', 'financialPlannerValidation', {
  incomes: 'Digita tu ingreso mensual.',
  outcomes: 'Digita tu gasto mensual. Si no tienes escribe $0.',
  saving:
    'Recuerda que aquí debes diligenciar el dinero que ahorras mensualmente. Este monto no debe ser superior a tus ingresos.',
  dependency:
    'Anteriormente nos indicaste que tenías dependientes económicos, por favor diligencia el valor que destinas mensualmente para ellos, en caso de tener una novedad en tu grupo familiar te invitamos a actualizar tu información.',
  pet: 'Anteriormente nos indicaste que tenías mascotas, por favor diligencia el valor que destinas mensualmente para ellas, en caso de tener una novedad en tu grupo familiar te invitamos a actualizar tu información.'
})
