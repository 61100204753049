import { createTypes, completeTypes } from 'redux-recompose'

import { faqsService } from '../../services/BifiService'
import { denormalizeData } from '../../schemas/faqSchema'
import { options } from '../../constants/faqs'

export const actions = createTypes(completeTypes(['GET_FAQS']), '@@FAQS')

export const actionsCreators = {
  getFAQs: () => ({
    type: actions.GET_FAQS,
    target: options.questions,
    service: faqsService.getFAQs,
    successSelector: (response) => denormalizeData(response.data)
  })
}

export default actionsCreators
