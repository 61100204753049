import { createTypes, completeTypes } from 'redux-recompose'
import { financialResults } from '../../services/BifiService'
import {
  normalizeFinanceData,
  normalizeHistoryData
} from '../../schemas/financeSchema'
import { normalizeResults } from '../../schemas/pollSchema'

export const actions = createTypes(
  completeTypes(['GET_POLL_RESULTS', 'GET_FINANCIAL_DATA', 'GET_HISTORY']),
  '@@FINANCIAL_RESULTS'
)

export const actionsCreators = {
  getPollResults: () => ({
    type: actions.GET_POLL_RESULTS,
    target: 'results',
    service: financialResults.getPollResults,
    successSelector: (response) => normalizeResults(response.data)
  }),
  getFinancialData: (params, redirect) => ({
    type: actions.GET_FINANCIAL_DATA,
    target: 'finance',
    service: financialResults.getFinancialData,
    payload: params,
    successSelector: (response) => normalizeFinanceData(response.data),
    failureSelector: (response) => ({ error: response.error })
  }),
  getHistory: () => ({
    type: actions.GET_HISTORY,
    target: 'history',
    service: financialResults.getHistory,
    successSelector: (response) => normalizeHistoryData(response.data)
  })
}

export default actionsCreators
