import i18next from 'i18next'

i18next.addResources('es', 'myBeingOnBoarding', {
  title: 'Antes de comenzar ten presente:',
  openingParagraph:
    'Con el propósito de entender tu situación financiera actual y tu forma de ser, es necesario que nos permitas consultar en las bases de datos tu información personal y financiera.',
  middleParagraph:
    'Todos los datos que nos suministrarás a partir de este momento serán almacenados bajo los parámetros de ley.',
  finalParagraph: 'Al hacer clic en CONTINUAR estás aceptando la ',
  dataAuthorization: 'Autorización de tratamiento de datos',
  dataConditions: ', términos y condiciones',
  buttonLabel: 'Continuar'
})

i18next.addResources('es', 'theWayIAm', {
  title: 'Mi forma de ser',
  openingParagraph:
    'Con esta valoración podrás identificar aspectos de tu personalidad que influyen en tus decisiones financieras.',
  middleParagraph:
    'No hay respuestas correctas o incorrectas, solo debes contestar honestamente lo que piensas. ¡Sé sincero contigo mismo!',
  finalParagraph:
    'Esta valoración te tomará aproximadamente quince minutos, sin embargo no hay tiempo límite. Te invitamos a ubicarte en un ambiente cómodo y tranquilo, evitando interrupciones, porque una vez empiezas estas preguntas, debes terminarlas ya que el sistema no guarda información parcial. Ten presente que solo podrás hacer la valoración una única vez.',
  subTitle: 'Quiero hacer mi valoración',
  buttonLabel: 'Continuar'
})

i18next.addResources('es', 'instructionsMyBeing', {
  title: 'Instrucciones',
  openingParagraph:
    'Encontrarás 53 afirmaciones. Lee cada una de ella cuidadosamente y decide cuál alternativa de respuesta refleja mejor tu nivel de acuerdo o desacuerdo con lo que dice la frase teniendo en cuenta la siguiente escala:',
  completelyAgree: 'Completamente de acuerdo',
  moderatelyAgree: 'Medianamente de acuerdo',
  slightlyAgree: 'Ligeramente de acuerdo',
  slightlyDisagree: 'Ligeramente en desacuerdo',
  moderatelyDisagree: 'Medianamente en desacuerdo',
  completelyDisagree: 'Completamente en desacuerdo',
  buttonLabel: 'Continuar'
})

i18next.addResources('es', 'exampleMyBeing', {
  title: 'Ejemplo',
  openingParagraph:
    'Para responder selecciona el botón correspondiente a la opción de respuesta elegida',
  subTitle: '¿Aprendo de mis errores para planear acciones a futuro?'
})

i18next.addResources('es', 'exampleEndsMyBeing', {
  title: 'Ejemplo',
  openingParagraph:
    'Para responder selecciona el botón correspondiente a la opción de respuesta elegida',
  subTitle: '¡Ya puedes iniciar la prueba!',
  buttonLabel: 'Continuar'
})

i18next.addResources('es', 'doneMyBeing', {
  title: 'Has completado',
  world: 'Mi forma de ser',
  content: '¡Sigue avanzando!',
  buttonLabel: 'Continuar'
})
