import {
  createTypes,
  completeTypes,
  withPostSuccess,
  withPostFailure,
  withPreFetch
} from 'redux-recompose'

import { createUserService } from '../../services/BifiService'
import {
  normalizeOptions,
  normalizeDocumets
} from '../../schemas/adminModuleSchema'

export const actions = createTypes(
  completeTypes(
    [
      'POST_CREATE_USER',
      'POST_UPLOAD_USERS',
      'GET_SOURCES_DATA',
      'GET_PROGRAMS_DATA',
      'GET_DOCUMENT_DATA',
      'GET_CITIES_DATA',
      'GET_PROFILES_DATA',
      'GET_CONVERSATIONS_DATA',
      'GET_VPC_DATA'
    ],
    [
      'SET_REPORT_DATA',
      'SET_SHOW_MODAL',
      'SET_POST_USER_LOADING',
      'SET_STATUS_CODE'
    ]
  ),
  '@@CREATEUSER'
)

export const actionsCreators = {
  getSources: () => ({
    type: actions.GET_SOURCES_DATA,
    target: 'sourcesList',
    service: createUserService.getSourcesData,
    successSelector: (response) =>
      normalizeOptions(response.data, 'sourcesList')
  }),
  getPrograms: () => ({
    type: actions.GET_PROGRAMS_DATA,
    target: 'programsData',
    service: createUserService.getProgramsData,
    successSelector: (response) => response.data
  }),
  getCities: () => ({
    type: actions.GET_CITIES_DATA,
    target: 'citiesList',
    service: createUserService.getCitiesData,
    successSelector: (response) => normalizeOptions(response.data, 'citiesList')
  }),
  getProfiles: () => ({
    type: actions.GET_PROFILES_DATA,
    target: 'profilesList',
    service: createUserService.getProfilesData,
    successSelector: (response) =>
      normalizeOptions(response.data, 'profilesList')
  }),
  getDocuments: () => ({
    type: actions.GET_DOCUMENT_DATA,
    target: 'documentList',
    service: createUserService.getDocumentsData,
    successSelector: (response) =>
      normalizeDocumets(response.data, 'documentList')
  }),
  getConversations: () => ({
    type: actions.GET_CONVERSATIONS_DATA,
    target: 'conversationsList',
    service: createUserService.getConversationsData,
    successSelector: (response) =>
      normalizeOptions(response.data, 'conversationsList')
  }),
  getVpc: () => ({
    type: actions.GET_VPC_DATA,
    target: 'vpcList',
    service: createUserService.getVpcData,
    successSelector: (response) => normalizeOptions(response.data, 'vpcList')
  }),
  postUser: (userData) => ({
    type: actions.POST_CREATE_USER,
    target: 'userData',
    service: createUserService.postUser,
    payload: userData,
    injections: [
      withPreFetch((dispatch) => {
        dispatch(actionsCreators.setPostUserLoading(true))
        dispatch(actionsCreators.setStatusCode(null))
        dispatch(actionsCreators.setShowModal(true))
        dispatch(actionsCreators.setReportData(null))
      }),
      withPostSuccess((dispatch) => {
        const SUCCESS_CODE = 201
        dispatch(actionsCreators.setStatusCode(SUCCESS_CODE))
        dispatch(actionsCreators.setPostUserLoading(false))
      }),
      withPostFailure((dispatch, response) => {
        dispatch(actionsCreators.setReportData(response?.data))
        dispatch(actionsCreators.setStatusCode(response?.status))
        dispatch(actionsCreators.setPostUserLoading(false))
      })
    ]
  }),
  postUploadUsers: (uploadData) => ({
    type: actions.POST_UPLOAD_USERS,
    target: 'uploadData',
    service: createUserService.postUploadUsers,
    payload: uploadData,
    injections: [
      withPreFetch((dispatch) => {
        dispatch(actionsCreators.setPostUserLoading(true))
        dispatch(actionsCreators.setStatusCode(null))
        dispatch(actionsCreators.setShowModal(true))
        dispatch(actionsCreators.setReportData(null))
      }),
      withPostSuccess((dispatch, response) => {
        if (typeof response.data === 'string') {
          const BAD_REQUEST = 400
          dispatch(actionsCreators.setReportData(response.data))
          dispatch(actionsCreators.setStatusCode(BAD_REQUEST))
        } else {
          dispatch(actionsCreators.setStatusCode(response?.status))
        }

        dispatch(actionsCreators.setPostUserLoading(false))
      }),
      withPostFailure((dispatch, response) => {
        dispatch(actionsCreators.setStatusCode(response?.status))
        dispatch(actionsCreators.setPostUserLoading(false))
      })
    ]
  }),
  setReportData: (value) => ({
    type: actions.SET_REPORT_DATA,
    payload: value
  }),
  setShowModal: (value) => ({
    type: actions.SET_SHOW_MODAL,
    payload: value
  }),
  setPostUserLoading: (value) => ({
    type: actions.SET_POST_USER_LOADING,
    payload: value
  }),
  setStatusCode: (value) => ({
    type: actions.SET_STATUS_CODE,
    payload: value
  })
}

export default actionsCreators
