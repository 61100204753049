import i18next from 'i18next'

i18next.addResources('es', 'errorModal', {
  somethingWrong: '¡Algo salió mal!',
  somethingGreat: '¡Bien Hecho!',
  platformError:
    'En este momento la plataforma está presentando inconvenientes.',
  userError: 'Error!',
  reportError:
    ' o si lo prefieres puedes contactarnos de manera inmediata a través de:',
  contact: 'Pronto nos comunicaremos contigo al correo ',
  token:
    'Tu sesión ha expirado, te invitamos a actualizar e ingresar de nuevo.',
  repeat: 'Te invitamos a actualizar e ingresar de nuevo.',
  suggest: 'Verifica el numero de documento he inténtalo de nuevo.',
  thank: 'Agradecemos tu comprensión.',
  update: 'Actualizar',
  close: 'Cerrar'
})
