import { createTypes, completeTypes } from 'redux-recompose'

import { reportError } from '../../services/BifiService'

export const actions = createTypes(
  completeTypes(
    ['POST_EMAIL'],
    [
      'SHOW_ERROR_MODAL',
      'SHOW_SUCCESS_MODAL',
      'SHOW_CIFIN_MODAL',
      'SHOW_ALERT_MODAL',
      'SHOW_FILTERS_DASHBOARD_MODAL',
      'SHOW_FILTERS_TABLE_MODAL'
    ]
  ),
  '@@MODAL'
)

export const actionsCreators = {
  reportError: (data) => ({
    type: actions.POST_EMAIL,
    target: 'email',
    service: reportError.postData,
    payload: data
  }),
  showErrorModal: (value) => ({
    type: actions.SHOW_ERROR_MODAL,
    payload: value
  }),
  showSuccesModal: (value) => ({
    type: actions.SHOW_SUCCESS_MODAL,
    payload: value
  }),
  showCifinModal: (value) => ({
    type: actions.SHOW_CIFIN_MODAL,
    payload: value
  }),
  showAlertModal: (value) => ({
    type: actions.SHOW_ALERT_MODAL,
    payload: value
  }),
  showModal: (values) => ({
    type: actions[values.type]
  })
}
export default actionsCreators

export const showErrorModalAction = (dispatch, response) => {
  if (response.data && response.data.internal_code === 'authentication_error') {
    dispatch(
      actionsCreators.showErrorModal({
        showErrorModal: true,
        errorType: 'AUTHENTICATION_ERROR'
      })
    )
  } else {
    dispatch(
      actionsCreators.showErrorModal({
        showErrorModal: true,
        errorType: 'CLIENT_ERROR'
      })
    )
  }
}
