import React from 'react'
import { t } from 'i18next'

import styles from './styles.module.scss'
import { termLink, privacyLink } from './constants'

function Foooter() {
  return (
    <div className={styles.container}>
      <div className={styles.mainRow}>
        <div className="d-none d-md-block">
          <strong>{t('footer:copyright')}</strong>
        </div>
        <div className={`col-lg-4 col-md-6 col-sm-12 ${styles.linksCol}`}>
          <div className={styles.linksRow}>
            <a
              id="termLink"
              className={styles.link}
              href={termLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('footer:term')}
            </a>
            <a
              id="privacyLink"
              className={styles.link}
              href={privacyLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('footer:privacy')}
            </a>
          </div>
        </div>
      </div>
      <div className={`d-block d-md-none ${styles.secondRow}`}>
        <strong>{t('footer:copyright')}</strong>
      </div>
    </div>
  )
}

export default Foooter
