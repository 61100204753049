import { createReducer, completeReducer } from 'redux-recompose'

import { actions } from './actions'

const initialState = {
  learningUrl: null
}

const reducerDescription = {
  primaryActions: [actions.GET_LEARN_URL]
}

export default createReducer(initialState, completeReducer(reducerDescription))
